import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { gameLayers } from "game-layers";
import { SlingoCompletionReason } from "slingo/integration/slingo-schema";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SlingoGameOverState } from "slingo/states/slingo-game-over-state";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { applyWhiteBlackTextStroke } from "util/hr-text-style";

export class HotrollGameOverState extends SlingoGameOverState {
    protected showWinSummary(): Contract {
        Services.get(SoundService).customEvent(SlingoSoundEvent.end_game_win);

        const summaryPrompt = new PromptContentSubcomponent(["gamesummary_win"], 5000);

        const translations = Services.get(TranslationsService);
        const currency = Services.get(CurrencyService);

        const gameplay = gameState.getCurrentGame();
        const totalWin = gameplay.getTotalWin();
        const totalStake = gameplay.getTotalWagered();

        const isBigWin = Components.get(BigWinComponent).isBigWin(totalWin, totalStake);
        const isFullHouse = gameplay.getLatestResultOfType(SlingoGameProgressResult).completionReason === SlingoCompletionReason.TICKET_MATCHED;

        // Easiest way to populate the scene outside of the prompt component
        gameLayers.Prompts.onSceneEnter.addOnce(() => {
            const contents = gameLayers.Prompts.scene.gamesummary_win.contents;

            contents.bigwin_en.visible = false;
            contents.win_en.visible = false;
            contents.fullhouse_en.visible = false;
            contents.results_en.visible = false;
            contents.title.visible = !translations.playingInEnglish();

            let titleKey = "results";
            if (this.meetsWinRequirement()) {
                if (isFullHouse) {
                    titleKey = "full_house";
                    contents.fullhouse_en.visible = translations.playingInEnglish();
                } else if (isBigWin) {
                    titleKey = "big_win";
                    contents.bigwin_en.visible = translations.playingInEnglish();
                } else {
                    titleKey = "win";
                    contents.win_en.visible = translations.playingInEnglish();
                }
            } else {
                contents.results_en.visible = translations.playingInEnglish();
            }
            contents.title.text = translations.get(titleKey);
            contents.win_value.text = `${currency.format(totalWin)}}`;
        });

        return Components.get(PromptComponent).showPrompt(summaryPrompt);
    }
}
