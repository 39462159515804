import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { CenterPivot, Group } from "appworks/graphics/pixi/group";
import { ParticleContainer } from "appworks/graphics/pixi/particle-container";
import { Position } from "appworks/graphics/pixi/position";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { fade } from "appworks/utils/animation/fade";
import { deepClone } from "appworks/utils/collection-utils";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { deviceInfo } from "appworks/utils/device-info";
import { Point } from "appworks/utils/geom/point";
import { Lerp } from "appworks/utils/math/lerp";
import { RandomFromArray, RandomRangeFloat, RandomRangeInt, RandomSign } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing, Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { HotRollBonusDiceRollResult } from "model/results/hotroll-bonus-dice-roll-result";
import { Emitter } from "pixi-particles";
import { InteractionEvent } from "pixi.js";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";
import { Signal } from "signals";
import THREE = require("three");
import { HotRollDiceRollCounterComponent } from "./hotroll-dice-roll-counter-component";

enum DiceState {
    PreRoll,
    Dragging,
    DragFollow,
    Rolling,
    PostRoll,
    Transform
}

export enum DiceType {
    Red = "red",
    Gold = "gold"
}

export class HotRollDiceComponent extends AbstractComponent {
    public static TRAILS_ENABLED = false;

    public rollComplete: Signal = new Signal();

    protected dice: HotRollDice[] = [];

    protected preRollElementsContainer: Container;

    protected lastUpdate: number = Timer.time;
    protected updateInterval: number;
    protected rollCount: number = 1;
    protected autoRollEnabled: boolean;

    protected currentResult: HotRollBonusDiceRollResult;

    protected arrowNudgeTween: Tween;
    protected outlinePulseTween: Tween;

    protected instructionIndex: number;
    protected nextInstructionTimeout: number;

    public init() {
        this.updateInterval = Timer.setInterval(() => this.update(), 0);

        HotRollDice.POPULATE_STATIC_MATERIALS();

        gameLayers.Dice.onSceneExit.add((scene: string) => {
            if (scene !== "bonus") { return; }

            this.destroyDice();

            this.currentResult = undefined;
            this.autoRollEnabled = false;
            this.preRollElementsContainer = null;

            this.arrowNudgeTween.stop();
            this.arrowNudgeTween = null;

            this.outlinePulseTween.stop();
            this.outlinePulseTween = null;
        });

        gameLayers.Dice.onSceneEnter.add((scene: string) => {
            if (scene !== "bonus") { return; }

            this.spawnDice();

            this.showGoldBorder(false, 0);

            this.instructionIndex = 0;
            this.playInstructionVO();

            gameLayers.Dice.getText("roll_100_limit").visible = false;

            gameLayers.Dice.getSprite("drag_follow_target").visible = false;

            this.setAutoRoll(false);
            gameLayers.Dice.getButton("autoroll_start").on(ButtonEvent.CLICK.getPIXIEventString(), () => this.setAutoRoll(true));
            gameLayers.Dice.getButton("autoroll_stop").on(ButtonEvent.CLICK.getPIXIEventString(), () => this.setAutoRoll(false));

            gameLayers.Dice.getButton("arrow").on(ButtonEvent.CLICK.getPIXIEventString(), () => {
                if (this.dice[0].getState() === DiceState.PreRoll) {
                    this.dice.forEach((dice: HotRollDice) => dice.startRoll());
                }
            });

            // Dice outline alpha tween
            const outlineAlpha = { alpha: 1 };
            this.outlinePulseTween = new Tween(outlineAlpha)
                .to({ alpha: 0.1 }, 500)
                .easing(Easing.Cubic.InOut)
                .yoyo(true)
                .repeat(Number.MAX_SAFE_INTEGER)
                .onUpdate(() => {
                    gameLayers.Dice.getSprite("outline_red").alpha = outlineAlpha.alpha;
                    gameLayers.Dice.getSprite("outline_gold").alpha = outlineAlpha.alpha;
                })
                .start();

            // Arrow nudge animation
            const arrowGroup = Group([
                gameLayers.Dice.getButton("arrow"),
                gameLayers.Dice.getSprite("arrow_gold"),
                gameLayers.Dice.getSprite("arrow_text") || gameLayers.Dice.getText("arrow_text")
            ]);
            const startXLandscape = arrowGroup.landscape.x;
            const startXPortrait = arrowGroup.portrait.x;

            this.arrowNudgeTween = new Tween(arrowGroup.landscape)
                .to({ x: startXLandscape + 30 }, 1000)
                .easing(Easing.Sinusoidal.InOut)
                .yoyo(true)
                .repeat(Number.MAX_SAFE_INTEGER)
                .onUpdate(() => {
                    const offset = arrowGroup.landscape.x - startXLandscape;
                    arrowGroup.portrait.x = startXPortrait + offset;
                })
                .start();
        });
    }

    public spawnDice() {
        this.destroyDice();

        while (this.dice.length < 2) {
            const dice = new HotRollDice(this.dice.length, this);
            this.dice.push(dice);

            dice.onStateChange.add((index: number, state: DiceState, lastState: DiceState) => this.onDiceStateChanged(index, state, lastState));
            dice.setState(DiceState.PreRoll);
        }

        this.updateRollCount();
    }

    public getDice(): HotRollDice[] { return this.dice; }

    public getResult() { return this.currentResult; }
    public setResult(result: HotRollBonusDiceRollResult) {
        this.currentResult = result;
        this.updateRollCount();

        const transform = result.isGoldenRoll && this.dice[0].diceType === DiceType.Red;
        const revert = !result.isGoldenRoll && this.dice[0].diceType === DiceType.Gold;

        this.dice.forEach((dice: HotRollDice) => {
            if (transform) {
                dice.setState(DiceState.Transform);
                this.showGoldBorder(true);
            } else {
                if (revert) {
                    dice.setDiceType(DiceType.Red);
                    this.showGoldBorder(false);
                }

                if (this.autoRollEnabled) {
                    dice.startRoll();
                } else {
                    dice.setState(DiceState.PreRoll);
                }
            }
        });
    }

    protected destroyDice() {
        this.dice.forEach((dice: HotRollDice) => { dice.destroy(); });
        this.dice = [];
    }

    protected update() {
        const deltaTime = (Timer.time - this.lastUpdate) / 1000;
        this.lastUpdate = Timer.time;

        if (this.dice.length > 0) {
            HotRollDiceComponent.TRAILS_ENABLED = this.rollCount >= 12;
            this.dice.forEach((dice: HotRollDice) => dice.update(deltaTime));
        }
    }

    protected showPreRollElements(visible: boolean) {
        if (!this.preRollElementsContainer) {
            this.preRollElementsContainer = new Container();
            this.preRollElementsContainer.addChild(
                gameLayers.Dice.getSprite("outline_red"),
                gameLayers.Dice.getSprite("outline_gold"),
                gameLayers.Dice.getButton("arrow"),
                gameLayers.Dice.getSprite("arrow_gold"),
                gameLayers.Dice.getSprite("arrow_text") || gameLayers.Dice.getText("arrow_text"),
                gameLayers.Dice.getSprite("double_pays_en"),
                gameLayers.Dice.getText("double_pays")
            );
            gameLayers.Dice.add(this.preRollElementsContainer, false, true);
        }

        gameLayers.Dice.getButton("arrow").interactive = visible;
        gameLayers.Dice.getSprite("arrow_gold").visible = this.dice[0].diceType === DiceType.Gold;

        gameLayers.Dice.getSprite("double_pays_en").visible = this.dice[0].diceType === DiceType.Gold && Services.get(TranslationsService).playingInEnglish();
        gameLayers.Dice.getText("double_pays").visible = this.dice[0].diceType === DiceType.Gold && !Services.get(TranslationsService).playingInEnglish();

        gameLayers.Dice.getSprite("outline_red").visible = this.dice[0].diceType === DiceType.Red;
        gameLayers.Dice.getSprite("outline_gold").visible = this.dice[0].diceType === DiceType.Gold;

        fade(this.preRollElementsContainer, this.preRollElementsContainer.alpha, visible ? 1 : 0, 100).execute();
    }

    protected showGoldBorder(show: boolean, fadeTime: number = 200) {
        if (show) {
            gameLayers.GoldBorder.setScene("gold").execute();
        } else {
            gameLayers.GoldBorder.defaultScene().execute();
        }
    }

    protected setAutoRoll(enabled: boolean) {
        this.autoRollEnabled = enabled;

        gameLayers.Dice.getButton("autoroll_start").visible = !enabled;
        gameLayers.Dice.getButton("autoroll_stop").visible = enabled;

        if (enabled && this.dice.every((dice: HotRollDice) => dice.getState() === DiceState.PreRoll)) {
            this.dice.forEach((dice: HotRollDice) => dice.startRoll());
        }
    }

    protected updateRollCount() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.getResultsOfType(HotRollBonusDiceRollResult);
        const played = results.filter((result: HotRollBonusDiceRollResult) => result.played).length;

        this.rollCount = played + 1;
        Components.get(HotRollDiceRollCounterComponent).setValue(this.rollCount);
    }

    protected onDiceStateChanged(index: number, state: DiceState, lastState: DiceState) {
        switch (state) {
            case DiceState.PreRoll:
                if (index === 0) {
                    this.showPreRollElements(true);
                    Services.get(SoundService).customEvent(HotRollSoundEvents.dice_slide);

                    if (lastState === DiceState.PostRoll) {
                        this.instructionIndex = 0;
                        this.nextInstructionTimeout = Timer.setTimeout(() => this.playInstructionVO(), 10000);
                    }
                }

                if (lastState === DiceState.Dragging) {
                    this.dice.find((dice: HotRollDice) => dice.getState() === DiceState.DragFollow)?.setState(DiceState.PreRoll);
                }
                break;

            case DiceState.Dragging:
                this.showPreRollElements(false);

                // Set the other dice to follow the one we're dragging
                this.dice.find((dice: HotRollDice) => dice.getIndex() !== index).setState(DiceState.DragFollow);
                break;

            case DiceState.Rolling:
                if (index === 0) {
                    this.showPreRollElements(false);

                    Services.get(SoundService).customEvent(HotRollSoundEvents.stop_instructions);
                    Timer.clearTimeout(this.nextInstructionTimeout);

                    const soundEvents = [
                        HotRollSoundEvents.dice_tumble_a,
                        HotRollSoundEvents.dice_tumble_b,
                        HotRollSoundEvents.dice_tumble_c,
                        HotRollSoundEvents.dice_tumble_d,
                        HotRollSoundEvents.dice_tumble_e,
                        HotRollSoundEvents.dice_tumble_f
                    ];
                    Services.get(SoundService).customEvent(RandomFromArray(soundEvents));
                }

                const rollingDice = this.dice[index];

                // Get the other dice that was following (if player was dragging), and set it rolling with a similar but slightly offset direction
                const followingDice = this.dice.find((dice: HotRollDice) => dice.getState() === DiceState.DragFollow);
                if (followingDice) {
                    const dir = rollingDice.getDirection();

                    for (const orientation of [Orientation.LANDSCAPE, Orientation.PORTRAIT]) {
                        const pnt = dir[orientation].coordsToPoint();
                        // pnt.x += RandomRangeFloat(-0.2, 0.2);
                        pnt.y += (index === 0) ? 0.2 : -0.2;

                        const normalized = pnt.normalize();
                        dir[orientation].set(normalized.x, normalized.y);
                    }

                    followingDice.startRoll(dir);
                }
                break;

            case DiceState.PostRoll:
                if (index === 0) {
                    this.rollComplete.dispatch();

                    Services.get(SoundService).customEvent(HotRollSoundEvents.dice_tumble_stop);
                    Services.get(SoundService).customEvent(HotRollSoundEvents[`dice_award_${this.currentResult.diceSum}`]);

                    this.playDiceResultVO();
                    this.playCheerVO();

                    const results = gameState.getCurrentGame().getCurrentRecord().getResultsOfType(HotRollBonusDiceRollResult);
                    if (results.every((result: HotRollBonusDiceRollResult) => result.played)) {
                        this.setAutoRoll(false);
                        gameLayers.Dice.getButton("autoroll_start").setEnabled(false);
                    }
                }
                break;

            case DiceState.Transform:
                if (index === 0) {
                    this.showPreRollElements(false);

                    new Parallel([
                        () => gameLayers.DiceTransform.setScene("transform"),
                        () => Contract.wrap(() => {
                            Timer.setTimeout(() => {
                                this.dice.forEach((dice: HotRollDice) => dice.setDiceType(DiceType.Gold));
                            }, 1000);
                        })
                    ]).then(() => {
                        this.dice.forEach((dice: HotRollDice) => {
                            if (this.autoRollEnabled) {
                                dice.startRoll();
                            } else {
                                dice.setState(DiceState.PreRoll);
                            }
                        });
                    });
                }
                break;
        }
    }

    protected playInstructionVO() {
        const instructions = [
            [HotRollSoundEvents.dice_instruction_1],
            [HotRollSoundEvents.dice_instruction_2a, HotRollSoundEvents.dice_instruction_2b, HotRollSoundEvents.dice_instruction_2c],
            [HotRollSoundEvents.dice_instruction_3a, HotRollSoundEvents.dice_instruction_3b]
        ];

        const record = gameState.getCurrentGame().getCurrentRecord();
        const bonusResults = record.getResultsOfType(HotRollBonusDiceRollResult);
        const is7OutBonus = !!bonusResults.find((result) => result.is7Out);

        if (this.instructionIndex === 1 && !is7OutBonus) {
            // This level says "roll until you hit a 7", and won't apply if not a 7 out bonus, so skip
            this.instructionIndex++;
        }

        const snd = RandomFromArray(instructions[this.instructionIndex]);

        Services.get(SoundService).customEvent(snd);

        this.nextInstructionTimeout = Timer.setTimeout(() => this.playInstructionVO(),
            3000 + Services.get(SoundService).getSoundDuration(snd));

        this.instructionIndex++;
        if (this.instructionIndex >= instructions.length) { this.instructionIndex = 0; }
    }

    protected playDiceResultVO() {
        const isHard = this.currentResult.diceValues[0] === this.currentResult.diceValues[1];
        let soundEventName: string;

        switch (this.currentResult.diceSum) {
            case 2:
                soundEventName = RandomFromArray([
                    HotRollSoundEvents.dice_snakeeyes,
                    HotRollSoundEvents.dice_two,
                    HotRollSoundEvents.dice_aces
                ]);
                break;

            case 3:
                soundEventName = HotRollSoundEvents.dice_acedeuce;
                break;

            case 4:
                if (isHard) {
                    soundEventName = RandomFromArray([
                        HotRollSoundEvents.dice_four_hard,
                        HotRollSoundEvents.dice_doubledeuce
                    ]);
                } else {
                    soundEventName = HotRollSoundEvents.dice_four_easy;
                }
                break;

            case 5:
                soundEventName = HotRollSoundEvents.dice_five;
                break;

            case 6:
                if (isHard) {
                    soundEventName = HotRollSoundEvents.dice_six_hard;
                } else {
                    soundEventName = RandomFromArray([
                        HotRollSoundEvents.dice_bigsix,
                        HotRollSoundEvents.dice_six_easy
                    ]);
                }
                break;

            case 7:
                if (this.currentResult.is7Out) {
                    soundEventName = HotRollSoundEvents.dice_sevenout;
                } else {
                    soundEventName = HotRollSoundEvents.dice_seven;
                }
                break;

            case 8:
                if (isHard) {
                    soundEventName = HotRollSoundEvents.dice_eight_hard;
                } else {
                    soundEventName = RandomFromArray([
                        HotRollSoundEvents.dice_eight,
                        HotRollSoundEvents.dice_eight_easy
                    ]);
                }
                break;

            case 9:
                soundEventName = HotRollSoundEvents.dice_nine;
                break;

            case 10:
                if (isHard) {
                    soundEventName = HotRollSoundEvents.dice_ten_hard;
                } else {
                    soundEventName = HotRollSoundEvents.dice_ten_easy;
                }
                break;

            case 11:
                soundEventName = HotRollSoundEvents.dice_eleven;
                break;

            case 12:
                soundEventName = RandomFromArray([
                    HotRollSoundEvents.dice_boxcars,
                    HotRollSoundEvents.dice_route66
                ]);
                break;
        }

        Services.get(SoundService).customEvent(soundEventName);
    }

    protected playCheerVO() {
        const is7OutBonus = gameState.getCurrentGame().getCurrentRecord()
            .getResultsOfType(HotRollBonusDiceRollResult).some((result) => result.is7Out);

        if (this.currentResult.is7Out) {
            Services.get(SoundService).customEvent(HotRollSoundEvents.dice_7_aww);
        } else {
            let cheerCount = this.rollCount;
            if (is7OutBonus) { cheerCount -= 3; } // To start cheers on 4th roll in hot rolls

            if (cheerCount > 15) {
                Services.get(SoundService).customEvent(HotRollSoundEvents["cheer" + RandomRangeInt(11, 15)]);
            } else {
                Services.get(SoundService).customEvent(HotRollSoundEvents["cheer" + cheerCount]);
            }
        }
    }
}

class HotRollDice {
    public static readonly SIZE: number = 275;

    public static POPULATE_STATIC_MATERIALS() {
        for (const type of ["red", "gold"]) {
            // Create PIXI sprites from which we'll feed textures through to THREE
            // Ordering was trial and error to get faces accurate to a real dice
            const diceSprites = [
                Services.get(GraphicsService).createSprite(`dice_${type}_4`),
                Services.get(GraphicsService).createSprite(`dice_${type}_3`),
                Services.get(GraphicsService).createSprite(`dice_${type}_2`),
                Services.get(GraphicsService).createSprite(`dice_${type}_5`),
                Services.get(GraphicsService).createSprite(`dice_${type}_1`),
                Services.get(GraphicsService).createSprite(`dice_${type}_6`)
            ];

            // Create THREE materials by converting PIXI sprite textures to base64
            // and then feeding that into THREE's TextureLoader.
            // This means dice textures can be atlased and loaded as we always do.
            const textureLoader = new THREE.TextureLoader();
            const materials = diceSprites.map((diceSprite: Sprite) => {
                const mat = new THREE.MeshLambertMaterial({
                    map: textureLoader.load(
                        Services.get(CanvasService).renderer.extract.base64(diceSprite)
                    )
                });
                diceSprite.destroy();
                return mat;
            });

            HotRollDice.MATERIALS[type] = materials;
        }
    }

    protected static readonly V_MIN: number = 2500;
    protected static readonly V_MAX: number = 4000;

    protected static readonly FACE_ROTATIONS = [
        { x: 0, y: 0, z: 0 },
        { x: Math.PI / 2, y: 0, z: 0 },
        { x: 0, y: Math.PI / 2, z: 0 },
        { x: 0, y: -Math.PI / 2, z: 0 },
        { x: -Math.PI / 2, y: 0, z: 0 },
        { x: 0, y: Math.PI, z: 0 }
    ];

    protected static readonly MATERIALS: {
        red: THREE.MeshLambertMaterial[],
        gold: THREE.MeshLambertMaterial[]
    } = { red: [], gold: [] };

    public onStateChange: Signal = new Signal();

    public diceType: DiceType = DiceType.Red;

    protected state: DiceState;

    protected dragStartTimeout: number;
    protected dragPositions: Point[] = [];

    protected scene: THREE.Scene;
    protected camera: THREE.PerspectiveCamera;
    protected renderer: THREE.WebGLRenderer;
    protected mesh: THREE.Mesh;

    protected sprite: Sprite;

    protected emitters: {
        red: Emitter[],
        gold: Emitter[]
    } = { red: [], gold: [] };

    protected value: number = 1;

    protected direction: DualPosition = new DualPosition();
    protected speed: number = 0;

    protected cancelGroup: CancelGroup = new CancelGroup();

    constructor(public index: number, protected parentComponent: HotRollDiceComponent) {
        // Create THREE scene, camera and renderer
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(5, 1, 0.1, 10000);
        this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: false });
        this.renderer.setSize(HotRollDice.SIZE, HotRollDice.SIZE);

        // Create scene lights
        const ambientLight = new THREE.AmbientLight(0xA8BBFF, 0.6);
        const pointLight = new THREE.PointLight(0xFFFFFF, 0.7);
        this.scene.add(ambientLight, pointLight);

        // Create dice mesh
        this.mesh = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), HotRollDice.MATERIALS.red);
        this.scene.add(this.mesh);
        this.mesh.position.z = -19;

        // Get start position
        const startPos = gameLayers.Dice.getPosition("dice_start_" + this.index);

        // Create PIXI sprite we'll use to render the dice in-game
        this.sprite = Services.get(GraphicsService).createBlankSprite();
        this.sprite.texture = PIXI.Texture.from(this.renderer.domElement);
        gameLayers.Dice.add(CenterPivot(this.sprite));

        this.sprite.landscape.set(startPos.landscape.x, startPos.landscape.y, HotRollDice.SIZE, HotRollDice.SIZE);
        this.sprite.portrait.set(startPos.portrait.x, startPos.portrait.y, HotRollDice.SIZE, HotRollDice.SIZE);

        this.sprite.interactive = true;
        this.sprite.on(ButtonEvent.POINTER_DOWN.getPIXIEventString(), () => this.onMouseDown());
        this.sprite.on(ButtonEvent.POINTER_UP.getPIXIEventString(), () => this.onMouseUp(true));
        this.sprite.on(ButtonEvent.POINTER_UP_OUTSIDE.getPIXIEventString(), () => this.onMouseUp(false));
        this.sprite.on(ButtonEvent.POINTER_MOVE.getPIXIEventString(), (e) => this.onDrag(e));

        // Create trail emitters
        const containers = [new ParticleContainer(), new ParticleContainer(), new ParticleContainer(), new ParticleContainer()];
        this.emitters.red = [
            Services.get(ParticleService).add("dice_fire", containers[0]).emitter,
            Services.get(ParticleService).add("dice_red_trail", containers[1]).emitter
        ];
        this.emitters.gold = [
            Services.get(ParticleService).add("dice_stars", containers[2]).emitter,
            Services.get(ParticleService).add("dice_gold_trail", containers[3]).emitter
        ];
        containers.forEach((container) => gameLayers.Dice.add(container, false, true));
    }

    public setDiceType(type: DiceType) {
        this.mesh.material = HotRollDice.MATERIALS[type];
        this.diceType = type;
    }

    public rollToValue(value: number): Contract {
        if (value < 1 || value > 6) { return Contract.empty(); }
        const oldValue = this.value;
        this.value = value;

        const duration = 2500 + RandomRangeInt(-125, 125);

        return new Contract((resolve) => {
            const targetRotation = HotRollDice.FACE_ROTATIONS[value - 1];

            // Offset current dice rotation by x full turns, in a random direction for each axis
            const rotationStartOffset = (Math.PI * 2) * 2;
            this.mesh.rotation.x += rotationStartOffset * RandomSign();
            this.mesh.rotation.y += rotationStartOffset * RandomSign();
            this.mesh.rotation.z += rotationStartOffset * RandomSign();

            // First we want to rotate to our target face, but offset by those same x turns
            // Doing this here makes the end of the roll smoother, so it doesn't look like it's snapping to the correct value
            const initialRotation = deepClone(targetRotation);
            initialRotation.x += rotationStartOffset * Math.sign(this.mesh.rotation.x);
            initialRotation.y += rotationStartOffset * Math.sign(this.mesh.rotation.y);
            initialRotation.z += rotationStartOffset * Math.sign(this.mesh.rotation.z);

            // The problem with the above is that if we're rolling to the same value we started on, the dice won't spin for the initialRotation phase
            // So if that's the case, rotate start position by another full turn in each direction
            if (value === oldValue) {
                this.mesh.rotation.x += (Math.PI * 2) * Math.sign(this.mesh.rotation.x);
                this.mesh.rotation.y += (Math.PI * 2) * Math.sign(this.mesh.rotation.y);
                this.mesh.rotation.z += (Math.PI * 2) * Math.sign(this.mesh.rotation.z);
            }

            const initialTween = this.cancelGroup.tween(this.mesh.rotation)
                .to({ x: initialRotation.x, y: initialRotation.y, z: initialRotation.z }, duration * 0.15);

            const targetTween = this.cancelGroup.tween(this.mesh.rotation)
                .to({ x: targetRotation.x, y: targetRotation.y, z: targetRotation.z }, duration * 0.85)
                .easing(Easing.Sinusoidal.Out)
                .onComplete(() => {
                    resolve();
                    Timer.setTimeout(() => this.setState(DiceState.PostRoll), 500);
                });

            initialTween.chain(targetTween);
            initialTween.start();

            this.cancelGroup.tween(this)
                .to({ speed: 0 }, duration * 0.75)
                .delay(duration * 0.25)
                .start();
        });
    }

    public update(deltaTime: number) {
        switch (this.state) {
            case DiceState.PreRoll: {
                const startPos = gameLayers.Dice.getPosition("dice_start_" + this.index);
                const t = Math.min(deltaTime * 10, 1);

                this.sprite.landscape.x = Lerp(this.sprite.landscape.x, startPos.landscape.x, t);
                this.sprite.landscape.y = Lerp(this.sprite.landscape.y, startPos.landscape.y, t);
                this.sprite.portrait.x = Lerp(this.sprite.portrait.x, startPos.portrait.x, t);
                this.sprite.portrait.y = Lerp(this.sprite.portrait.y, startPos.portrait.y, t);
                break;
            }

            case DiceState.DragFollow: {
                const target = gameLayers.Dice.getSprite("drag_follow_target");
                const yOffset = ((this.index === 0) ? -HotRollDice.SIZE : HotRollDice.SIZE) * 0.75;
                const t = Math.min(deltaTime * 30, 1);

                this.sprite.landscape.x = Lerp(this.sprite.landscape.x, target.landscape.x, t);
                this.sprite.landscape.y = Lerp(this.sprite.landscape.y, target.landscape.y + yOffset, t);
                this.sprite.portrait.x = Lerp(this.sprite.portrait.x, target.portrait.x, t);
                this.sprite.portrait.y = Lerp(this.sprite.portrait.y, target.portrait.y + yOffset, t);
                break;
            }

            case DiceState.Rolling: {
                this.sprite.landscape.x += this.direction.landscape.x * deltaTime * this.speed;
                this.sprite.landscape.y += this.direction.landscape.y * deltaTime * this.speed;
                this.sprite.portrait.x += this.direction.portrait.x * deltaTime * this.speed;
                this.sprite.portrait.y += this.direction.portrait.y * deltaTime * this.speed;

                this.handleCollision();

                break;
            }

            case DiceState.PostRoll: {
                const endPos = gameLayers.Dice.getPosition("dice_end_" + this.index);
                const t = Math.min(deltaTime * 10, 1);

                this.sprite.landscape.x = Lerp(this.sprite.landscape.x, endPos.landscape.x, t);
                this.sprite.landscape.y = Lerp(this.sprite.landscape.y, endPos.landscape.y, t);
                this.sprite.portrait.x = Lerp(this.sprite.portrait.x, endPos.portrait.x, t);
                this.sprite.portrait.y = Lerp(this.sprite.portrait.y, endPos.portrait.y, t);
                break;
            }

            case DiceState.Transform: {
                const endPos = gameLayers.Dice.getPosition("dice_transform_" + this.index);
                const t = Math.min(deltaTime * 10, 1);

                this.sprite.landscape.x = Lerp(this.sprite.landscape.x, endPos.landscape.x, t);
                this.sprite.landscape.y = Lerp(this.sprite.landscape.y, endPos.landscape.y, t);
                this.sprite.portrait.x = Lerp(this.sprite.portrait.x, endPos.portrait.x, t);
                this.sprite.portrait.y = Lerp(this.sprite.portrait.y, endPos.portrait.y, t);
                break;
            }

            case DiceState.Dragging: {
                // Update dragPositions which is used to calculate roll direction
                this.dragPositions.push(this.sprite[deviceInfo.getOrientation()].coordsToPoint());
                while (this.dragPositions.length > 5) { this.dragPositions.shift(); }
                break;
            }
        }

        this.renderer.render(this.scene, this.camera);
        this.sprite.texture.update();

        this.emitters.red.forEach((emitter: Emitter) => {
            emitter.updateSpawnPos(this.sprite[deviceInfo.getOrientation()].x, this.sprite[deviceInfo.getOrientation()].y);
            emitter.emit = this.diceType === DiceType.Red && this.speed > 0 && HotRollDiceComponent.TRAILS_ENABLED;
        });
        this.emitters.gold.forEach((emitter: Emitter) => {
            emitter.updateSpawnPos(this.sprite[deviceInfo.getOrientation()].x, this.sprite[deviceInfo.getOrientation()].y);
            emitter.emit = this.diceType === DiceType.Gold && this.speed > 0;
        });
    }

    public getIndex() { return this.index; }

    public getPosition() { return this.sprite.dualPosition.clone(); }

    public getDirection() { return this.direction.clone(); }
    public setDirection(dir: DualPosition) { this.direction = dir; }

    public getState() { return this.state; }
    public setState(state: DiceState) {
        if (state === this.state) { return; }

        const lastState = this.state;
        this.state = state;

        this.onStateChange.dispatch(this.index, state, lastState);
    }

    public startRoll(direction?: DualPosition) {
        if (!direction) {
            direction = new DualPosition();

            const dirLandscape = new Point(1.25, -1.25 + this.index).normalize();
            direction.landscape.set(dirLandscape.x, dirLandscape.y);

            const dirPortrait = new Point(0.5 + this.index, -1.25).normalize();
            direction.portrait.set(dirPortrait.x, dirPortrait.y);
        }

        this.direction = direction;

        this.speed = RandomRangeFloat(HotRollDice.V_MIN, HotRollDice.V_MAX);

        this.setState(DiceState.Rolling);
        this.rollToValue(this.parentComponent.getResult().diceValues[this.index]).execute();
    }

    public destroy() {
        this.scene.clear();

        this.scene = null;
        this.camera = null;

        this.renderer.forceContextLoss();
        this.renderer.dispose();
        this.renderer = null;

        try {
            this.sprite.destroy();
        } catch (e) {
            // if scene has already changed this will fail
        }
        this.sprite = null;

        this.emitters.red.forEach((emitter) => { emitter.destroy(); });
        this.emitters.gold.forEach((emitter) => { emitter.destroy(); });

        this.cancelGroup.cancel();
    }

    protected handleCollision() {
        const bounds = gameLayers.Dice.bounds;
        const currentOrientation = Services.get(CanvasService).orientation;
        for (const orientation of [Orientation.LANDSCAPE, Orientation.PORTRAIT]) {
            /**
             * Other Dice
             */
            this.parentComponent.getDice().forEach((other: HotRollDice) => {
                if (other.index > this.index) {
                    const p0 = this.getPosition()[orientation].getCenterPoint();
                    const p1 = other.getPosition()[orientation].getCenterPoint();
                    const distance = p0.distanceTo(p1);

                    if (distance <= HotRollDice.SIZE * 0.8) { // is collision
                        const dir0 = this.getDirection();
                        const dir1 = other.getDirection();

                        const mag0 = dir0[orientation].getCenterPoint().getMagnitude();
                        const mag1 = dir1[orientation].getCenterPoint().getMagnitude();

                        const bounceDir0 = p0.subtract(p1).normalize().multiply(mag0);
                        const bounceDir1 = p1.subtract(p0).normalize().multiply(mag1);

                        dir0[orientation].set(bounceDir0.x, bounceDir0.y);
                        dir1[orientation].set(bounceDir1.x, bounceDir1.y);

                        this.setDirection(dir0);
                        other.setDirection(dir1);

                        const soundEvents = [
                            HotRollSoundEvents.dice_hit_dice_a,
                            HotRollSoundEvents.dice_hit_dice_b,
                            HotRollSoundEvents.dice_hit_dice_c
                        ];
                        Services.get(SoundService).customEvent(RandomFromArray(soundEvents));
                    }
                }
            });

            /**
             * Screen Edges
             */
            const isCollisionLeft = this.sprite[orientation].x <= (bounds[orientation].x + this.sprite[orientation].width / 2);
            const isCollisionRight = this.sprite[orientation].x >= ((bounds[orientation].x + bounds[orientation].width) - (this.sprite[orientation].width / 2));
            const isCollisionUp = this.sprite[orientation].y <= (bounds[orientation].y + this.sprite[orientation].height / 2);
            const isCollisionDown = this.sprite[orientation].y >= ((bounds[orientation].y + bounds[orientation].height) - (this.sprite[orientation].height / 2));
            let isValidCollision = false;

            if ((isCollisionLeft && this.direction[orientation].x < 0) || (isCollisionRight && this.direction[orientation].x > 0)) {
                this.direction[orientation].x *= -1;
                isValidCollision = orientation === currentOrientation;
            }

            if ((isCollisionUp && this.direction[orientation].y < 0) || (isCollisionDown && this.direction[orientation].y > 0)) {
                this.direction[orientation].y *= -1;
                isValidCollision = orientation === currentOrientation;
            }

            if (isValidCollision) {
                const soundEvents = [
                    HotRollSoundEvents.dice_hit_wall_a,
                    HotRollSoundEvents.dice_hit_wall_b,
                    HotRollSoundEvents.dice_hit_wall_c
                ];
                Services.get(SoundService).customEvent(RandomFromArray(soundEvents));
            }
        }
    }

    protected onMouseDown() {
        switch (this.state) {
            case DiceState.PreRoll:
                this.dragStartTimeout = Timer.setTimeout(() => this.startDrag(), 125);
                break;
        }
    }

    protected onMouseUp(over: boolean) {
        switch (this.state) {
            case DiceState.PreRoll:
                Timer.clearTimeout(this.dragStartTimeout);

                if (over) {
                    if (++this.value > 6) { this.value = 1; }
                    // new Tween(this.mesh.rotation)
                    //     .to({
                    //         x: HotRollDice.FACE_ROTATIONS[this.value - 1].x,
                    //         y: HotRollDice.FACE_ROTATIONS[this.value - 1].y,
                    //         z: HotRollDice.FACE_ROTATIONS[this.value - 1].z
                    //     }, 200)
                    //     .easing(Easing.Cubic.Out)
                    //     .start();
                    this.mesh.rotation.x = HotRollDice.FACE_ROTATIONS[this.value - 1].x;
                    this.mesh.rotation.y = HotRollDice.FACE_ROTATIONS[this.value - 1].y;
                    this.mesh.rotation.z = HotRollDice.FACE_ROTATIONS[this.value - 1].z;
                }
                break;

            case DiceState.Dragging:
                this.endDrag();
                break;
        }
    }

    protected startDrag() {
        this.setState(DiceState.Dragging);

        const followTarget = gameLayers.Dice.getSprite("drag_follow_target");
        followTarget.landscape.set(this.sprite.landscape.x, this.sprite.landscape.y);
        followTarget.portrait.set(this.sprite.portrait.x, this.sprite.portrait.y);
    }

    protected endDrag() {
        const launchDir = new Point();

        this.dragPositions.forEach((pos, index) => {
            if (index > 0) {
                launchDir.x += pos.x - this.dragPositions[index - 1].x;
                launchDir.y += pos.y - this.dragPositions[index - 1].y;
            }
        });

        if (launchDir.getMagnitude() > 1) {
            const normalized = launchDir.normalize();
            const direction = new DualPosition();
            direction.landscape.set(normalized.x, normalized.y);
            direction.portrait.set(normalized.x, normalized.y);

            this.startRoll(direction);
        } else {
            this.dragPositions.length = 0;
            this.setState(DiceState.PreRoll);
        }
    }

    protected onDrag(event: InteractionEvent) {
        switch (this.state) {
            case DiceState.Dragging:
                const orientation: Orientation = deviceInfo.getOrientation();
                const layerPosition: Position = gameLayers.Dice.container.getPosition(orientation);
                const pointerLocal = gameLayers.Dice.globalToLocal(
                    new Position(event.data.global.x, event.data.global.y),
                    layerPosition
                );

                // Clamp within layer bounds
                const layerBounds = gameLayers.Dice.bounds[orientation];
                pointerLocal.x = Math.max(pointerLocal.x, layerBounds.x + (HotRollDice.SIZE / 2));
                pointerLocal.x = Math.min(pointerLocal.x, layerBounds.x + layerBounds.width - (HotRollDice.SIZE / 2));
                pointerLocal.y = Math.max(pointerLocal.y, layerBounds.y + (HotRollDice.SIZE / 2));
                pointerLocal.y = Math.min(pointerLocal.y, layerBounds.y + layerBounds.height - (HotRollDice.SIZE / 2));

                this.sprite[orientation].set(pointerLocal.x, pointerLocal.y);

                // Update drag follow target position, for the other dice to follow
                const followTarget = gameLayers.Dice.getSprite("drag_follow_target");
                followTarget.landscape.set(pointerLocal.x, pointerLocal.y);
                followTarget.portrait.set(pointerLocal.x, pointerLocal.y);

                break;
        }
    }
}
