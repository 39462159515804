import { Components } from "appworks/components/components";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { State } from "appworks/state-machine/states/state";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { pulse } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { HotRollBonusMultipleChoicePickComponent } from "components/hotroll-bonus-multiple-choice-pick-component";
import { HotRollDicePaytableComponent } from "components/hotroll-dice-paytable-component";
import { HotRollDiceRollCounterComponent } from "components/hotroll-dice-roll-counter-component";
import { gameLayers } from "game-layers";
import { HotRollBonusDiceRollResult } from "model/results/hotroll-bonus-dice-roll-result";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { applyRedBlackTextStroke } from "util/hr-text-style";

export class HotRollEnterBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const result = record.getResultsOfType(HotRollBonusDiceRollResult).find((r) => !r.played);

        this.multipleChoicePick().then(() => {
            gameLayers.ScreenFade.setScene("flame_transition").execute();
            Timer.setTimeout(() => {
                new Sequence([
                    () => gameLayers.BonusChoice.defaultScene(),
                    () => Components.get(HotRollDiceRollCounterComponent).show(),
                    () => Components.get(HotRollDicePaytableComponent).show(result),
                    () => gameLayers.Logo.setScene("bonus"),
                    () => this.multiplierSequence(result),
                    () => this.showBonusIntroText(),
                    () => Contract.wrap(() => Components.get(HotRollDicePaytableComponent).setResult(result)),
                    () => gameLayers.Dice.setScene("bonus")
                ]).then(() => this.complete());
            }, 700);
        });
    }

    protected showBonusIntroText(): Contract {
        const translations = Services.get(TranslationsService);

        const record = gameState.getCurrentGame().getCurrentRecord();
        const is7OutBonus = record.getResultsOfType(HotRollBonusDiceRollResult).some((result) => result.is7Out);
        const multipleLives = record.getFirstResultOfType(HotRollBonusDiceRollResult).livesTotal > 1;

        let introText: Text;
        return new Sequence([
            () => new Parallel([
                () => gameLayers.Dice.setScene("bonusintrotext"),
                () => Contract.wrap(() => {
                    introText = gameLayers.Dice.getText("introtext");
                    applyRedBlackTextStroke(introText);

                    introText.text = translations.get("bonus_intro_text_main");

                    if (is7OutBonus) {
                        introText.text += "\n7 - ";
                        introText.text += translations.get(multipleLives ? "bonus_intro_text_7out_loselife" : "bonus_intro_text_7out_end");
                    }
                })
            ]),
            () => Contract.getTimeoutContract(2500)
        ]);
    }

    protected multiplierSequence(result: HotRollBonusDiceRollResult): Contract {
        const multiplier = result.isGoldenRoll ? result.multiplier / 2 : result.multiplier;
        if (multiplier === 1) { return Contract.empty(); }

        let img: Sprite;

        const contracts: Array<() => Contract> = [
            () => gameLayers.MultiplierSequence.setScene(`x${multiplier}`),
            () => Contract.wrap(() => img = gameLayers.MultiplierSequence.getSprite("img")),
            () => Contract.getTimeoutContract(750)
        ];

        for (let i = 2; i <= multiplier; i++) {
            contracts.push(
                () => new Sequence([
                    () => new Parallel([
                        () => pulse(img, { x: 1.25, y: 1.25 }, 750, Easing.Sinusoidal.InOut),
                        () => Components.get(HotRollDicePaytableComponent).setMultiplierWithTickup(i, 750)
                    ]),
                    () => Contract.getTimeoutContract(250)
                ])
            );
        }

        contracts.push(() => gameLayers.MultiplierSequence.defaultScene());

        return new Sequence(contracts);
    }

    // levels 7 and 8 have an extra step, you pick multiple rolls or one multiplied spin,
    // then there's this additional random pick of 2x,3x,4x,5x
    protected multipleChoicePick(): Contract {
        const level = Components.get(SlingoLadderComponent).getCurrentLevel();

        if (level === 7 || level === 8) {
            const record = gameState.getCurrentGame().getCurrentRecord();
            const results = record.getResultsOfType(HotRollBonusDiceRollResult);

            const rollCount = results.length;
            const multiplier = results.find((result) => !result.isGoldenRoll).multiplier;

            return Components.get(HotRollBonusMultipleChoicePickComponent).show(
                (multiplier > 1) ? multiplier : rollCount,
                (multiplier > 1) ? "multiplier" : "rolls"
            );
        }

        return Contract.empty();
    }
}
