import { gameState } from "appworks/model/game-state";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { HotRollSpinState } from "state-machine/states/hotroll-spin-state";
import { SocialStateMachine } from "../social-state-machine";
import { BlastworksClientEvent } from "appworks/state-machine/ClientController";

export class SocialSpinState extends HotRollSpinState {
    protected onReelLand(reelIndex: number, match?: { matchedValue: number; reelIndex: number; }): void {
        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoReelSpinResult);
        const landedSymbol = result.symbols[reelIndex];

        if (landedSymbol === "FS") {
            (this.stateMachine as SocialStateMachine).dispatchClientEvent(BlastworksClientEvent.REEL_STOP_FREE_SPIN);
        }

        if (landedSymbol === "J") {
            (this.stateMachine as SocialStateMachine).dispatchClientEvent(BlastworksClientEvent.REEL_STOP_JOKER);
        }

        if (landedSymbol === "SJ") {
            (this.stateMachine as SocialStateMachine).dispatchClientEvent(BlastworksClientEvent.REEL_STOP_SUPER_JOKER);
        }

        super.onReelLand(reelIndex, match);
    }
}