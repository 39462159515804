import { Components } from "appworks/components/components";
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Exit } from "appworks/state-machine/data/exit";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { HotRollBonusMultipleChoicePickComponent } from "components/hotroll-bonus-multiple-choice-pick-component";
import { gameLayers } from "game-layers";
import { GMRAction } from "gaming-realms/integration/gmr-schema";
import { HotRollCollectRequestPayload } from "model/requests/hotroll-collect-request-payload";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoPurchaseSpinOrCollectState } from "slingo/states/slingo-purchase-spin-or-collect-state";
import { SlingoPotentialWin } from "slingo/util/slingo-get-potential-wins";
import { FooterComponent } from "slotworks/components/footer/footer-component";
import { HotRollHasBonusDecision } from "state-machine/decisions/hotroll-has-bonus-decision";
import { applyWhiteBlackTextStroke } from "util/hr-text-style";

export class HotRollPurchaseSpinOrCollectState extends SlingoPurchaseSpinOrCollectState {
    public onEnter(cascadeSkip?: boolean): void {
        super.onEnter();

        const ladderResult = gameState.getCurrentGame().getLatestResultOfType(SlingoLadderResult);

        const lblCollect = this.layer.getText("collect");
        const lblEndGame = this.layer.getText("end_game");
        if (lblCollect) {
            lblCollect.visible = ladderResult.total >= 5;
            applyWhiteBlackTextStroke(lblCollect);
        }
        if (lblEndGame) {
            lblEndGame.visible = ladderResult.total < 5;
            applyWhiteBlackTextStroke(lblEndGame);
        }

        /**
         * If there's a completionreason sent by server (i.e. full house, stake limit),
         * fall straight through to collect and let that handle it
         */
        if (gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult).completionReason) {
            this.collect();
        }
    }

    public onExit(): void {
        super.onExit();
        Components.get(FooterComponent).clear();
    }

    // Hard code min win level as they're all bonuses, not values
    protected getMinWinLevel() {
        return 4;
    }

    protected hideElementsIfNoPotentialWins() {
        this.hidePrizeGraphics();
        this.showCurrentPrizeGraphicOnCollectButton();
        super.hideElementsIfNoPotentialWins();
    }

    protected showPotentialWin(win: SlingoPotentialWin) {
        super.showPotentialWin(win);

        this.hidePrizeGraphics();

        // Set text to not include value
        this.layer.getText("potential_win_body").text = Services.get(TranslationsService).get("you_could_win_hr");

        this.showCurrentPrizeGraphicOnCollectButton();

        // Show potential win graphic if applicable
        const potentialPrize = this.layer.getSprite(`pot_level${win.lines}`);
        if (potentialPrize) {
            potentialPrize.parent.addChild(potentialPrize);
            potentialPrize.visible = true;
        }

        const hrPrizeText = this.layer.getText("pot_prize");
        hrPrizeText.text = this.getPrizeNameString(win.lines);
        hrPrizeText.visible = hrPrizeText.text.length > 0;

        const hrDice = this.layer.getSprite("pot_dice");
        hrDice.visible = hrPrizeText.text.length > 0;
    }

    protected showCurrentPrizeGraphicOnCollectButton() {
        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoLadderResult);
        const currentPrize = this.layer.getSprite(`col_level${result.total}`);
        if (currentPrize) {
            currentPrize.parent.addChild(currentPrize);
            currentPrize.visible = true;
        }

        const prizeText = this.layer.getText("col_prize");
        prizeText.text = this.getPrizeNameString(result.total);
        prizeText.visible = prizeText.text.length > 0;
        this.layer.getSprite("col_dice").visible = prizeText.text.length > 0;
    }

    protected hidePrizeGraphics() {
        // Hide all prize graphics
        for (let prizeId = 5; prizeId < 13; prizeId++) {
            const potentialPrize = this.layer.getSprite(`pot_level${prizeId}`);
            if (potentialPrize) { potentialPrize.visible = false; }

            const collectPrize = this.layer.getSprite(`col_level${prizeId}`);
            if (collectPrize) { collectPrize.visible = false; }
        }

        this.layer.getSprite("pot_dice").visible = false;
        this.layer.getText("pot_prize").visible = false;
        this.layer.getSprite("col_dice").visible = false;
        this.layer.getText("col_prize").visible = false;
    }

    protected getPotentialWinDisplayElements(): PIXIElement[] {
        const elements = [
            CenterPivot(this.layer.getSprite("potential_win_frame")),
            CenterPivot(this.layer.getText("potential_win_body")),
            CenterPivot(this.layer.getText("pot_prize")),
            CenterPivot(this.layer.getSprite("pot_dice"))
        ];

        for (let prizeId = 5; prizeId < 13; prizeId++) {
            const potentialPrize = this.layer.getSprite(`pot_level${prizeId}`);
            if (potentialPrize) {
                elements.push(CenterPivot(potentialPrize));
            }
        }

        return elements;
    }

    protected collect(): void {
        // If we have a bonus, the collect request needs to include the player's pick choice
        const hasBonus = (new HotRollHasBonusDecision()).evaluate() === Exit.True;

        if (hasBonus) {
            new Sequence([
                () => Contract.wrap(() => {
                    Components.get(FooterComponent).clear();
                    Timer.clearInterval(this.potentialWinInterval);
                    this.potentialWins = null;
                }),
                () => this.disableButtons(),
                () => this.layer.defaultScene(),
                () => this.showBonusChoiceScreen(),
                (pickChoice) => this.makePickCollectRequest(pickChoice)
            ]).then(() => this.complete());
        } else {
            if (this.isCollectValid()) {
                super.collect();
            } else {
                this.layer.defaultScene().then(() => this.complete());
            }
        }
    }

    protected makePickCollectRequest(pickValue: number): Contract {
        if (!this.isCollectValid()) { return Contract.empty(); }

        return new Sequence([
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
            () => commsManager.request(new HotRollCollectRequestPayload(pickValue)),
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false))
        ]);
    }

    protected showBonusChoiceScreen(): Contract<number | null> {
        return new Contract((resolve) => {

            const bonusIndex = Components.get(SlingoLadderComponent).getCurrentLevel() - 4;
            Services.get(SoundService).customEvent(HotRollSoundEvents.bonus_start);
            Services.get(SoundService).event(HotRollSoundEvents.bonus_win_N as any, bonusIndex.toString());

            gameLayers.ScreenFade.setScene("flame_transition").execute();
            Timer.setTimeout(() => {
                Services.get(SoundService).customEvent(HotRollSoundEvents.bonus_intro_voice);

                Layers.setLayerOrder("bonus");
                uiFlags.set(UIFlag.BONUS, true);

                gameLayers.Logo.setScene("bonuschoice").execute();
                gameLayers.BonusChoice.setScene("level_" + Components.get(SlingoLadderComponent).getCurrentLevel()).then(() => {
                    applyWhiteBlackTextStroke(gameLayers.BonusChoice.getText("choice_1_description"));
                    applyWhiteBlackTextStroke(gameLayers.BonusChoice.getText("choice_2_description"));
                    applyWhiteBlackTextStroke(gameLayers.BonusChoice.getHTMLText("choice_1_description"));
                    applyWhiteBlackTextStroke(gameLayers.BonusChoice.getHTMLText("choice_2_description"));
                    applyWhiteBlackTextStroke(gameLayers.BonusChoice.getText("golden_roll"));

                    const buttons = [
                        gameLayers.BonusChoice.getButton("pick1"),
                        gameLayers.BonusChoice.getButton("pick2")
                    ].filter((btn: ButtonElement) => Boolean(btn));

                    if (gameLayers.BonusChoice.getText("higher_risk")) {
                        gameLayers.BonusChoice.getText("higher_risk").text = gameLayers.BonusChoice.getText("higher_risk")?.text.toLocaleUpperCase();
                        gameLayers.BonusChoice.getText("lower_risk").text = gameLayers.BonusChoice.getText("lower_risk")?.text.toLocaleUpperCase();
                    }

                    if (buttons.length) {
                        buttons.forEach((btn: ButtonElement, index: number) => {
                            btn.once(ButtonEvent.CLICK.getPIXIEventString(), () => {
                                buttons.forEach((b, i) => {
                                    b.setEnabled(false);
                                    if (i !== index) { b.alpha = 0.75; }
                                });
                                Components.get(HotRollBonusMultipleChoicePickComponent).hideHighlights();
                                Timer.setTimeout(() => resolve(index + 1), 500);
                            });
                        });
                    } else {
                        Timer.setTimeout(() => { resolve(null); }, 4000);
                    }
                });
            }, 700);
        });
    }

    protected animatePotentialWinDisplay(): Contract<void> {
        return super.animatePotentialWinDisplay(Easing.Quartic.Out);
    }

    protected isCollectValid(): boolean {
        const record: SlingoRecord = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
        return record.actions.indexOf(GMRAction.COLLECT) !== -1;
    }

    protected getPrizeNameString(lvl: number): string {
        switch (lvl) {
            case 5:
                return Services.get(TranslationsService).get("ladder_easyroll");

            case 6:
                return Services.get(TranslationsService).get("ladder_doubleroll");

            case 7:
                return Services.get(TranslationsService).get("ladder_doubledouble");

            case 8:
                return Services.get(TranslationsService).get("ladder_tripledouble");

            default:
                return "";
        }
    }
}
