import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { scaleIn } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";

export class HotRollBigWinValueTransition extends AbstractSceneTransition {
    public in(): Contract<any> {
        const valueText = this.layer.getBitmapText("value");

        let bigWin: PIXIElement;
        if (Services.get(TranslationsService).playingInEnglish()) {
            bigWin = this.layer.getSprite("bigwin") || this.layer.getSprite("fullhouse");
        } else {
            bigWin = this.layer.getText("bigwin") || this.layer.getText("fullhouse");
        }

        bigWin.landscape.scale.set(0);
        bigWin.portrait.scale.set(0);

        if (valueText) {
            CenterPivot(valueText);

            valueText.landscape.scale.set(0);
            valueText.portrait.scale.set(0);
        }

        const animations = [
            () => fadeIn(bigWin, 250),
            () => scaleIn(bigWin, 250)
        ];

        if (valueText) {
            animations.push(() => fadeIn(valueText, 250));
            animations.push(() => scaleIn(valueText, 250));
        }

        return new Parallel(animations);
    }

    public out(): Contract<any> {
        const valueText = this.layer.getBitmapText("value");

        let bigWin: PIXIElement;
        if (Services.get(TranslationsService).playingInEnglish()) {
            bigWin = this.layer.getSprite("bigwin") || this.layer.getSprite("fullhouse");
        } else {
            bigWin = this.layer.getText("bigwin") || this.layer.getText("fullhouse");
        }

        const animations = [
            () => fadeOut(bigWin, 500)
        ];

        if (valueText) {
            animations.push(() => fadeOut(valueText, 500));
        }

        return new Parallel(animations);
    }
}
