import { Gameplay } from "appworks/model/gameplay/gameplay";
import { AxiosRequestConfig } from "axios";
import { GMRCollectRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-collect-request-builder";

export class HotRollCollectRequestBuilder extends GMRCollectRequestBuilder {
    public build(gameplay: Gameplay, data: any): AxiosRequestConfig<any> {
        const request = super.build(gameplay, data);

        if (data.pickValue) {
            request.data = {
                playFeature: true,
                data: {
                    pick: `${data.pickValue}`
                }
            };
        }

        return request;
    }
}
