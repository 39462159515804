import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { scaleIn } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";

export class HotRollDiceWinValueTransition extends AbstractSceneTransition {
    public in(): Contract<any> {
        const contracts = [];

        this.getElements().forEach((element: PIXIElement) => {
            CenterPivot(element);
            element.landscape.scale.set(0);
            element.portrait.scale.set(0);

            contracts.push(() => new Parallel([
                () => fadeIn(element, 250),
                () => scaleIn(element, 250)
            ]));
        });

        return new Parallel(contracts);
    }

    public out(): Contract<any> {
        return fadeOut(this.getElements(), 250);
    }

    protected getElements(): PIXIElement[] {
        return [
            this.layer.getBitmapText("value"),
            this.layer.getText("dynamic"),
            this.layer.getSprite("sevenout"),
            this.layer.getSprite("gameover")
        ];
    }
}
