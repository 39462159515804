import { AbstractComponent } from "appworks/components/abstract-component";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { pulse, scale } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { HotRollBonusDiceRollResult } from "model/results/hotroll-bonus-dice-roll-result";
import { AdjustmentFilter, BloomFilter, ColorOverlayFilter, ColorReplaceFilter } from "pixi-filters";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { applyWhiteBlackTextStroke } from "util/hr-text-style";

export enum HotRollPaytableMode {
    NORMAL,
    SEVENOUT_FIRSTROLL,
    SEVENOUT
}

export class HotRollDicePaytableComponent extends AbstractComponent {
    protected layer = gameLayers.DicePaytable;
    protected multiplier: number;
    protected lives: number;
    protected tickupTween: Tween;
    protected totalWin: number = 0;
    protected mode: HotRollPaytableMode;

    public show(result: HotRollBonusDiceRollResult): Contract {
        return new Parallel([
            () => this.layer.setScene("bonus"),
            () => Contract.wrap(() => {
                this.setResult(result, 1);

                this.hideHearts();
                this.setLives(result.livesTotal ?? 0);

                this.layer.getParticle("dice_fire").emit = false;

                this.updateTotalWin(0, 0).execute();

                for (let i = 2; i <= 12; i++) {
                    this.layer.getShape("highlight_" + i).alpha = 0;
                }

                const multiplier = result.isGoldenRoll ? result.multiplier / 2 : result.multiplier;
                this.layer.getSprite("pays2x").visible = multiplier === 2;
                this.layer.getSprite("pays3x").visible = multiplier === 3;
                this.layer.getSprite("pays4x").visible = multiplier === 4;
                this.layer.getSprite("pays5x").visible = multiplier === 5;
            })
        ]);
    }

    public hide(): Contract {
        return this.layer.defaultScene();
    }

    public setLives(lives: number) {
        this.lives = lives;

        for (let i = 1; i <= 4; i++) {
            const sprite = gameLayers.DicePaytable.getSprite(`heart_${i}`);
            const shouldBeVisible = i <= lives;
            const isVisible = sprite.alpha > 0;

            if (shouldBeVisible) {
                sprite.alpha = 1;
            } else if (isVisible) {
                const particleContainer = this.layer.getParticleContainer("dice_fire");

                particleContainer.landscape.setPosition(sprite.landscape.getCenterPoint());
                particleContainer.portrait.setPosition(sprite.portrait.getCenterPoint());

                particleContainer.emitter.emit = true;

                const adjustmentFilter = new AdjustmentFilter();
                sprite.filters = [adjustmentFilter];

                this.layer.add(sprite);

                new Parallel([
                    () => fadeOut(sprite, 1000, Easing.Quadratic.In),
                    () => TweenContract.wrapTween(new Tween(adjustmentFilter).to({ brightness: 2, contrast: 0 }, 400)),
                    () => scale(sprite, { x: 1.5, y: 1.5 }, 1000, Easing.Sinusoidal.Out),
                ]).then(() => {
                    particleContainer.emitter.emit = false;
                });
            }
        }
    }

    public showWinValue(rollResult: HotRollBonusDiceRollResult): Contract {
        const timeoutValue = 2500;

        const paytableValueText = this.layer.getText("value_" + rollResult.diceSum);
        const highlight = this.layer.getShape("highlight_" + rollResult.diceSum);

        this.layer.add(paytableValueText);

        return new Sequence([
            () => new Parallel([
                () => fadeIn(highlight, 125),
                () => pulse(paytableValueText, { x: 1.5, y: 1.5 }, 350),
                () => gameLayers.CelebrationContent.setScene("dicewin"),
                () => Contract.wrap(() => {
                    if (rollResult.is7Out && rollResult.cashWon === 0) {
                        const isGameOver = rollResult.livesRemaining === 0;
                        const isEnglish = Services.get(TranslationsService).playingInEnglish();

                        gameLayers.CelebrationContent.getSprite("gameover").visible = isEnglish && isGameOver;
                        gameLayers.CelebrationContent.getSprite("sevenout").visible = isEnglish && !isGameOver;

                        const dynamicText = gameLayers.CelebrationContent.getText("dynamic");
                        if (!isEnglish) {
                            dynamicText.text = Services.get(TranslationsService).get(
                                rollResult.livesRemaining === 0 ? "game_over" : "seven_out"
                            );
                            applyWhiteBlackTextStroke(dynamicText);
                        }
                    } else {
                        gameLayers.CelebrationContent.getBitmapText("value").text = Services.get(CurrencyService).format(rollResult.cashWon);
                        gameLayers.CelebrationContent.getSprite("gameover").visible = false;
                        gameLayers.CelebrationContent.getSprite("sevenout").visible = false;
                    }
                }),
                () => Contract.getTimeoutContract(timeoutValue)
            ]),
            () => Contract.wrap(() => {
                fadeOut(highlight, 125).execute();
                gameLayers.CelebrationContent.defaultScene().execute();
            })
        ]);
    }

    public setResult(result: HotRollBonusDiceRollResult, multiplierOverride?: number) {
        this.mode = result.paytableMode;
        if (this.mode === HotRollPaytableMode.NORMAL) {
            this.layer.getText("label_7").tint = 0xFFFFFF;
            this.layer.getText("value_7").tint = 0xFFFFFF;
        } else {
            this.layer.getText("label_7").tint = 0xFA3939;
            this.layer.getText("value_7").tint = 0xFA3939;
        }

        this.multiplier = multiplierOverride ?? result.multiplier;

        this.updateValues();
    }

    public setMultiplierWithTickup(multiplier: number, tickupTime: number): Contract {
        if (this.tickupTween) {
            this.tickupTween.stop();
            this.tickupTween = undefined;
        }

        if (multiplier > this.multiplier) {
            Services.get(SoundService).customEvent(HotRollSoundEvents.paytable_tickup);
        }

        return new Contract((resolve) => {
            this.tickupTween = new Tween(this)
                .to({ multiplier }, tickupTime)
                .onUpdate(() => this.updateValues())
                .onComplete(() => {
                    Services.get(SoundService).customEvent(HotRollSoundEvents.paytable_tickup_complete);
                    this.multiplier = multiplier;
                    this.updateValues();
                    resolve();
                })
                .start();
        });

    }

    public updateTotalWin(total: number, tickupTime: number = 500): Contract {
        const valueText = this.layer.getBitmapText("total_bonus_value");
        valueText.visible = total > 0;

        const labeli18n = this.layer.getText("total_bonus_label");
        const labelEn = this.layer.getSprite("total_bonus_label_en");

        labelEn.visible = valueText.visible && Services.get(TranslationsService).playingInEnglish();
        labeli18n.visible = valueText.visible && !Services.get(TranslationsService).playingInEnglish();

        if (tickupTime > 0 && this.totalWin !== total) {
            return new Sequence([
                () => Contract.wrap(() => { Services.get(SoundService).customEvent(HotRollSoundEvents.bonus_running_total_tickup_start) }),
                () => TweenContract.wrapTween(
                    new Tween(this)
                        .to({ totalWin: total }, tickupTime)
                        .onUpdate(() => valueText.text = Services.get(CurrencyService).format(this.totalWin))
                ),
                () => Contract.wrap(() => { Services.get(SoundService).customEvent(HotRollSoundEvents.bonus_running_total_tickup_end) })
            ]);
        } else {
            this.totalWin = total;
            valueText.text = Services.get(CurrencyService).format(this.totalWin);
            return Contract.empty();
        }
    }

    protected updateValues() {
        const currencyService = Services.get(CurrencyService);

        for (let i = 2; i <= 12; i++) {
            const valueText = this.layer.getText("value_" + i);

            if (i === 7 && this.mode === HotRollPaytableMode.SEVENOUT) {
                if (this.lives > 1) {
                    valueText.text = "-";
                } else {
                    valueText.text = Services.get(TranslationsService).get("paytable_end");
                }
            } else {
                valueText.text = currencyService.format(this.calculateDiceWinValue(i));
            }
        }
    }

    protected calculateDiceWinValue(diceValue: number): number {
        return diceValue * this.multiplier * Services.get(SlotBetService).getTotalStake();
    }

    protected hideHearts() {
        this.layer.getSprite("heart_1").alpha = 0;
        this.layer.getSprite("heart_2").alpha = 0;
        this.layer.getSprite("heart_3").alpha = 0;
        this.layer.getSprite("heart_4").alpha = 0;
    }
}
