import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { PIXIElement } from "appworks/graphics/pixi/group";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Point } from "appworks/utils/geom/point";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";

export class HotRollTicketMatrixComponent extends SlingoTicketMatrixComponent {
    protected highlightTweens: Tween[] = [];

    public dabSymbol(symbolValue: number, instant?: boolean): Contract<void> {
        if (instant) {
            return super.dabSymbol(symbolValue, instant);
        }

        return new Sequence([
            () => Contract.wrap(() => {
                this.setNumberColour(symbolValue, "orange");
            }),
            () => super.dabSymbol(symbolValue, instant),
            () => Contract.wrap(() => {
                this.setNumberColour(symbolValue, "blue");
            })
        ]);
    }

    public clearHighlights(): Contract<void> {
        this.highlightTweens.forEach((tween) => tween.stop());
        this.highlightTweens = [];
        return super.clearHighlights();
    }

    public setNumberColour(value: number, colour: "blue" | "orange") {
        const xy = this.getSymbolPositionFromValue(value);

        if (xy) {
            this.numbers[xy.x][xy.y].destroy();

            let sprite: Sprite;
            if (colour === "blue") {
                sprite = Services.get(GraphicsService).createSprite("numbers_blue/" + value);
            } else {
                sprite = Services.get(GraphicsService).createSprite("numbers/" + value);
            }

            const position = this.layer.getPosition(`symbol_${xy.x}_${xy.y}`);
            sprite.setDualPosition(position);
            this.layer.add(sprite, false, true);
            this.numbers[xy.x][xy.y] = sprite;
        }
    }

    protected getNumberSprite(value: number): Sprite {
        return Services.get(GraphicsService).createSprite("numbers_blue/" + value);
    }

    protected getDabSprite(): Sprite {
        const graphicsService = Services.get(GraphicsService);
        return graphicsService.createAnimation("dab");
    }

    protected getDabPosition(pos: Point): DualPosition {
        const symPos = this.layer.getPosition(`symbol_${pos.x}_${pos.y}`);
        const zeroPos = this.layer.getPosition("symbol_0_0");
        const symOffset = symPos.subtract(zeroPos);

        return this.layer.getPosition("dab").addPosition(symOffset);
    }

    protected getDabAnimationContract(dab: PIXIElement, instant: boolean): Contract<void> {
        const dabAnim = dab as AnimatedSprite;

        if (instant) {
            dabAnim.gotoAndStop(dabAnim.totalFrames - 1);
            return Contract.empty();
        }

        return dabAnim.gotoAndPlayOnce();
    }

    protected getHighlightAnimationContract(highlight: PIXIElement, highlightType: SlingoHighlightType): Contract<void> {
        if (highlightType === SlingoHighlightType.MATCHED) {
            return super.getHighlightAnimationContract(highlight, highlightType);
        }

        highlight.alpha = 0.25;
        this.highlightTweens.push(new Tween(highlight)
            .to({ alpha: 1 }, 500)
            .easing(Easing.Sinusoidal.InOut)
            .yoyo(true)
            .repeat(Number.MAX_SAFE_INTEGER)
            .start()
        );

        return Contract.getTimeoutContract(500);
    }
}
