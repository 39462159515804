import { AbstractComponent } from "appworks/components/abstract-component";
import { FlexiText } from "appworks/graphics/elements/flexi-text";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
// import { SmartUIManager } from "appworks/ui/smart-ui-manager";
// import { HeadlessSmartUIGroup } from "appworks/ui/ui-scene";
import * as TweenJS from "appworks/utils/tween";
import { slotModel } from "slotworks/model/slot-model";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { BetBarValueText } from "./bet-bar-value-text";

export class BetBarComponent extends AbstractComponent {

    protected static FREESPINS_TOTAL_VALUE: string = "freespins_total_value";
    protected static FREESPINS_REMAINING_VALUE: string = "freespins_remaining_value";
    protected static FREESPINS_X_OF_Y_VALUE: string = "freespins_x_of_y_value";

    public balanceTickTime: number = 0;
    public balanceUpdateDelay: number = 0;

    protected freespinsTotalText: FlexiText;
    protected freespinsRemainingText: FlexiText;
    protected freespinsXOfyText: FlexiText;

    protected balanceTween: TweenJS.Tween;

    //protected balanceValue: BetBarValueText;
    protected totalWinValue: BetBarValueText;
    protected currentWinValue: BetBarValueText;
    protected netPositionValue: BetBarValueText;

    protected balance: number = 0;
    protected displayBalance: number = 0;
    protected totalWin: number = 0;
    protected netPosition: number = 0;

    protected totalFreeSpins: number = 0;
    protected remainingFreespins: number = 0;
    protected currentWin: number = 0;

    protected layer: Layers;

    public constructor() {
        super();

        this.layer = Layers.get("BetBar");

        this.layer?.defaultScene().execute();

        this.initBetBarValues();
        this.netPositionValue = new BetBarValueText(this.layer, "net_position");

        slotModel.onUpdate.add(() => this.updateTexts());
        this.layer?.onSceneEnter.add(() => this.refresh());

        Services.get(TransactionService).currentWinUpdate.add((value: number) => this.setCurrentWin(value));
        Services.get(TransactionService).totalWinUpdate.add((value: number) => this.setTotalWin(value));
        Services.get(TransactionService).balanceUpdate.add((value: number) => this.setBalance(value));
        Services.get(JurisdictionService).netPositionUpdate.add((value: number) => this.setNetPosition(value));
    }

    // Free spins
    public setTotalFreespins(value: number) {
        this.totalFreeSpins = value;
        this.updateTexts();
    }

    public setFreespinsPlayed(value: number) {
        this.remainingFreespins = this.totalFreeSpins - value;
        this.updateTexts();
    }

    protected initBetBarValues() {
        //this.balanceValue = new BetBarValueText(this.layer, "balance");
        this.totalWinValue = new BetBarValueText(this.layer, "total_win", true);
        this.currentWinValue = new BetBarValueText(this.layer, "current_win", true);
    }

    protected setCurrentWin(value: number) {
        this.currentWin = value;
        this.updateTexts();
    }

    protected setNetPosition(value: number) {
        this.netPosition = value;
        this.updateTexts();
    }

    protected setBalance(value: number) {
        if (this.balanceTween) {
            this.balanceTween.stop();
            TweenJS.remove(this.balanceTween);
            this.displayBalance = this.balance;
        }

        this.balance = value;

        if (this.balanceTickTime > 0 && this.balance > this.displayBalance) {
            this.balanceTween = new TweenJS.Tween(this)
                .to({ displayBalance: value }, this.balanceTickTime)
                .delay(this.balanceUpdateDelay)
                .onUpdate(() => this.updateTexts())
                .start();
        } else {
            this.displayBalance = this.balance;
        }

        this.updateTexts();
    }

    protected setTotalWin(value: number) {
        this.totalWin = value;
        this.updateTexts();
    }

    protected refresh() {
        this.setupTexts();
        Services.get(TransactionService).setWinnings(this.currentWin, this.totalWin).execute();
        this.setBalance(this.balance);
    }

    protected setupTexts() {
        // this.balanceValue.refresh();
        this.totalWinValue.refresh();
        this.currentWinValue.refresh();
        this.netPositionValue.refresh();

        this.layer.getFlexiText("net_position_value")?.setVisible(Services.get(JurisdictionService).getNetPositionEnabled());
        this.layer.getFlexiSprite("ukgc-net_position_icon")?.setVisible(Services.get(JurisdictionService).getNetPositionEnabled());

        this.freespinsTotalText = this.layer.getFlexiText(BetBarComponent.FREESPINS_TOTAL_VALUE);
        this.freespinsRemainingText = this.layer.getFlexiText(BetBarComponent.FREESPINS_REMAINING_VALUE);
        this.freespinsXOfyText = this.layer.getFlexiText(BetBarComponent.FREESPINS_X_OF_Y_VALUE);
        this.layer.getText("balance_label").visible = false; // removing for social
    }

    protected updateTexts() {
        // this.balanceValue.update(this.displayBalance);
        this.totalWinValue.update(this.totalWin);
        this.currentWinValue.update(this.currentWin);
        this.netPositionValue.update(this.netPosition);

        if (this.freespinsTotalText) {
            if (this.totalFreeSpins > 0) {
                this.freespinsTotalText.setText(this.totalFreeSpins.toString());
            } else {
                this.freespinsTotalText.setText("");
            }
        }
        if (this.freespinsRemainingText) {
            this.freespinsRemainingText.setText(this.remainingFreespins.toString());
        }
        if (this.freespinsXOfyText) {
            if (this.totalFreeSpins > 0) {
                const tokens = {
                    current_spin: this.totalFreeSpins - this.remainingFreespins,
                    total_spins: this.totalFreeSpins
                };

                if (Services.get(TranslationsService).exists("bar.xoy")) {
                    this.freespinsXOfyText.setText(Services.get(TranslationsService).get("bar.xoy", tokens));
                } else {
                    this.freespinsXOfyText.setText(`${tokens.current_spin}/${tokens.total_spins}`);
                }
            } else {
                this.freespinsXOfyText.setText("");
            }
        }
    }
}

export class BetBarType {

    public static BASE: BetBarType = new BetBarType("base");
    public static FREESPINS: BetBarType = new BetBarType("freespins");

    constructor(private type: string) {
    }

    public toString(): string {
        return this.type;
    }
}
