import { Components } from "appworks/components/components";
import { AssetConfigSchema } from "appworks/config/asset-config-schema";
import { gameLoop } from "appworks/core/game-loop";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { gameState } from "appworks/model/game-state";
import { AuthRequestPayload } from "appworks/model/gameplay/requests/auth-request-payload";
import { Model } from "appworks/model/model";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { stateMachine } from "appworks/state-machine/social/social-state-machine";
import { Sequence } from "appworks/utils/contracts/sequence";
import "pixi-filters";
import { AlertComponent } from "slotworks/components/alert/alert-component";
import { Setup } from "./boot/setup";
import { ci } from "./debug/ci";
import { CanvasService } from "./graphics/canvas/canvas-service";
import { Layers } from "./graphics/layers/layers";
import { HTMLText } from "./graphics/pixi/html-text";
import { LoaderService } from "./loader/loader-service";
import { SoundService } from "./services/sound/sound-service";
import { TranslationsService } from "./services/translations/translations-service";
import { deviceInfo } from "./utils/device-info";

declare const __DEBUG__: boolean;
declare const __ENVIRONMENT__: string;

export class AppWorks {
    public static go(
        setups: Setup | Setup[],
        assetworksData: any
    ) {

        const setupsArr = (Array.isArray(setups)) ? setups : [setups];

        setupsArr.forEach((setup) => {
            setup.setupGameDefinition();
            setup.setupCanvas();
            setup.setupComponents();
            setup.setupComms();
            setup.setupSound();
            setup.setupStates();

            const particleConfigs = setup.setupParticles();
            Services.get(ParticleService).initConfigs(particleConfigs);
        });

/////////////////////////////////////////////////////////
//////////////////
//////////////////

        window.onerror = (e) => {
            let message = Services.get(TranslationsService).get("General Error");

            if (typeof e === "string" && e.includes("debug-evaluate")) {
                return;
            }

/////////////////////////////
////////////////////////////
///////////////////////////////////////
/////////////
//////////////////////

            if (Components.get(AlertComponent).isInitialized()) {
                Components.get(AlertComponent).error(message, true).execute();
            } else {
                this.simpleAlert(message);
            }
            window.onerror = null;
        };

        commsManager.connector.onError((message, fatal) => {
            if (Components.get(AlertComponent).isInitialized()) {
                Components.get(AlertComponent).error(message, fatal).execute();
            } else {
                this.simpleAlert(message);
            }
        });
        Services.get(LoaderService).onError((message) => {
            if (Components.get(AlertComponent).isInitialized()) {
                Components.get(AlertComponent).error(message, true).execute()
            } else {
                this.simpleAlert(message);
            }
        });

        Services.get(LoaderService).loadConfig().then((assetConfig: AssetConfigSchema) => {

            Layers.gameLayers = assetworksData.gameLayers;

            Services.get(CanvasService).setup(assetConfig);
            Services.get(GraphicsService).setup(assetConfig);
            Services.get(ParticleService).setup(assetConfig);

            const layoutConfig = assetConfig.layouts[deviceInfo.layoutId].graphics;
            Layers.init(layoutConfig, assetworksData.LayerNames);

            setupsArr.forEach((setup) => {
                setup.setupLayers();
                setup.setupTransitions();
            });

            new Sequence([
                () => gameLoop.init(),
                ...setupsArr.map((setup) => () => setup.setupAsync())
            ]).then(() => {

                {
                    // TODO V6: Delete this block
                    const legacyLangCode = Model.read().settings.language;
                    Services.get(LoaderService).fallbackI18nFileNames.unshift(Model.read().settings.language);

                    if (legacyLangCode.indexOf("-") > -1) {
                        Services.get(LoaderService).fallbackI18nFileNames.unshift(legacyLangCode.split("-")[0]);
                    }
                }

                Services.get(LoaderService).loadI18n(assetConfig).then((json) => {

//////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
/////////////////////
//////////////////////////////
                    Services.get(TranslationsService).load(json);
                    Services.get(TranslationsService).updateDOMTexts();

                    setupsArr.forEach((setup) => {
                        setup.setupI18N();
                    });

                    gameState.newGame();


                    new Sequence([
                        () => Services.get(LoaderService).setup(assetConfig),
                        () => commsManager.request(new AuthRequestPayload()),
                        () => Services.get(LoaderService).load()
                    ]).then(() => {

                        HTMLText.addStaticImages();

                        setupsArr.forEach((setup) => {
                            setup.setupUI();
                        });

                        Services.get(SpineService).setup(assetConfig);

                        Services.get(CanvasService).gameLoaded();

//////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////
/////////////////////////
//////////////////////////////////

                        setupsArr.forEach((setup) => {
                            setup.onReady();
                        });

                        stateMachine.start();
                    });
                });
            });
        });
    }

    protected static simpleAlert(message: string) {
        message = Services.get(TranslationsService)?.exists(message) ? Services.get(TranslationsService).get(message) : "Fatal Error";

        const alertEl = document.createElement("div");
        alertEl.id = "simple-alert";
        alertEl.classList.add("simple-alert");

        const alertElWrapper = document.createElement("div");
        alertElWrapper.classList.add("simple-alert__wrapper");
        alertEl.appendChild(alertElWrapper);

        const titleEl = document.createElement("div");
        titleEl.classList.add("simple-alert__title");
        titleEl.innerText = "Error";
        alertElWrapper.appendChild(titleEl);

        const messageEl = document.createElement("div");
        messageEl.classList.add("simple-alert__message");
        messageEl.innerText = Services.get(TranslationsService).get(message);

        alertElWrapper.appendChild(messageEl);

        const btnEl = document.createElement("a");
        btnEl.classList.add("simple-alert__button");
        btnEl.innerText = Services.get(TranslationsService).get("OK");
        btnEl.href = document.location.href;
        alertElWrapper.appendChild(btnEl);

        document.body.appendChild(alertEl);
    }

    private constructor() { }
}
