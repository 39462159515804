import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ButtonElement } from "appworks/graphics/elements/button-element";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { Scene } from "appworks/graphics/layers/scene";
import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { SoundNames } from "sounds-enum";

class GameLayers {
    public BonusChoice: Layers & {
        scene: {
             level_12: Scene & {
                contents: {
                    Low?: Sprite
                    High?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    pickabonus?: Text | Sprite
                    higher_risk?: Text
                    lower_risk?: Text
                    choice_2_description?: Text
                    choice_1_description?: Text
                    golden_roll?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        multiplier_1?: DualPosition
                        multiplier_2?: DualPosition
                        multiplier_3?: DualPosition
                        multiplier_4?: DualPosition
                    };
                    Low?: Sprite
                    High?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    pickabonus?: Text | Sprite
                    higher_risk?: Text
                    lower_risk?: Text
                    choice_2_description?: Text
                    choice_1_description?: Text
                    golden_roll?: Text
                    icon?: Sprite
                    easyroll?: Text
                    pays2x?: Sprite
                    pays3x?: Sprite
                    pays4x?: Sprite
                    pays5x?: Sprite
                    pick3?: ButtonElement
                    pick4?: ButtonElement
                    highlight_3?: Sprite
                    highlight_4?: Sprite
                    pickabutton?: Text | Sprite
                    rolltext_1?: Text
                    rolltext_2?: Text
                    rolltext_3?: Text
                    rolltext_4?: Text
                };
            };
             level_10: Scene & {
                contents: {
                    Low?: Sprite
                    High?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    pickabonus?: Text | Sprite
                    higher_risk?: Text
                    lower_risk?: Text
                    choice_2_description?: Text
                    choice_1_description?: Text
                    golden_roll?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             level_9: Scene & {
                contents: {
                    icon?: Sprite
                    choice_1_description?: Text
                    golden_roll?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             level_8: Scene & {
                contents: {
                    Low?: Sprite
                    High?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    pickabonus?: Text | Sprite
                    higher_risk?: Text
                    lower_risk?: Text
                    choice_2_description?: Text
                    choice_1_description?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             level_7: Scene & {
                contents: {
                    Low?: Sprite
                    High?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    pickabonus?: Text | Sprite
                    higher_risk?: Text
                    lower_risk?: Text
                    choice_2_description?: Text
                    choice_1_description?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             level_6: Scene & {
                contents: {
                    Low?: Sprite
                    High?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    pickabonus?: Text | Sprite
                    higher_risk?: Text
                    lower_risk?: Text
                    choice_2_description?: Text
                    choice_1_description?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             level_5: Scene & {
                contents: {
                    icon?: Sprite
                    easyroll?: Text
                    choice_1_description?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             dice_choice: Scene & {
                contents: {
                    pays2x?: Sprite
                    pays3x?: Sprite
                    pays4x?: Sprite
                    pays5x?: Sprite
                    pick1?: ButtonElement
                    pick2?: ButtonElement
                    pick3?: ButtonElement
                    pick4?: ButtonElement
                    highlight_1?: Sprite
                    highlight_2?: Sprite
                    highlight_3?: Sprite
                    highlight_4?: Sprite
                    pickabutton?: Text | Sprite
                    rolltext_1?: Text
                    rolltext_2?: Text
                    rolltext_3?: Text
                    rolltext_4?: Text
                    positions: {
                        [key: string]: DualPosition
                        multiplier_1?: DualPosition
                        multiplier_2?: DualPosition
                        multiplier_3?: DualPosition
                        multiplier_4?: DualPosition
                    };
                };
            };
        };
    };
    public CelebrationForeground: Layers & {
        scene: {
             logo: Scene & {
                contents: {
                    logo_slingo?: Sprite
                    logo_roll?: Sprite
                    Logo_Hot?: AnimatedSprite
                    logo_dice?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    logo_slingo?: Sprite
                    logo_roll?: Sprite
                    Logo_Hot?: AnimatedSprite
                    logo_dice?: Sprite
                };
            };
        };
    };
    public CelebrationContent: Layers & {
        scene: {
             dicewin: Scene & {
                contents: {
                    sevenout?: Sprite
                    gameover?: Sprite
                    dynamic?: Text
                    value?: BitmapText
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    sevenout?: Sprite
                    gameover?: Sprite
                    dynamic?: Text
                    value?: BitmapText
                    fullhouse?: Text | Sprite
                    creditwinner?: Text | Sprite
                    bigwin?: Text | Sprite
                    slingo_spine?: SpineContainer
                };
            };
             fullhouse: Scene & {
                contents: {
                    fullhouse?: Text | Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             creditwinner: Scene & {
                contents: {
                    creditwinner?: Text | Sprite
                    value?: BitmapText
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             bigwin: Scene & {
                contents: {
                    bigwin?: Text | Sprite
                    value?: BitmapText
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             slingo: Scene & {
                contents: {
                    slingo_spine?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CelebrationBackground: Layers & {
        scene: {
             fullhouse: Scene & {
                contents: {
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cover?: SmartShape
                    bg?: Sprite
                };
            };
             creditwinner: Scene & {
                contents: {
                    bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             bigwin: Scene & {
                contents: {
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MultiplierSequence: Layers & {
        scene: {
             x2: Scene & {
                contents: {
                    img?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    img?: Sprite
                };
            };
             x3: Scene & {
                contents: {
                    img?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             x4: Scene & {
                contents: {
                    img?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             x5: Scene & {
                contents: {
                    img?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public DiceRollCounter: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    roll?: Sprite
                    positions: {
                        [key: string]: DualPosition
                        0?: DualPosition
                        1?: DualPosition
                        2?: DualPosition
                        suffix?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        0?: DualPosition
                        1?: DualPosition
                        2?: DualPosition
                        suffix?: DualPosition
                    };
                    roll?: Sprite
                };
            };
        };
    };
    public ScreenFade: Layers & {
        scene: {
             flame_transition: Scene & {
                contents: {
                    fire_transition?: AnimatedSprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    fire_transition?: AnimatedSprite
                    fade?: SmartShape
                };
            };
             black: Scene & {
                contents: {
                    fade?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MenuButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    ui_settings_menu?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ui_settings_menu?: ButtonElement
                };
            };
        };
    };
    public Footer: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    value?: Text
                };
            };
        };
    };
    public BetBar: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    top_bar?: SmartShape
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    top_bar?: SmartShape
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    ui_stake_menu?: ButtonElement
                    bottom_bar?: Sprite
                };
            };
             base: Scene & {
                contents: {
                    ui_stake_menu?: ButtonElement
                    top_bar?: SmartShape
                    bottom_bar?: Sprite
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public DicePaytable: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    pays2x?: Sprite
                    pays3x?: Sprite
                    pays4x?: Sprite
                    pays5x?: Sprite
                    heart_1?: Sprite
                    heart_2?: Sprite
                    heart_3?: Sprite
                    heart_4?: Sprite
                    dice_fire?: Sprite
                    total_bonus_label_en?: Sprite
                    highlight_12?: SmartShape
                    highlight_11?: SmartShape
                    highlight_10?: SmartShape
                    highlight_9?: SmartShape
                    highlight_8?: SmartShape
                    highlight_7?: SmartShape
                    highlight_6?: SmartShape
                    highlight_5?: SmartShape
                    highlight_4?: SmartShape
                    highlight_3?: SmartShape
                    highlight_2?: SmartShape
                    bg_portrait?: Sprite
                    bg?: Sprite
                    total_bonus_value?: BitmapText
                    total_bonus_label?: Text
                    value_12?: Text
                    value_11?: Text
                    value_10?: Text
                    value_9?: Text
                    value_8?: Text
                    value_7?: Text
                    value_6?: Text
                    value_5?: Text
                    value_4?: Text
                    value_3?: Text
                    value_2?: Text
                    label_win?: Text
                    label_12?: Text
                    label_11?: Text
                    label_10?: Text
                    label_9?: Text
                    label_8?: Text
                    label_7?: Text
                    label_6?: Text
                    label_5?: Text
                    label_4?: Text
                    label_3?: Text
                    label_2?: Text
                    label_roll?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    pays2x?: Sprite
                    pays3x?: Sprite
                    pays4x?: Sprite
                    pays5x?: Sprite
                    heart_1?: Sprite
                    heart_2?: Sprite
                    heart_3?: Sprite
                    heart_4?: Sprite
                    dice_fire?: Sprite
                    total_bonus_label_en?: Sprite
                    highlight_12?: SmartShape
                    highlight_11?: SmartShape
                    highlight_10?: SmartShape
                    highlight_9?: SmartShape
                    highlight_8?: SmartShape
                    highlight_7?: SmartShape
                    highlight_6?: SmartShape
                    highlight_5?: SmartShape
                    highlight_4?: SmartShape
                    highlight_3?: SmartShape
                    highlight_2?: SmartShape
                    bg_portrait?: Sprite
                    bg?: Sprite
                    total_bonus_value?: BitmapText
                    total_bonus_label?: Text
                    value_12?: Text
                    value_11?: Text
                    value_10?: Text
                    value_9?: Text
                    value_8?: Text
                    value_7?: Text
                    value_6?: Text
                    value_5?: Text
                    value_4?: Text
                    value_3?: Text
                    value_2?: Text
                    label_win?: Text
                    label_12?: Text
                    label_11?: Text
                    label_10?: Text
                    label_9?: Text
                    label_8?: Text
                    label_7?: Text
                    label_6?: Text
                    label_5?: Text
                    label_4?: Text
                    label_3?: Text
                    label_2?: Text
                    label_roll?: Text
                };
            };
        };
    };
    public Collect: Layers & {
        scene: {
             collect: Scene & {
                contents: {
                    purchase?: ButtonElement
                    pot_level12?: Sprite
                    pot_level10?: Sprite
                    pot_level9?: Sprite
                    pot_dice?: Sprite
                    col_level12?: Sprite
                    col_level10?: Sprite
                    col_level9?: Sprite
                    col_dice?: Sprite
                    collect?: Text
                    potential_win_frame?: Sprite
                    potential_win_body?: Text
                    pot_prize?: Text
                    col_prize?: Text
                    end_game?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    purchase?: ButtonElement
                    pot_level12?: Sprite
                    pot_level10?: Sprite
                    pot_level9?: Sprite
                    pot_dice?: Sprite
                    col_level12?: Sprite
                    col_level10?: Sprite
                    col_level9?: Sprite
                    col_dice?: Sprite
                    collect?: Text
                    potential_win_frame?: Sprite
                    potential_win_body?: Text
                    pot_prize?: Text
                    col_prize?: Text
                    end_game?: Text
                };
            };
        };
    };
    public SpinCounter: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                        sparkStart_0?: DualPosition
                        sparkStart_1?: DualPosition
                        sparkStart_2?: DualPosition
                        sparkStart_3?: DualPosition
                        sparkStart_4?: DualPosition
                        sparkMidPoint?: DualPosition
                        sparkTarget?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        sparkStart_0?: DualPosition
                        sparkStart_1?: DualPosition
                        sparkStart_2?: DualPosition
                        sparkStart_3?: DualPosition
                        sparkStart_4?: DualPosition
                        sparkMidPoint?: DualPosition
                        sparkTarget?: DualPosition
                    };
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                };
            };
        };
    };
    public DiceTransform: Layers & {
        scene: {
             transform: Scene & {
                contents: {
                    dice_transform?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    dice_transform?: SpineContainer
                };
            };
        };
    };
    public Dice: Layers & {
        scene: {
             bonusintrotext: Scene & {
                contents: {
                    introtext?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        dice_start_0?: DualPosition
                        dice_start_1?: DualPosition
                        dice_end_0?: DualPosition
                        dice_end_1?: DualPosition
                        dice_transform_0?: DualPosition
                        dice_transform_1?: DualPosition
                    };
                    introtext?: Text
                    arrow_text?: Text | Sprite
                    arrow_gold?: Sprite
                    arrow?: ButtonElement
                    double_pays_en?: Sprite
                    outline_gold?: Sprite
                    outline_red?: Sprite
                    autoroll_stop?: ButtonElement
                    autoroll_start?: ButtonElement
                    drag_follow_target?: Sprite
                    roll_100_limit?: Text
                    double_pays?: Text
                };
            };
             bonus: Scene & {
                contents: {
                    arrow_text?: Text | Sprite
                    arrow_gold?: Sprite
                    arrow?: ButtonElement
                    double_pays_en?: Sprite
                    outline_gold?: Sprite
                    outline_red?: Sprite
                    autoroll_stop?: ButtonElement
                    autoroll_start?: ButtonElement
                    drag_follow_target?: Sprite
                    roll_100_limit?: Text
                    double_pays?: Text
                    positions: {
                        [key: string]: DualPosition
                        dice_start_0?: DualPosition
                        dice_start_1?: DualPosition
                        dice_end_0?: DualPosition
                        dice_end_1?: DualPosition
                        dice_transform_0?: DualPosition
                        dice_transform_1?: DualPosition
                    };
                };
            };
        };
    };
    public Logo: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    Logo_Hot?: AnimatedSprite
                    logo_roll?: Sprite
                    logo_ksg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    Logo_Hot?: AnimatedSprite
                    logo_roll?: Sprite
                    logo_ksg?: Sprite
                    logo_slingo?: Sprite
                    logo_dice?: Sprite
                };
            };
             default: Scene & {
                contents: {
                    logo_slingo?: Sprite
                    logo_roll?: Sprite
                    Logo_Hot?: AnimatedSprite
                    logo_dice?: Sprite
                    logo_ksg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             bonuschoice: Scene & {
                contents: {
                    logo_slingo?: Sprite
                    logo_roll?: Sprite
                    Logo_Hot?: AnimatedSprite
                    logo_dice?: Sprite
                    logo_ksg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             onboard: Scene & {
                contents: {
                    logo_slingo?: Sprite
                    logo_roll?: Sprite
                    Logo_Hot?: AnimatedSprite
                    logo_dice?: Sprite
                    logo_ksg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public SpinButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    spin?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    spin?: ButtonElement
                };
            };
        };
    };
    public TicketMatrix: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        dab?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        dab?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
        };
    };
    public SlingoLadder: Layers & {
        scene: {
             paytable: Scene & {
                contents: {
                    level12?: Sprite
                    level10?: Sprite
                    level9?: Sprite
                    level8?: Sprite
                    level7?: Sprite
                    level6?: Sprite
                    level5?: Sprite
                    level4?: Sprite
                    level3?: Sprite
                    level2?: Sprite
                    level1?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    ladder_bg?: Sprite
                    cover?: SmartShape
                    txt8?: Text
                    txt7?: Text
                    txt6?: Text
                    txt5?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    level12?: Sprite
                    level10?: Sprite
                    level9?: Sprite
                    level8?: Sprite
                    level7?: Sprite
                    level6?: Sprite
                    level5?: Sprite
                    level4?: Sprite
                    level3?: Sprite
                    level2?: Sprite
                    level1?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    ladder_bg?: Sprite
                    cover?: SmartShape
                    txt8?: Text
                    txt7?: Text
                    txt6?: Text
                    txt5?: Text
                    p_level12?: Sprite
                    p_level10?: Sprite
                    p_level9?: Sprite
                    p_level8?: Sprite
                    p_level7?: Sprite
                    p_level6?: Sprite
                    p_level5?: Sprite
                    p_level4?: Sprite
                    p_level3?: Sprite
                    p_level2?: Sprite
                    p_level1?: Sprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_ladder?: Sprite
                    ui_ladder?: ButtonElement
                    ui_paytable?: ButtonElement
                };
            };
             base: Scene & {
                contents: {
                    p_level12?: Sprite
                    p_level10?: Sprite
                    p_level9?: Sprite
                    p_level8?: Sprite
                    p_level7?: Sprite
                    p_level6?: Sprite
                    p_level5?: Sprite
                    p_level4?: Sprite
                    p_level3?: Sprite
                    p_level2?: Sprite
                    p_level1?: Sprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_ladder?: Sprite
                    ui_ladder?: ButtonElement
                    ui_paytable?: ButtonElement
                    level12?: Sprite
                    level10?: Sprite
                    level9?: Sprite
                    level8?: Sprite
                    level7?: Sprite
                    level6?: Sprite
                    level5?: Sprite
                    level4?: Sprite
                    level3?: Sprite
                    level2?: Sprite
                    level1?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    ladder_bg?: Sprite
                    txt8?: Text
                    txt7?: Text
                    txt6?: Text
                    txt5?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public TicketMatrixBacking: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    grid?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    grid?: Sprite
                };
            };
        };
    };
    public MatrixContent: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        bounds?: DualPosition
                        land?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        bounds?: DualPosition
                        land?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
        };
    };
    public SymbolAnimations: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MatrixBackground: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    reels?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    reels?: Sprite
                };
            };
        };
    };
    public BackgroundFire: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    BorderFire_Level4_Merged?: AnimatedSprite
                    positions: {
                        [key: string]: DualPosition
                        level0?: DualPosition
                        level1?: DualPosition
                        level2?: DualPosition
                        level3?: DualPosition
                        level4?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        level0?: DualPosition
                        level1?: DualPosition
                        level2?: DualPosition
                        level3?: DualPosition
                        level4?: DualPosition
                    };
                    BorderFire_Level4_Merged?: AnimatedSprite
                };
            };
        };
    };
    public BackgroundMultipliers: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    Layer_930?: Sprite
                    Layer_929?: Sprite
                    Layer_928?: Sprite
                    Layer_927?: Sprite
                    Layer_926?: Sprite
                    Layer_925?: Sprite
                    Layer_924?: Sprite
                    Layer_923?: Sprite
                    Layer_922?: Sprite
                    Layer_921?: Sprite
                    Layer_920?: Sprite
                    Layer_919?: Sprite
                    Layer_918?: Sprite
                    Layer_917?: Sprite
                    Layer_916?: Sprite
                    Layer_915?: Sprite
                    Layer_914?: Sprite
                    Layer_913?: Sprite
                    Layer_912?: Sprite
                    Layer_911?: Sprite
                    Layer_931?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    Layer_930?: Sprite
                    Layer_929?: Sprite
                    Layer_928?: Sprite
                    Layer_927?: Sprite
                    Layer_926?: Sprite
                    Layer_925?: Sprite
                    Layer_924?: Sprite
                    Layer_923?: Sprite
                    Layer_922?: Sprite
                    Layer_921?: Sprite
                    Layer_920?: Sprite
                    Layer_919?: Sprite
                    Layer_918?: Sprite
                    Layer_917?: Sprite
                    Layer_916?: Sprite
                    Layer_915?: Sprite
                    Layer_914?: Sprite
                    Layer_913?: Sprite
                    Layer_912?: Sprite
                    Layer_911?: Sprite
                    Layer_931?: Sprite
                };
            };
        };
    };
    public GoldBorder: Layers & {
        scene: {
             gold: Scene & {
                contents: {
                    gold_top?: Sprite
                    gold_left?: Sprite
                    gold_right?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    gold_top?: Sprite
                    gold_left?: Sprite
                    gold_right?: Sprite
                };
            };
        };
    };
    public Background: Layers & {
        scene: {
             preloader: Scene & {
                contents: {
                    bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    bg?: Sprite
                };
            };
             onboard: Scene & {
                contents: {
                    bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             default: Scene & {
                contents: {
                    bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Menus: Layers & {
        scene: {
             settings: Scene & {
                contents: {
                    cover?: SmartShape
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cover?: SmartShape
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    arrow_forward?: ButtonElement
                    arrow_back?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    close?: ButtonElement
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    save?: ButtonElement
                    cancel?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                    preloader?: SpineContainer
                };
            };
             stake: Scene & {
                contents: {
                    cover?: SmartShape
                    arrow_forward?: ButtonElement
                    arrow_back?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             rules: Scene & {
                contents: {
                    close?: ButtonElement
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             play_controls: Scene & {
                contents: {
                    cover?: SmartShape
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    save?: ButtonElement
                    cancel?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             preloader: Scene & {
                contents: {
                    preloader?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Onboard: Layers & {
        scene: {
             onboard: Scene & {
                contents: {
                    golddice?: Sprite
                    reddice?: Sprite
                    ladder?: Sprite
                    middleframe?: Sprite
                    continue?: ButtonElement
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    legal1?: Text
                    text3?: Text
                    text2?: Text
                    text1?: Text
                    version?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    golddice?: Sprite
                    reddice?: Sprite
                    ladder?: Sprite
                    middleframe?: Sprite
                    continue?: ButtonElement
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    legal1?: Text
                    text3?: Text
                    text2?: Text
                    text1?: Text
                    version?: Text
                };
            };
        };
    };
    public Prompts: Layers & {
        scene: {
             bonusmoney: Scene & {
                contents: {
                    cover?: SmartShape
                    tc?: ButtonElement
                    ok?: ButtonElement
                    logo?: Sprite
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cover?: SmartShape
                    tc?: ButtonElement
                    ok?: ButtonElement
                    logo?: Sprite
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    bigwin_en?: Sprite
                    win_en?: Sprite
                    results_en?: Sprite
                    fullhouse_en?: Sprite
                    totalwin?: Text | Sprite
                    win_value?: BitmapText
                    yes?: ButtonElement
                    no?: ButtonElement
                    continuenooptions?: ButtonElement
                    continue?: ButtonElement
                    history?: ButtonElement
                    logout?: ButtonElement
                };
            };
             gamesummary_nowin: Scene & {
                contents: {
                    cover?: SmartShape
                    title?: Text | Sprite
                    logo?: Sprite
                    frame?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             gamesummary_win: Scene & {
                contents: {
                    cover?: SmartShape
                    bigwin_en?: Sprite
                    win_en?: Sprite
                    results_en?: Sprite
                    fullhouse_en?: Sprite
                    totalwin?: Text | Sprite
                    logo?: Sprite
                    frame?: Sprite
                    title?: Text
                    win_value?: BitmapText
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             yes_no: Scene & {
                contents: {
                    cover?: SmartShape
                    yes?: ButtonElement
                    no?: ButtonElement
                    logo?: Sprite
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             realitycheck: Scene & {
                contents: {
                    cover?: SmartShape
                    continuenooptions?: ButtonElement
                    continue?: ButtonElement
                    history?: ButtonElement
                    logout?: ButtonElement
                    frame?: Sprite
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             info: Scene & {
                contents: {
                    cover?: SmartShape
                    continue?: ButtonElement
                    logo?: Sprite
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
}

export enum LayerNames {
    BonusChoice = "bonuschoice",
    CelebrationForeground = "celebrationforeground",
    CelebrationContent = "celebrationcontent",
    CelebrationBackground = "celebrationbackground",
    MultiplierSequence = "multipliersequence",
    DiceRollCounter = "dicerollcounter",
    ScreenFade = "screenfade",
    MenuButton = "menubutton",
    Footer = "footer",
    BetBar = "betbar",
    DicePaytable = "dicepaytable",
    Collect = "collect",
    SpinCounter = "spincounter",
    DiceTransform = "dicetransform",
    Dice = "dice",
    Logo = "logo",
    SpinButton = "spinbutton",
    TicketMatrix = "ticketmatrix",
    SlingoLadder = "slingoladder",
    TicketMatrixBacking = "ticketmatrixbacking",
    MatrixContent = "matrixcontent",
    SymbolAnimations = "symbolanimations",
    MatrixBackground = "matrixbackground",
    BackgroundFire = "backgroundfire",
    BackgroundMultipliers = "backgroundmultipliers",
    GoldBorder = "goldborder",
    Background = "background",
    Menus = "menus",
    Onboard = "onboard",
    Prompts = "prompts"
}

export const gameLayers = new GameLayers();
export const assetworksData = {gameLayers, LayerNames, SoundNames};
