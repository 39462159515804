import { AbstractComponent } from "appworks/components/abstract-component";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { Layers } from "appworks/graphics/layers/layers";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Tween } from "appworks/utils/tween";
import { Easing } from "appworks/utils/tween";
import { gameLayers } from "game-layers";

export class HotRollBackgroundFireComponent extends AbstractComponent {
    protected cancelGroup = new CancelGroup();

    protected layer: Layers = gameLayers.BackgroundFire;
    protected animation: AnimatedSprite;

    public init(): void {
        this.animation = this.layer.getAnimatedSprite("BorderFire_Level4_Merged");
        this.setFlameLevel(0, 0).execute();
    }

    public setLevelFromRollCount(rollCount: number) {
        if (rollCount <= 5) {
            this.setFlameLevel(1).execute();
        } else if (rollCount <= 12) {
            this.setFlameLevel(2).execute();
        } else if (rollCount <= 19) {
            this.setFlameLevel(3).execute();
        } else {
            this.setFlameLevel(4).execute();
        }
    }

    public setFlameLevel(lvl: number, duration: number = 500): Contract {
        this.cancelGroup.skip();

        const position = this.layer.getPosition("level" + lvl);

        const landscapeTween = new Tween(this.animation.landscape)
            .to({ y: position.landscape.y }, duration)
            .easing(Easing.Sinusoidal.Out);

        const portraitTween = new Tween(this.animation.portrait)
            .to({ y: position.portrait.y }, duration)
            .easing(Easing.Sinusoidal.Out);

        const alphaTween = new Tween(this.animation)
            .to({ alpha: (lvl > 0) ? 1 : 0 }, duration)
            .easing(Easing.Sinusoidal.Out);

        return this.cancelGroup.parallel([
            () => this.cancelGroup.tweenContract(landscapeTween),
            () => this.cancelGroup.tweenContract(portraitTween),
            () => this.cancelGroup.tweenContract(alphaTween)
        ]);
    }
}
