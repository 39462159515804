import { Contract } from "appworks/utils/contracts/contract";
import { BlastworksClientEvent } from "../../ClientController";
import { SocialStateMachine } from "../social-state-machine";
import { HotRollPurchaseSpinOrCollectState } from "state-machine/states/hotroll-purchase-spin-or-collect-state";
import { slingoModel } from "slingo/model/slingo-model";
import { gameState } from "appworks/model/game-state";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

export class SocialPurchaseSpinOrCollectState extends HotRollPurchaseSpinOrCollectState {
    public onEnter(): void {
        (this.stateMachine as SocialStateMachine).dispatchClientEvent(BlastworksClientEvent.PURCHASE_SPIN);
        super.onEnter();
    }

    protected checkResponsibleGamingLimits(): string {
        const model = slingoModel.read().responsibleGaming;
        const gameProgress = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);

        // Extra Spins
        if (model.extraSpins.currentValue !== undefined) {
            if (gameProgress.purchaseSpinsUsed >= model.extraSpins.currentValue) {
                return "ExtraSpins";
            }
        }

        return null // in social there are no other limits
    }

    protected showSpinPriceWarning(): Contract<boolean> {
        return new Contract<boolean>((resolve) => { resolve(true); }); // we don't show this on social so we skip the logic
    }
}
