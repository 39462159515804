
import { Contract } from "appworks/utils/contracts/contract";
import { AnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/animate-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class LandAnimateSymbolBehaviour extends AnimateSymbolBehaviour {

    public behaviourName: string = "LandAnimate";

    /**
     * Same as AnimateBehaviour except for landing
     *
     * @param symbol {SymbolSubcomponent}
     * @param [defaultAnimation="win"] {string}
     */
    constructor(symbol: SymbolSubcomponent, defaultAnimation: string = "win") {
        super(symbol, defaultAnimation);
    }

    public anticipateLand(): Contract<void> {
        return this.animate("_land", ["land"], true);
    }

    public highlight(): Contract<void> {
        return Contract.empty();
    }

    public win(): Contract<void> {
        return Contract.empty();
    }

    public cycle(): Contract<void> {
        return Contract.empty();
    }
}
