import { Components } from "appworks/components/components";
import { AnimationService } from "appworks/graphics/animation/animation-service";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Layers } from "appworks/graphics/layers/layers";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { LoaderService } from "appworks/loader/loader-service";
import { gameState } from "appworks/model/game-state";
import { Model } from "appworks/model/model";
import { SentryService } from "appworks/server/analytics/sentry-service";
import { builderManager } from "appworks/server/builders/builder-manager";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SettingsService } from "appworks/services/settings/settings-service";
import { SoundService } from "appworks/services/sound/sound-service";
import { LocalStorageService } from "appworks/services/storage/local-storage-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { StandardI18nAliases } from "appworks/services/translations/standard-i18n-aliases";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { stateMachine } from "appworks/state-machine/social/social-state-machine";
import { uiFlags } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { deviceInfo } from "appworks/utils/device-info";
import { logger } from "appworks/utils/logger";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { ReelSpinner } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { DebugMenu } from "slotworks/debug/menu/debug-menu";
import { slotDefinition } from "slotworks/model/slot-definition";
import { slotModel } from "slotworks/model/slot-model";
import { AutoplayService } from "slotworks/services/autoplay/autoplay-service";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { EmptySetup } from "./empty-setup";

declare var window: any;

//////////////////////////////////////////////////////////////////////////////
/////////////////////
//////////

export abstract class BaseSetup extends EmptySetup {

    public setupDebug(): void {
        DebugMenu.init();

        window.Services = Services;
        window.Layers = Layers;
        window.Components = Components;
        window.Timer = Timer;
        window.UI = UI;
        window.ReelSpinner = ReelSpinner;
        window.Easing = Easing;
        window.stateMachine = stateMachine;
        window.gameState = gameState;
        window.uiFlags = uiFlags;
        window.builderManager = builderManager;
        window.canvas = Services.get(CanvasService);
        window.deviceInfo = deviceInfo;
        window.graphics = Services.get(GraphicsService);
        window.spine = Services.get(SpineService);
        window.gameLayers = gameLayers;
        window.slotModel = slotModel;
        window.animation = Services.get(AnimationService);
        window.deviceInfo = deviceInfo;
        window.slotDefinition = slotDefinition;
        window.particle = Services.get(ParticleService);
        window.DebugMenu = DebugMenu;
        window.commsManager = commsManager;
        window.Model = Model;
    }

    public setupCanvas(): void {
        deviceInfo.selectLayout({
            phone: "mobile",
            tablet: "mobile",
            desktop: "desktop"
        });

        Services.get(CanvasService).fullScreenEnabled = false;
        Services.get(CanvasService).setPortraitCanvasEnabled(true);
    }

    public setupI18N(): void {
        StandardI18nAliases();
    }

    public setupServices(): void {
        Services.register(new SlotBetService());
        Services.register(new LocalStorageService());
        Services.register(new SoundService());
        Services.register(new CurrencyService());
        Services.register(new TranslationsService());
        Services.register(new TransactionService());
        Services.register(new AutoplayService());
        Services.register(new SettingsService());
        Services.register(new JurisdictionService());
        Services.register(new CanvasService());
        Services.register(new GraphicsService());
        Services.register(new AnimationService());
        Services.register(new ParticleService());
        Services.register(new SpineService());
        Services.register(new LoaderService());
        Services.register(new SentryService());
    }
}
