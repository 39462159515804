import { AbstractComponent } from "appworks/components/abstract-component";
import { Layers } from "appworks/graphics/layers/layers";
import { TrailEmitter } from "appworks/graphics/particles/trail-emitter";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { RandomRangeInt } from "appworks/utils/math/random";
import { Easing } from "appworks/utils/tween";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";

export class HotRollFreespinTrailComponent extends AbstractComponent {
    public sparksActive = 0;

    public fireSpark(reelIndex: number): Contract {
        const layer = Layers.get("SpinCounter");

        const particles = [
            new TrailEmitter(layer, "dice_red_trail", { duration: 750, easing: Easing.Quartic.InOut }),
            new TrailEmitter(layer, "dice_fire", { duration: 750, easing: Easing.Quartic.InOut })
        ];

        const posStart = layer.getPosition("sparkStart_" + reelIndex);
        const posMid = layer.getPosition("sparkMidPoint");
        const posEnd = layer.getPosition("sparkTarget");

        const rndRange = 200;
        posMid.landscape.x += RandomRangeInt(-rndRange, rndRange);
        posMid.landscape.y += RandomRangeInt(-rndRange, rndRange);
        posMid.portrait.x += RandomRangeInt(-rndRange, rndRange);
        posMid.portrait.y += RandomRangeInt(-rndRange, rndRange);

        return new Sequence([
            () => Contract.wrap(() => {
                Services.get(SoundService).customEvent(HotRollSoundEvents.free_spin_spark);
            }),
            () => Contract.wrap(() => this.sparksActive++),
            () => new Parallel(particles.map((particle) => () => particle.play([posStart, posMid, posEnd]))),
            () => Contract.wrap(() => {
                Services.get(SoundService).customEvent(HotRollSoundEvents.free_spin_increment);
                this.sparksActive--;
            })
        ]);
    }
}
