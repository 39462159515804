import { Components } from "appworks/components/components";
import { Text } from "appworks/graphics/pixi/text";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HotRollBackgroundComponent } from "components/hotroll-background-component";
import { gameLayers } from "game-layers";
import { SlingoInitState } from "slingo/states/slingo-init-state";
import { applyWhiteBlackTextStroke } from "util/hr-text-style";

export class HotRollInitState extends SlingoInitState {
    public onEnter(): void {
        super.onEnter();
        Components.get(HotRollBackgroundComponent).init();
    }

    protected showOnBoard(): Contract<any> {
        gameLayers.Onboard.onSceneEnter.addOnce(() => {
            applyWhiteBlackTextStroke(gameLayers.Onboard.scene.onboard.contents.text1);
            applyWhiteBlackTextStroke(gameLayers.Onboard.scene.onboard.contents.text2);
            applyWhiteBlackTextStroke(gameLayers.Onboard.scene.onboard.contents.text3);

            gameLayers.Onboard.scene.onboard.contents.continue.getLabels().forEach((lbl: Text) => {
                applyWhiteBlackTextStroke(lbl);
            })
        });

        return new Sequence([
            () => Contract.wrap(() => {
                gameLayers.Logo.setScene("onboard").execute();
                gameLayers.Logo.show();
            }),
            () => super.showOnBoard(),
            () => gameLayers.Logo.defaultScene()
        ]);
    }
}
