import { Gameplay } from "appworks/model/gameplay/gameplay";
import { HotRollBonusDiceRollResult } from "model/results/hotroll-bonus-dice-roll-result";
import { SlingoPickRecordBuilder } from "slingo/integration/builders/records/slingo-pick-record-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";

export class HotRollPickRecordBuilder extends SlingoPickRecordBuilder {
    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        const record = super.build(gameplay, request, response)[0];

        const bonusPending = record.getFirstResultOfType(SlingoLadderResult).total >= 5;
        if (bonusPending && !record.hasResultOfType(HotRollBonusDiceRollResult)) {
            record.lastChild = false;
        }

        return [record];
    }
}
