import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";

export class HotRollHasBonusDecision extends Decision {
    public evaluate(): Exit {
        const ladderResult = gameState.getCurrentGame().getLatestResultOfType(SlingoLadderResult);

        if (ladderResult && ladderResult.total >= 5) {
            return Exit.True;
        } else {
            return Exit.False;
        }
    }
}
