import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HotRollDiceComponent } from "components/hotroll-dice-component";
import { HotRollDicePaytableComponent } from "components/hotroll-dice-paytable-component";
import { gameLayers } from "game-layers";
import { HotRollBonusDiceRollResult } from "model/results/hotroll-bonus-dice-roll-result";
import { FooterComponent } from "slotworks/components/footer/footer-component";

export class HotRollBonusState extends State {
    protected currentResult: HotRollBonusDiceRollResult;

    public onEnter(cascadeSkip?: boolean): void {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const bonusResults = record.getResultsOfType(HotRollBonusDiceRollResult);
        this.currentResult = bonusResults.find((result) => !result.played);

        const diceComponent = Components.get(HotRollDiceComponent);
        diceComponent.setResult(this.currentResult);
        diceComponent.rollComplete.addOnce(() => this.complete());

        Components.get(HotRollDicePaytableComponent).setMultiplierWithTickup(this.currentResult.multiplier, 500).then(
            () => Components.get(HotRollDicePaytableComponent).setResult(this.currentResult)
        );

        const is7OutBonus = !!bonusResults.find((result) => result.is7Out);
        if (!is7OutBonus) {
            Components.get(FooterComponent).showCustomMessage(
                Services.get(TranslationsService).get("roll") + " " +
                Services.get(TranslationsService).get("x_of_y", {
                    amount: (bonusResults.filter((result) => result.played).length + 1).toString(),
                    total: bonusResults.length.toString()
                })
            );
        }
    }

    public complete(): void {
        this.currentResult.played = true;

        const playedResults = gameState.getCurrentGame().getCurrentRecord()
            .getResultsOfType(HotRollBonusDiceRollResult)
            .filter((result) => result.played);
        const totalWinSoFar = playedResults.map((result) => result.cashWon).reduce((total, current) => total += current);

        const rollLimitText = gameLayers.Dice.getText("roll_100_limit");
        if (rollLimitText) {
            rollLimitText.visible = this.currentResult.is100thRoll;
        }

        new Sequence([            
            () => Contract.wrap(() => Components.get(HotRollDicePaytableComponent).setLives(this.currentResult.livesRemaining)),
            () => Components.get(HotRollDicePaytableComponent).showWinValue(this.currentResult),
            () => Components.get(HotRollDicePaytableComponent).updateTotalWin(totalWinSoFar),
        ]).then(() => {
            if (rollLimitText) { rollLimitText.visible = false; }
            super.complete();
        });
    }

    public onExit(): void {
        Components.get(FooterComponent).clear();
    }
}
