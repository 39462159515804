import { Components } from "appworks/components/components";
import { PreloaderComponent } from "appworks/components/preloader/preloader-component";
import { AssetConfigSchema, FileLocation, SharedManifestSchema, SoundManifest } from "appworks/config/asset-config-schema";
import { CanvasService, ResolutionName } from "appworks/graphics/canvas/canvas-service";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { gpuUploader } from "appworks/loader/gpu-uploader";
import { Model } from "appworks/model/model";
import { Service } from "appworks/services/service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { deviceInfo } from "appworks/utils/device-info";
import { logger } from "appworks/utils/logger";
import axios from "axios";
import * as Howler from "howler";
import * as Logger from "js-logger";
import { Loader as PIXILoader, LoaderResource, MIPMAP_MODES } from "pixi.js";
import { Signal } from "signals";
import { AlertComponent } from "slotworks/components/alert/alert-component";
import * as WebFont from "webfontloader";

declare const __VERSION__: string;

export class LoaderService extends Service {
    /**
     * Signal for setting up the loader
     */
    public setupComplete: Signal = new Signal();

    /**
     * Signal for when sounds have been unlocked via a user interaction
     */
    public onSoundUnlocked: Signal = new Signal();

    /**
     * Signal for when a stage completes loading
     */
    public onStageLoad: Signal = new Signal();

    /**
     * A minimum amount of time to wait before the initial preloader completes - useful if you have a message in the preloader you want to ensure the user has time to read
     */
    public minPreloaderTime: number = 0;

    /**
     * Set this before preloader in order to include sound load in preloader
     */
    public loadSoundsInPreloader: boolean;

    // TODO V6: Delete, this is obsolete and handled properly in translation service now
    /**
     * Set this before preloader to fall back to a specific language if the code is missing.
     */
    public fallbackI18nFileNames: string[] = ["en-GB", "en"];

    // Track if sound has been unlocked
    private soundUnlocked: boolean;

    /**
     * Keep a record of which files belong to which stage, so we can later unload and reload them
     */
    private stages: FileLocation[][];

    /**
     * Keeps a record of which specific textures belong to which stage
     */
    private stageIds: string[][] = [];

    /**
     * Is a stage currently loading
     */
    private stageLoading: boolean;

    /**
     * Queue of stage loads to perform
     */
    private loadStageQueue: Array<() => void> = [];

    /**
     * Keeps a record of which stages have been loaded, and a pixi containr containing all textures loaded
     */
    private loadedStages: Map<number, boolean> = new Map<number, boolean>();

    /**
     * Keep a record of resolves which should be called when a particular stage is loaded
     */
    private stageLoadCallbacks: Map<number, Array<(value: void) => void>> = new Map<number, Array<(value: void) => void>>();

    /**
     * Merged manifest of layout specific and general assets
     */
    private mergedManifest: SharedManifestSchema;

    /**
     * Optional base URL to be prepended to all urls when loading
     */
    private baseURL: string = "";

    /**
     * Used for debugging RAM consumption
     */
    private predictedRAMConsumption: number = 0;

    private errorSignal: Signal = new Signal();

    private loaderOnErrorSignals: number[] = [];
    private loaderOnProgressSignals: number[] = [];

    /**
     * Map of sprites (id of Services(GraphicsService).get(id)) to replace, and a url to load them from
     */
    private dynamicSprites: Map<string, string> = new Map();

    public init(): void {
        // Do nothing.
    }

    public setBaseURL(url: string) {
        this.baseURL = url;
        axios.defaults.baseURL = this.baseURL;
    }

    /**
     * Prepends baseURL onto a given relative url
     */
    public getURL(url: string) {
        return this.baseURL + url;
    }

    /**
     * Add a method to call in the event of an error
     */
    public onError(method: (message: string) => void) {
        this.errorSignal.add(method);
    }

    /**
     * Loads the first three stages (the preloader itself is stage 0, then game stage 1 and 2)
     * and displays load progress with a graphical load bar
     *
     * @param manifest {ManifestSchema}
     * @param onLoadProgress {(stage:number, progress:number, resource:loaders.Resource)=>{}}
     * @return {Contract<void>}
     */
    public setup(config: AssetConfigSchema): Contract<void> {

        const deviceManifest = config.layouts[deviceInfo.layoutId].manifest;
        const deviceGraphicsManifest = deviceManifest.graphics;

        this.stages = deviceGraphicsManifest.hd;
        let domElements = deviceManifest.DOMElements.hd;

        if (Services.get(CanvasService).resolutionName === ResolutionName.MD) {
            this.stages = deviceGraphicsManifest.md;
            domElements = deviceManifest.DOMElements.md;
        }

        if (Services.get(CanvasService).resolutionName === ResolutionName.LD) {
            this.stages = deviceGraphicsManifest.ld;
            domElements = deviceManifest.DOMElements.ld;
        }

        if (config.layouts.all) {
            this.stages[1] = this.stages[1].concat(config.layouts.all.manifest.files);
        }

        this.mergedManifest = config.layouts.all.manifest;

        if (this.stages.length < 3) {
            this.stages[2] = [];
        }

        this.stages.forEach((value, index, array) => {
            if (value === null) {
                array[index] = [];
            }
        });

        const loadSequence = [
            () => this.loadFonts(this.mergedManifest.fonts),
            () => this.loadStage(0),
            () => this.loadParticleBundles(),
            () => this.loadDOMElements(domElements),
            () => Contract.wrap(() => this.cleanup()),
            () => Contract.wrap(() => this.setupComplete.dispatch())
        ];

        return new Sequence<void>(loadSequence);
    }

    /**
     * Loads stages 1 and 2 and displays load progress with a graphical load bar
     */
    public load(): Contract<void> {

        // Update graphical preloader when a file is loaded
        const preloaderUpdate = (stage: number, progress: number, resource: LoaderResource) => {
            //Components.get(PreloaderComponent).fileLoaded(resource);
        };

        //Components.get(PreloaderComponent).start(this.stages[1].concat(this.stages[2]));
        this.hideDOMLoadSpinner();

        const loadParallel: Array<() => Contract<any>> = [
            () => new Sequence([
                () => this.loadStage(1, preloaderUpdate),
                () => this.loadStage(2, preloaderUpdate),
                () => this.loadDynamicSprites()
            ])
        ];

        if (this.loadSoundsInPreloader) {
            loadParallel.push(() => this.loadSounds());
        }

        if (this.minPreloaderTime) {
            loadParallel.push(() => Contract.getTimeoutContract(this.minPreloaderTime));
        }

        // Load stage 0 (the preloader), then stages 1 and 2
        return new Parallel(loadParallel);
    }

    /**
     * Unloads a stage from memory
     *
     * @param stage {number}
     */
    public unloadStage(stage: number) {
        if (this.stageIds[stage]) {
            while (this.stageIds[stage].length) {
                const textureId = this.stageIds[stage].pop();
                Services.get(GraphicsService).destroyTexture(textureId);
            }

            this.loadedStages.delete(stage);

            this.log("Unloaded stage " + stage.toString());
        } else {
            this.log("Could not unload stage. Stage " + stage.toString() + " is not loaded");
        }
    }

    public isStageLoaded(stage: number) {
        return this.loadedStages.has(stage);
    }

    /**
     * Loads a specified stage
     * @param stage {number}
     * @param onLoadProgress {(stage:number, progress:number, resource:loaders.Resource)=>{}}
     * @return {Contract<void>}
     */
    public loadStage(stage: number, onLoadProgress?: (stage: number, progress: number, resource: LoaderResource) => void): Contract<void> {

        return new Contract<void>((resolve) => {
            if (this.loadedStages.has(stage)) {
                resolve(null);
            } else if (this.stageLoadCallbacks.has(stage)) {
                this.stageLoadCallbacks.get(stage).push(resolve);
            } else {

                if (this.stageLoading) {
                    this.loadStageQueue.push(() => this.loadStage(stage, onLoadProgress).then(resolve));
                    return;
                }

                const filesToLoad: FileLocation[] = this.stages[stage];
                if (filesToLoad.length === 0) {
                    this.onStageLoad.dispatch(stage);
                    resolve(null);
                    return;
                }

                const totalSize = Math.round(filesToLoad.reduce((prev, file) => file.size + prev, 0) / 10.24) / 100;

                this.log(`Loading stage ${stage.toString()} (${totalSize} MB)`);

                this.stageLoading = true;
                this.stageLoadCallbacks.set(stage, [resolve]);

                PIXILoader.shared.pre((resource: LoaderResource, next: Function) => {
                    if (resource.url.indexOf("?") === -1) {
                        resource.url += "?" + __VERSION__;
                    }

                    next();
                });

                PIXILoader.shared.reset();
                this.removeLoaderSignals();

                PIXILoader.shared.baseUrl = this.baseURL;

                this.loaderOnErrorSignals.push(PIXILoader.shared.onError.add(() => {
                    this.errorSignal.dispatch("err.dis", true);
                }));

                this.loaderOnProgressSignals.push(PIXILoader.shared.onProgress.add((pixiLoader: PIXILoader, resource: LoaderResource) => {
                    if (resource.error) {
                        this.log("Failed to load " + resource.url);
                    } else {
                        this.atlasLoaded(stage, resource);
                        if (onLoadProgress) {
                            onLoadProgress(stage, pixiLoader.progress, resource);
                        }
                    }
                }));

                PIXILoader.shared
                    .add(filesToLoad)
                    .load(() => {
                        this.log("Stage " + stage.toString() + " load complete (" + (Math.floor(this.predictedRAMConsumption * 100) / 100) + " MB RAM)");

                        this.stageLoading = false;

                        this.onStageLoad.dispatch(stage);

                        this.loadedStages.set(stage, true);

                        const loadedResolves = this.stageLoadCallbacks.get(stage);

                        for (const loadedResolve of loadedResolves) {
                            loadedResolve(null);
                        }

                        this.stageLoadCallbacks.delete(stage);

                        if (this.loadStageQueue.length) {
                            this.loadStageQueue.shift()();
                        }
                    });
            }
        });
    }

    public loadSounds(): Contract<void> {
        Services.get(SoundService).setLoading();

        const manifest = this.mergedManifest;

        return new Contract<void>((resolve) => {

            // Disable sounds in IE11, it's very buggy (causes huge lag spikes, see: https://github.com/goldfire/howler.js/issues/931)
            if (deviceInfo.isIE11()) {
                resolve(null);
                return;
            }

            const soundConfig = manifest.sound;

            const soundLoads = [];
            if (soundConfig?.src && soundConfig?.sprite) {
                // Single sound sprite
                soundLoads.push(() => this.loadSound(soundConfig as SoundManifest));
            } else {
                // Multiple sound sprites
                for (const spriteName in soundConfig) {
                    if (soundConfig[spriteName]) {
                        soundLoads.push(() => this.loadSound(soundConfig[spriteName], spriteName));
                    }
                }
            }

            new Parallel(soundLoads).then(() => {
                Services.get(SoundService).loadComplete();
                resolve();
            });
        });
    }

    public unlockSound() {
        if (!this.soundUnlocked) {
            this.soundUnlocked = true;
            this.onSoundUnlocked.dispatch();
        }
    }

    public soundIsUnlocked() {
        return this.soundUnlocked;
    }

    public loadConfig() {
        return new Contract<AssetConfigSchema>((resolve) => {
            axios.get("config/asset-config.json?" + __VERSION__).then((response) => {
                this.log("Asset config loaded", response.data);
                resolve(response.data);
            }).catch((e) => {
                if (!e.response) {
                    throw e;
                }
            });
        });
    }

    public loadI18n(assetConfig: AssetConfigSchema): Contract<any> {

        if (assetConfig.layouts.all.manifest.languages?.length) {
            // TODO V6: This should be standard behaviour, so the else can be removed and this doesn't need to have an if around it


            // The target language configured by a wrapper or url param etc, it may not be an IETF language code yet
            let targetLanguage = Model.read().settings.language;

            // Send target language to translation service, which will return the IETF language code
            const language = Services.get(TranslationsService).setLanguage(targetLanguage, true);

            return new Contract<any>((resolve) => {
                axios.get("i18n/" + language + ".json?" + __VERSION__).then((response) => {
                    this.log("i18n loaded", response.data);
                    resolve(response.data);
                });
            });
        } else {

            // TODO: V6 Delete this block
            let langCode = Services.get(LoaderService).fallbackI18nFileNames.shift();

            return new Contract<any>((resolve) => {
                axios.get("i18n/" + langCode + ".json?" + __VERSION__).then((response) => {
                    this.log("i18n loaded (legacy)", response.data);
                    resolve(response.data);
                    Services.get(TranslationsService).setLanguage(langCode);
                }).catch((e) => {
                    if (!e.response) {
                        throw e;
                    }
                    if (this.fallbackI18nFileNames.length < 1) {
                        Components.get(AlertComponent).error("Translations failed to load", true).execute();
                    } else {
                        this.loadI18n(assetConfig).then((data) => {
                            Services.get(TranslationsService).setLanguage(langCode);
                            resolve(data);
                        });
                    }
                });
            });
        }

    }

    public getStageFiles(stage: number): FileLocation[] {
        return this.stages[stage];
    }

    public getTotalStages(): number {
        return this.stages?.filter((stage) => stage.length > 0).length || 1;
    }

    public allStagesLoaded(): boolean {
        return this.loadedStages.size === this.getTotalStages();
    }

    public registerDynamicSprite(id: string, url: string) {
        this.dynamicSprites.set(id, url);
    }

    protected loadDynamicSprites() {
        return new Contract<void>((resolve) => {
            let resolved = false;

            const urls = [];
            this.dynamicSprites.forEach((url) => urls.push(url));

            if (urls.length <= 0) {
                resolve();
                return;
            }

            const binding = PIXILoader.shared.onError.once(() => {
                logger.warn("Failed to load dynamic sprites");
                resolve();
                resolved = true;
            });

            PIXILoader.shared
                .add(urls)
                .load(() => {

                    if (resolved) {
                        return;
                    }

                    this.dynamicSprites.forEach((url, id) => {
                        const newTexture = PIXILoader.shared.resources[url].texture;
                        const oldTexture = Services.get(GraphicsService).getTexture(id);
                        if (oldTexture.width !== newTexture.width || oldTexture.height != newTexture.height) {
                            logger.warn(`Warning: Dynamic asset does not match embedded asset dimensions: ${id} - expected: ${oldTexture.width}x${oldTexture.height}, actual: ${newTexture.width}x${newTexture.height}`)
                        }

                        Services.get(GraphicsService).addTexture(id, newTexture);
                    });

                    binding.detach();

                    resolve();
                });
        });
    }

    private loadSound(soundConfig: SoundManifest, spriteName?: string): Contract<void> {
        return new Contract((resolve) => {
            soundConfig.src = soundConfig.src.map((src: string) => this.baseURL + src + "?" + __VERSION__);

            const sound = new Howler.Howl({
                src: soundConfig.src,
                sprite: soundConfig.sprite as IHowlSoundSpriteDefinition,
                autoUnlock: true,
                onunlock: () => this.unlockSound()
            } as IHowlProperties);

            sound.once("load", () => {
                this.soundLoaded(sound, soundConfig.sprite);
                resolve(null);
            });
        });
    }

    private loadFonts(fonts: string[]): Contract<void> {
        return new Contract<void>((resolve) => {
            if (!fonts) {
                Logger.warn("No fonts to load");
                resolve(null);
            } else {
                const config = {
                    custom: {
                        families: fonts,
                        urls: [this.baseURL + "font/fonts.css?" + __VERSION__]
                    },
                    active: () => {
                        resolve(null);
                    },
                    fontinactive: (familyName, fvd) => {
                        Logger.warn("Invalid font: " + familyName);
                    },
                    inactive: () => {
                        Logger.warn("Invalid fonts");
                        resolve(null);
                    }
                } as WebFont.Config;

                WebFont.load(config);
            }
        });
    }

    private loadParticleBundles() {
        return Services.get(ParticleService).loadFXFiles();
    }

    private loadDOMElements(elements: string[]) {
        if (!elements || !elements.length) {
            return Contract.empty();
        }

        const domPreloadElement = document.createElement("div");
        domPreloadElement.id = "DOMPreloadElement";
        domPreloadElement.style.position = "absolute";
        domPreloadElement.style.width = "0";
        domPreloadElement.style.height = "0";
        domPreloadElement.style.overflow = "hidden";
        domPreloadElement.style.zIndex = "-1";

        document.body.appendChild(domPreloadElement);

        let contentString = "";

        return new Contract<void>((resolve) => {
            let imagesToLoad = elements.length;
            const onImageLoaded = () => {
                imagesToLoad--;

                if (imagesToLoad <= 0) {
                    domPreloadElement.style.content = contentString;

                    resolve(null);
                }
            };

            for (const element of elements) {
                const cacheBustedPath = this.baseURL + element + "?" + __VERSION__;
                contentString += "url(" + cacheBustedPath + ") ";

                const img = new Image();
                img.src = cacheBustedPath;
                if (img.complete) {
                    onImageLoaded();
                } else {
                    img.onload = onImageLoaded;
                }
            }
        });
    }

    private soundLoaded(sound: Howl, definition: IHowlSoundSpriteDefinition) {
        Services.get(SoundService).addSoundSprite(sound, definition);
    }

    /**
     * When a pixi atlas is loaded, add it to the Services.get(GraphicsService) so that it can be accessed by the game
     *
     * @param stage {number}
     * @param resource {loaders.Resource}
     */
    private atlasLoaded(stage: number, resource: LoaderResource) {
        if (!this.stageIds[stage]) {
            this.stageIds[stage] = [];
        }

        // Prepacked spines
        if (resource.spineData) {
            Services.get(SpineService).spineData.set(resource.name, resource.spineData);
        }

        if (resource.textures) {
            for (const id of Object.keys(resource.textures)) {
                Services.get(GraphicsService).addTexture(id, resource.textures[id]);
                this.stageIds[stage].push(id);
            }
        } else if (resource.texture) {
            this.predictedRAMConsumption += (resource.texture.baseTexture.realWidth * resource.texture.baseTexture.realHeight * 4) / (1024 * 1024) * 2;

            this.disableMipmapping(resource);
            gpuUploader.upload(resource.texture).then(() => this.log(resource.name + " uploaded to GPU"));
        }
    }

    private disableMipmapping(resource: LoaderResource) {
        if (resource.texture && resource.name.indexOf("nomipmap") >= 0) {
            resource.texture.baseTexture.mipmap = MIPMAP_MODES.OFF;
        }
    }

    private hideDOMLoadSpinner() {
        if (document.getElementById("preloadSpinner")) {
            document.getElementById("preloadSpinner").style.display = "none";
        }
    }

    private removeLoaderSignals() {
        this.loaderOnProgressSignals.forEach((signal) => {
            PIXILoader.shared.onProgress.detach(signal);
        });
        this.loaderOnErrorSignals.forEach((signal) => {
            PIXILoader.shared.onError.detach(signal);
        });

        this.loaderOnProgressSignals = [];
        this.loaderOnErrorSignals = [];
    }

    private cleanup() {
        const preloaderEl = document.getElementById("DOMPreloadElement");
        if (preloaderEl) {
            preloaderEl.remove();
        }
    }

    /**
     * Log a message with colored label
     *
     * @param message {string}
     */
    private log(message: string, obj: any = "") {
        Logger.info("%c Loader ", "background: #95f; color: #fff", message, obj);
    }
}
