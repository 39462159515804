import { Layers } from "appworks/graphics/layers/layers";
import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { AbstractControl } from "appworks/ui/controls/abstract-control";
import { slingoModel } from "slingo/model/slingo-model";

export class SlingoGameInstanceIdControl extends AbstractControl {
    protected label: Text;

    constructor(protected layer: Layers) {
        super();

        this.label = layer.getText("gameId");

        this.update();
        slingoModel.onUpdate.add(() => this.update());
    }

    protected update() {
        return;
        const model = slingoModel.read();
        if (model.gameInstanceId) {
            this.label.text = Services.get(TranslationsService).get("game_id", { gameId: model.gameInstanceId });
        } else {
            this.label.text = "";
        }
    }
}