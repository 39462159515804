import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";

export class HotRollDiceTransformTransition extends AbstractSceneTransition {
    public in(): Contract<any> {
        const anim = this.layer.getSpine("dice_transform");

        return new Sequence([
            () => Contract.wrap(() => Services.get(SoundService).customEvent(HotRollSoundEvents.dice_transform)),
            () => anim.playOnce(),
            () => this.layer.defaultScene()
        ]);
    }
}
