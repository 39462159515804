export interface GMRResponse {
    actions: GMRAction[];
    state: string;
    rsp: number;
}

export interface GMRConfig {
    stakeConfig: GMRStakeConfig;
}

export interface GMRBalance {
    type: "CASH" | "BONUS";
    amount: number;
    currencyCode: string;
}

export interface GMRStakeConfig {
    amounts: number[];
    currencyCode: string;
    defaultIndex: number;
    defaultFractionDigits: number;
    lastStake: number;
    maximumStake: number;
    maximumTotalStake: number;
}

export interface GMRGameConfig {
    id: string;
    rtp: number;
    minimumSpinDuration: number;
    celebrateWinThreshold: number;
}

export interface GMRBuyFeature {
    mode: string;
    multiplier: number;
}

export enum GMRAction {
    PURCHASE = "PURCHASE",
    START = "START",
    SPIN = "SPIN",
    CHOOSE_CELL = "CHOOSE_CELL",
    COLLECT = "COLLECT"
}