import { Components } from "appworks/components/components";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HotRollBackgroundFireComponent } from "components/hotroll-background-fire-component";
import { gameLayers } from "game-layers";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";
import { SlingoBigWinState } from "slingo/states/slingo-big-win-state";

export class HotRollBigWinState extends SlingoBigWinState {
    public onEnter(): void {
        if (this.bigWinTriggered()) {
            this.bigWin().then(() => this.complete());
        } else if (this.getTotalWin() > 0) {
            this.showCreditWinnerSummary().then(() => this.complete());
        } else {
            this.complete();
        }
    }

    public complete(): void {
        gameLayers.CelebrationForeground.defaultScene().execute();
        Components.get(HotRollBackgroundFireComponent).setFlameLevel(0, 250).execute();
        super.complete();
    }

    protected bigWin(): Contract<boolean> {
        gameLayers.CelebrationForeground.setScene("logo").execute();
        Components.get(HotRollBackgroundFireComponent).setFlameLevel(1, 1000).then(() => {
            Components.get(HotRollBackgroundFireComponent).setFlameLevel(3, 9000).execute();
        });
        return super.bigWin();
    }

    protected showCreditWinnerSummary(): Contract {
        return new Sequence([
            () => new Parallel([
                () => gameLayers.CelebrationForeground.setScene("logo"),
                () => gameLayers.CelebrationContent.setScene("creditwinner"),
                () => gameLayers.CelebrationBackground.setScene("creditwinner")
            ]),
            () => Contract.wrap(() => {
                Services.get(SoundService).customEvent(HotRollSoundEvents.winSummaryTone);

                const txt = gameLayers.CelebrationContent.getBitmapText("value");
                txt.text = Services.get(CurrencyService).format(this.getTotalWin());
            }),
            () => Contract.getTimeoutContract(5000),
            () => new Parallel([
                () => gameLayers.CelebrationForeground.defaultScene(),
                () => gameLayers.CelebrationContent.defaultScene(),
                () => gameLayers.CelebrationBackground.defaultScene()
            ])
        ]);
    }

    protected isFullHouse(): boolean {
        return false;
    }
}
