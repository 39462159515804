import { AbstractComponent } from "appworks/components/abstract-component";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { pulse, scaleIn } from "appworks/utils/animation/scale";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract, Resolver } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { RandomFromArray } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { shuffle } from "lodash";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { applyWhiteBlackTextStroke } from "util/hr-text-style";

export class HotRollBonusMultipleChoicePickComponent extends AbstractComponent {
    protected readonly level7ValueLists = [
        [2, 2, 3, 4], [2, 3, 3, 4], [2, 3, 4, 4]
    ];
    protected readonly level8ValueLists = [
        [3, 3, 4, 5], [3, 4, 4, 5], [3, 4, 5, 5]
    ];

    protected cancelGroup = new CancelGroup();

    public init(): void {
        // Start button idle animations on scene enter
        gameLayers.BonusChoice.onSceneEnter.add(() => {
            this.hideHighlights();

            const highlights = [
                gameLayers.BonusChoice.getSprite("highlight_1"),
                gameLayers.BonusChoice.getSprite("highlight_2"),
                gameLayers.BonusChoice.getSprite("highlight_3"),
                gameLayers.BonusChoice.getSprite("highlight_4")
            ].filter((highlight: Sprite) => Boolean(highlight));

            let highlightOn = false;
            this.cancelGroup.interval(() => {
                highlightOn = !highlightOn;
                highlights.forEach((highlight: Sprite) => {
                    highlight.visible = highlightOn;
                });
            }, 500);
        });
    }

    public show(result: number, type: "multiplier" | "rolls"): Contract {
        return new Contract((resolve: Resolver<void>) => {
            gameLayers.ScreenFade.setScene("flame_transition").execute();
            Timer.setTimeout(() => {
                gameLayers.BonusChoice.setScene("dice_choice").then(() => {
                    const level = gameState.getCurrentGame().getLatestResultOfType(SlingoLadderResult).total;

                    let revealValues: number[];
                    if (level === 7) {
                        revealValues = [...RandomFromArray(this.level7ValueLists)];
                    } else if (level === 8) {
                        revealValues = [...RandomFromArray(this.level8ValueLists)];
                    } else {
                        throw new Error("Multiple choice bonus triggered on an invalid level");
                    }

                    revealValues.splice(revealValues.indexOf(result), 1);
                    shuffle(revealValues);

                    for (let i = 1; i <= 4; i++) {
                        const btn = gameLayers.BonusChoice.getButton("pick" + i);

                        btn.on(ButtonEvent.CLICK.getPIXIEventString(), () => {
                            this.disableButtons(i);
                            this.hideHighlights();
                            this.cancelGroup.skip();

                            new Parallel([
                                () => this.revealPick(i, result, type),
                                () => Contract.getDelayedContract(1000, () => this.revealNonPicked(revealValues, i, type))
                            ]).then(resolve);
                        });
                    }
                });
            }, 700);
        });
    }

    public hideHighlights() {
        this.cancelGroup.skip();
        [
            gameLayers.BonusChoice.getSprite("highlight_1"),
            gameLayers.BonusChoice.getSprite("highlight_2"),
            gameLayers.BonusChoice.getSprite("highlight_3"),
            gameLayers.BonusChoice.getSprite("highlight_4")
        ].forEach((highlight: Sprite) => { if (highlight) { highlight.visible = false; } });
    }

    protected revealPick(btnIndex: number, value: number, type: "multiplier" | "rolls", greyed: boolean = false): Contract {
        let element: PIXIElement;
        const contracts = [
            () => scaleIn(element, 500, Easing.Back.Out)
        ];

        if (type === "multiplier") {
            element = Sprite.from(gameLayers.BonusChoice.getSprite(`pays${value}x`).texture);
            gameLayers.BonusChoice.add(element);

            element.setDualPosition(gameLayers.BonusChoice.getPosition("multiplier_" + btnIndex));
        } else {
            element = gameLayers.BonusChoice.getText("rolltext_" + btnIndex);
            element.text = Services.get(TranslationsService).get("x_rolls", { rolls: value });
            applyWhiteBlackTextStroke(element);
        }

        if (greyed) {
            if (type === "multiplier") {
                element.tint = 0x888888;
            } else {
                element.alpha = 0.75;
            }
        } else {
            contracts.push(() => pulse(element, { x: 1.2, y: 1.2 }, 250));
            contracts.push(() => pulse(element, { x: 1.2, y: 1.2 }, 250));
            contracts.push(() => pulse(element, { x: 1.2, y: 1.2 }, 250));
            contracts.push(() => pulse(element, { x: 1.2, y: 1.2 }, 250));
            contracts.push(() => pulse(element, { x: 1.2, y: 1.2 }, 250));
        }

        element.landscape.scale.set(0);
        element.portrait.scale.set(0);

        CenterPivot(element);

        return new Sequence(contracts);
    }

    protected revealNonPicked(values: number[], chosenIndex: number, type: "multiplier" | "rolls"): Contract {
        const contracts: Array<() => Contract> = [];

        for (let i = 1; i <= 4; i++) {
            if (i === chosenIndex) { continue; }
            contracts.push(() => this.revealPick(i, values.shift(), type, true));
        }

        return new Parallel(contracts);
    }

    protected disableButtons(pickIndex: number) {
        for (let i = 1; i <= 4; i++) {
            const btn = gameLayers.BonusChoice.getButton("pick" + i);
            btn.setEnabled(false);

            btn.setAlpha(pickIndex === i ? 1 : 0.75);
        }
    }
}
