import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";

export class HotRollFlameScreenTransition extends AbstractSceneTransition {
    public in(): Contract<any> {
        Services.get(SoundService).customEvent(HotRollSoundEvents.fire_transition);

        const anim = this.layer.getAnimatedSprite("fire_transition");

        return new Contract((resolve) => {
            anim.onComplete.add(resolve);
            anim.onComplete.add(() => this.layer.defaultScene().execute());
        });
    }
}
