import { HTMLText } from "appworks/graphics/pixi/html-text";
import { Text } from "appworks/graphics/pixi/text";
import { OutlineFilter } from "pixi-filters";

export function applyWhiteBlackTextStroke(text: Text | HTMLText) {
    if (text) {
        const stroke1 = new OutlineFilter(4, 0x000000, 1);
        const stroke2 = new OutlineFilter(4, 0xffffff, 1);
        text.filters.push(stroke1, stroke2);
    }
}

export function applyRedBlackTextStroke(text: Text | HTMLText) {
    if (text) {
        const stroke1 = new OutlineFilter(2, 0xff0000, 1);
        const stroke2 = new OutlineFilter(4, 0x000000, 1);
        text.filters.push(stroke1, stroke2);
    }
}