import { supportedIETFCodes } from "appworks/config/asset-config-schema";
import { Model } from "appworks/model/model";
import { contains, lowerCaseMapKeys } from "appworks/utils/collection-utils";
import { parseJSONMap } from "appworks/utils/json-utils";
import { TextMetrics } from "pixi.js";
import { Service } from "../service";
import { translationKeyReplacer } from "./translation-key-replacer";
import { logger } from "appworks/utils/logger";

export interface FontSubstitute {
    font: string;
    weight?: string;
    // If specified, substitute if the current language is found in this array
    includedLanguages?: string[];
    // If specified, do NOT substitute if the current language is found in this array
    excludedLanguages?: string[];
    // If specified, only substitute text fields with this instance name
    targetInstances?: string[];
}

export class TranslationsService extends Service {
    // List of non-IETF compliant or non-slotworks supported codes and the corresponding IETF compliant / slotworks supported code
    protected static IETFMap: Map<string, string> = new Map<string, string>([
        ["ensc", "en-SC"], ["inid", "id-ID"], ["es419", "es-41"], ["enuk", "en-GB"], ["en", "en-GB"], ["bg", "bg-BG"], ["zh", "zh-CN"], ["hr", "hr-HR"], ["cs", "cs-CZ"], ["da", "da-DK"], ["nl", "nl-NL"], ["et", "et-EE"], ["fi", "fi-FI"], ["fr", "fr-FR"], ["ka", "ka-GE"], ["de", "de-DE"], ["gr", "gr-EL"], ["hu", "hu-HU"], ["is", "is-IS"], ["id", "id-ID"], ["it", "it-IT"], ["ja", "ja-JP"], ["km", "km-KH"], ["ko", "ko-KR"], ["lv", "lv-LV"], ["lt", "lt-LT"], ["ms", "ms-MY"], ["nb", "nb-NO"], ["nn", "nn-NO"], ["pl", "pl-PL"], ["pt", "pt-PT"], ["ro", "ro-RO"], ["ru", "ru-RU"], ["sk", "sk-SK"], ["es", "es-41"], ["es", "es-LA"], ["es", "es-MX"], ["es", "es-ES"], ["sv", "sv-SE"], ["th", "th-TH"], ["tr", "tr-TR"], ["uk", "uk-UA"], ["vi", "vi-VN"]
    ]);

    private static padding: Map<string, number> = new Map<string, number>([] as any);

    private static metricsStrings: Map<string, string> = new Map<string, string>([
        ["hi", "नि:होतेशुल्कवाइल्ड्सस्पिनपुरस्कारित!"],
        ["vi", "CƯỢCSỐDƯĐđEeÊêĂăÂâỶỷỬửẦầ"],
        ["vi-VN", "CƯỢCSỐDƯĐđEeÊêĂăÂâỶỷỬửẦầ"]
    ] as any);

    /* This should be a few symbols, one which represents the highest ascend and the lowest descend
     */
    private static baselineSymbols: Map<string, string> = new Map<string, string>([
        ["hi", "ड्सहोतेशु"],
        ["vi", "CƯỢCSỐDƯĐđEeÊêĂăÂâỶỷỬửẦầ"],
        ["vi-VN", "CƯỢCSỐDƯĐđEeÊêĂăÂâỶỷỬửẦầ"]
    ] as any);

    public debug: boolean = false;

    public formats: { [id: string]: { [id: string]: any } } = {};

    public breakWords: boolean;

    public languageCode: string;

    protected translations: Map<string, string>;

    protected aliases: Map<string, string> = new Map();

    protected breakWordLanguages: string[] = ["zh", "ja", "th", "ko", "test"];

    protected pixiToCSS: { [id: string]: string } = {
        fontFamily: "font-family",
        fill: "color",
        fontSize: "font-size"
    };

    protected fontSubstitutes: Map<string, FontSubstitute[]> = new Map();

    protected globalParameters?: { [value: string]: string | number } = {};

    public init(): void {
        //
    }

    /**
     * List of variables inside translations which should all translate to the same thing, for example "{RTP}" would be the same for all text fields
     */
    public setGlobalParameter(key: string, value: string) {
        this.globalParameters[key] = value;
        this.globalParameters[key.toLowerCase()] = this.globalParameters[key];
    }

    public setFontSubstitute(font: string, substitute: FontSubstitute) {
        if (!this.fontSubstitutes.has(font)) {
            this.fontSubstitutes.set(font, []);
        }
        this.fontSubstitutes.get(font).push(substitute);
    }

    public getFontSubstitutes(): Map<string, FontSubstitute[]> {
        return this.fontSubstitutes;
    }

    // TODO V6: IEFTMode should be mandatory, aka removed as an argument and always behave this way
    /**
     * Set the language
     * @param code 
     * @param IEFTMode   the code may not be IETF language code compliant, if this flag is set it will be converted to IETF language code, store and return it
     */
    public setLanguage(code: string = "en-GB", IEFTMode: boolean = false) {
        if (IEFTMode) {
            let convertedCode = this.simplifyLangCode(code);

            // Convert non-compliant codes to compliant code
            if (TranslationsService.IETFMap.has(convertedCode)) {
                convertedCode = TranslationsService.IETFMap.get(convertedCode);
            }

            convertedCode = supportedIETFCodes.find((ietfCode) => {
                if (this.simplifyLangCode(ietfCode) === this.simplifyLangCode(convertedCode)) {
                    return ietfCode;
                }
            });

            if (!convertedCode) {
                logger.warn(`Language "${code}" not supported, falling back to English`);
                convertedCode = supportedIETFCodes[0];
            }

            code = convertedCode;
        } else {
            if (code.indexOf("-") > -1) {
                code = code.split("-")[0].toLowerCase();
            }
        }

        this.breakWords = false;

        for (const breakWordLanguage of this.breakWordLanguages) {
            if (code.toLocaleLowerCase().indexOf(breakWordLanguage) > -1) {
                this.breakWords = true;
            }
        }

        if (TranslationsService.metricsStrings.has(code)) {
            TextMetrics.METRICS_STRING = TranslationsService.metricsStrings.get(code);
        }

        if (TranslationsService.baselineSymbols.has(code)) {
            TextMetrics.BASELINE_SYMBOL = TranslationsService.baselineSymbols.get(code);
        }

        this.languageCode = code;

        return this.languageCode;
    }

    public setAlias(alias: string, code: string) {
        this.aliases.set(alias.toLocaleLowerCase(), code);
    }

    public setFormat(name: string, format: any) {
        this.formats[name] = format;
    }

    // TODO: V6 rename this. Load is misleading, as the loading is already done and this simply registers the loaded json with the service
    public load(json: any) {
        this.translations = parseJSONMap<string>(json.translations);

        lowerCaseMapKeys(this.translations);

        if (json.formats) {
            for (const format in json.formats) {
                if (json.formats.hasOwnProperty(format)) {
                    this.formats[format] = json.formats[format];
                }
            }
        }

        if (this.debug) {
            this.aliases.forEach((alias: string) => {
                this.get(alias);
            });
        }
    }

    public getPadding() {
        if (TranslationsService.padding.has(this.languageCode)) {
            return TranslationsService.padding.get(this.languageCode);
        }

        return 0;
    }

    public set(id: string, translation: string) {
        this.translations.set(id.toLowerCase(), translation);
    }

    public exists(id: string) {
        const translationId = this.aliases.get(id) || id;
        return this.translations.has(translationId.toLowerCase());
    }

    // Will return first translation which exists, if none exist, first id in list is used
    public getFirstValid(ids: string[], parameters?: { [value: string]: string | number }) {
        let validId = ids.find((id) => this.exists(id));

        if (!validId) {
            validId = ids[0];
        }

        return this.get(validId, parameters);
    }

    public get(id: string, parameters?: { [value: string]: string | number }): string {
        let translationId = id;

        if (!translationId) {
            logger.warn("Trying to load an undefined translation ID.");
            return translationId;
        }

        if (!this.aliases) {
            logger.warn("Trying to use a translation before they were loaded: " + translationId);
            return translationId;
        }

        const alias = translationId;

        if (!this.exists(translationId)) {
            logger.warn("Missing translation: " + translationId.toLowerCase());
            return translationId;
        }

        if (this.aliases.has(translationId.toLowerCase())) {
            translationId = this.aliases.get(translationId.toLowerCase());
        }

        translationId = translationId.toLowerCase();

        const rawTranslation: string = this.translations.get(translationId);

        let translation = "";

        // Find brace blocks
        let searchIndex = -1;

        while (true) {

            const blockIndex = rawTranslation.indexOf("{", searchIndex + 1);
            const endBlockIndex = rawTranslation.indexOf("}", blockIndex + 1);

            if (blockIndex === -1) {
                translation += rawTranslation.slice(searchIndex + 1);
                break;
            }

            translation += rawTranslation.slice(searchIndex + 1, blockIndex);

            const block = rawTranslation.slice(blockIndex, endBlockIndex + 1).split(" ").join("");

            const argId = block.slice(1, block.length - 1).toLowerCase();
            let arg: string | number = "";

            if (translationKeyReplacer.lookup(argId)) {
                arg = translationKeyReplacer.get(argId);
            }

            if (this.globalParameters[argId] !== undefined) {
                arg = this.globalParameters[argId];
            }

            if (parameters) {
                Object.keys(parameters).forEach((key) => {
                    parameters[key.toLowerCase()] = parameters[key];
                });

                if (parameters && parameters[argId] !== undefined) {
                    arg = parameters[argId];
                }
            }

            translation += arg;

            searchIndex = endBlockIndex;
        }

        if (this.debug) {
            translation = "$" + translation + "$";
        }

        if (this.formats && this.formats[translationId]) {
            translation = `<${translationId}>${translation}</${translationId}>`;
        } else if (this.aliases && this.formats[alias]) {
            translation = `<${alias}>${translation}</${alias}>`;
        }

        return translation;
    }

    public updateDOMTexts() {
        const domTexts = document.querySelectorAll(".i18n");
        if (domTexts) {
            for (let i = 0; i < domTexts.length; i++) {
                const domElement = domTexts.item(i) as HTMLElement;
                domElement.innerHTML = this.convertTags(this.get(domElement.getAttribute("data-i18n") || domElement.innerText || ""));

                this.updateFontsWithSubstitute(domElement);
            }
        }
    }

    /**
     * Converts <orange></orange>
     * where orange is defined as
     * {
     *  "fill": "#ff0000",
     *  "fontSize": 100,
     *  "fontFamily": "Arial"
     * }
     *  to <span style="color: #ff0000; font-size: 100%; font-family: Arial"></span>
     * @param text {string}
     */
    public convertTags(text: string): string {
        for (const formatName in this.formats) {
            if (this.formats.hasOwnProperty(formatName)) {
                const format = this.formats[formatName];

                let styleString = "";
                for (const prop in format) {
                    if (format.hasOwnProperty(prop)) {
                        let propName = prop;
                        if (this.pixiToCSS[prop]) {
                            propName = this.pixiToCSS[prop];
                        }
                        let units = "";
                        if (propName === "font-size") {
                            units = "%";
                        }

                        styleString += propName + ": " + format[prop] + units + ";";
                    }
                }

                while (text.indexOf("<" + formatName + ">") > -1) {
                    const tagStart = text.indexOf("<" + formatName + ">");
                    const tagContentsStart = tagStart + formatName.length + 2;
                    const tagContentsEnd = text.indexOf("</" + formatName + ">", tagContentsStart);
                    const tagEnd = tagContentsEnd + formatName.length + 3;

                    const tagContents = text.slice(tagContentsStart, tagContentsEnd);

                    text = text.substring(0, tagStart) + "<span style=\"" + styleString + "\">" + tagContents + "</span>" + text.substr(tagEnd);
                }
            }
        }

        return text;
    }

    public playingInEnglish() {
        return this.languageCode?.toLowerCase().indexOf("en") > -1;
    }

    protected updateFontsWithSubstitute(element: HTMLElement) {
        const language = Model.read().settings.language;

        this.fontSubstitutes.forEach((substitutes: FontSubstitute[], font: string) => {
            substitutes.forEach((substitute: FontSubstitute, index: number) => {
                if (this.elementUsesFont(element, font)) {
                    if (!substitute.targetInstances || (substitute.targetInstances && contains(substitute.targetInstances, element.id))) {
                        if (substitute.includedLanguages && contains(substitute.includedLanguages, language)) {
                            element.style.fontFamily = substitute.font;
                            element.style.fontWeight = substitute.weight;
                        }
                        if (substitute.excludedLanguages && !contains(substitute.excludedLanguages, language)) {
                            element.style.fontFamily = substitute.font;
                            element.style.fontWeight = substitute.weight;
                        }
                    }
                }
            });
        });
    }

    protected elementUsesFont(element: HTMLElement, font: string) {

        const style = document.defaultView.getComputedStyle(element);

        if (style.fontFamily.length > 0) {
            return style.fontFamily.toLowerCase().indexOf(font.toLowerCase()) > -1;
        }

        return false;
    }


    // Strip non letters / numbers, and lowercase result for easier comparison
    private simplifyLangCode(code: string): string {
        return code.replace(/[^A-Za-z\d]*/g, "").toLowerCase();
    }
}
