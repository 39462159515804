
export enum SoundNames {
    Dab1 = "Dab1",
    Dab2 = "Dab2",
    Dab3 = "Dab3",
    Dab4 = "Dab4",
    Dab5 = "Dab5",
    hr02xAward = "hr02xAward",
    hr03xAward = "hr03xAward",
    hr04xAward = "hr04xAward",
    hr05xAward = "hr05xAward",
    hr06xAward = "hr06xAward",
    hr07xAward = "hr07xAward",
    hr08xAward = "hr08xAward",
    hr09xAward = "hr09xAward",
    hr10xAward = "hr10xAward",
    hr11xAward = "hr11xAward",
    hr12xAward = "hr12xAward",
    hr13xAward = "hr13xAward",
    hr14xAward = "hr14xAward",
    hr15xAward = "hr15xAward",
    hr16xAward = "hr16xAward",
    hrAceDeuce = "hrAceDeuce",
    hrAces = "hrAces",
    hrAnnounceInstruction1a = "hrAnnounceInstruction1a",
    hrAnnounceInstruction2a = "hrAnnounceInstruction2a",
    hrAnnounceInstruction2b = "hrAnnounceInstruction2b",
    hrAnnounceInstruction2c = "hrAnnounceInstruction2c",
    hrAnnounceInstruction3a = "hrAnnounceInstruction3a",
    hrAnnounceInstruction3b = "hrAnnounceInstruction3b",
    hrBigSix = "hrBigSix",
    hrBonus = "hrBonus",
    hrBonusRollup = "hrBonusRollup",
    hrBonusRollupTerm = "hrBonusRollupTerm",
    hrBonusRollup_lewisshortened = "hrBonusRollup_lewisshortened",
    hrBoxCars = "hrBoxCars",
    hrCrowdCheer01 = "hrCrowdCheer01",
    hrCrowdCheer02 = "hrCrowdCheer02",
    hrCrowdCheer03 = "hrCrowdCheer03",
    hrCrowdCheer04 = "hrCrowdCheer04",
    hrCrowdCheer05 = "hrCrowdCheer05",
    hrCrowdCheer06 = "hrCrowdCheer06",
    hrCrowdCheer07 = "hrCrowdCheer07",
    hrCrowdCheer08 = "hrCrowdCheer08",
    hrCrowdCheer09 = "hrCrowdCheer09",
    hrCrowdCheer10 = "hrCrowdCheer10",
    hrCrowdCheer11 = "hrCrowdCheer11",
    hrCrowdCheer12 = "hrCrowdCheer12",
    hrCrowdCheer13 = "hrCrowdCheer13",
    hrCrowdCheer14 = "hrCrowdCheer14",
    hrCrowdCheer15 = "hrCrowdCheer15",
    hrDiceHitEachOtherA = "hrDiceHitEachOtherA",
    hrDiceHitEachOtherB = "hrDiceHitEachOtherB",
    hrDiceHitEachOtherC = "hrDiceHitEachOtherC",
    hrDiceHitWallA = "hrDiceHitWallA",
    hrDiceHitWallB = "hrDiceHitWallB",
    hrDiceHitWallC = "hrDiceHitWallC",
    hrDiceSlide = "hrDiceSlide",
    hrDiceTumbleA = "hrDiceTumbleA",
    hrDiceTumbleB = "hrDiceTumbleB",
    hrDiceTumbleC = "hrDiceTumbleC",
    hrDiceTumbleD = "hrDiceTumbleD",
    hrDiceTumbleE = "hrDiceTumbleE",
    hrDiceTumbleF = "hrDiceTumbleF",
    hrDoubleDeuce = "hrDoubleDeuce",
    hrEasyEight = "hrEasyEight",
    hrEasyFour = "hrEasyFour",
    hrEasySix = "hrEasySix",
    hrEasyTen = "hrEasyTen",
    hrEighterFromDecatur = "hrEighterFromDecatur",
    hrEleven = "hrEleven",
    hrFive = "hrFive",
    hrgdGoldDiceSFX = "hrgdGoldDiceSFX",
    hrHardEight = "hrHardEight",
    hrHardFour = "hrHardFour",
    hrHardSix = "hrHardSix",
    hrHardTen = "hrHardTen",
    hrMusic = "hrMusic",
    hrNine = "hrNine",
    hrRoute66 = "hrRoute66",
    hrSeven = "hrSeven",
    hrSevenOut = "hrSevenOut",
    hrSnakeEyes = "hrSnakeEyes",
    hrSummaryTone04 = "hrSummaryTone04",
    HRS_Increment = "HRS_Increment",
    hrTransition = "hrTransition",
    hrTwo = "hrTwo",
    htr_bet_value = "htr_bet_value",
    htr_bet_value_decreases = "htr_bet_value_decreases",
    htr_extra_spin_click = "htr_extra_spin_click",
    htr_extra_spin_click2 = "htr_extra_spin_click2",
    htr_free_spin_added = "htr_free_spin_added",
    htr_free_spin_whoosh = "htr_free_spin_whoosh",
    htr_game_over_no_results = "htr_game_over_no_results",
    htr_launch_game = "htr_launch_game",
    htr_level_up_cashPrize = "htr_level_up_cashPrize",
    htr_level_up_featurePrize = "htr_level_up_featurePrize",
    htr_level_up_noPrize = "htr_level_up_noPrize",
    htr_null_click = "htr_null_click",
    htr_Prize_countup = "htr_Prize_countup",
    htr_reel_drop_superWild = "htr_reel_drop_superWild",
    htr_reel_drop_Wild = "htr_reel_drop_Wild",
    htr_results_big = "htr_results_big",
    htr_results_full_house = "htr_results_full_house",
    htr_results_normal = "htr_results_normal",
    htr_results_no_win = "htr_results_no_win",
    htr_results_small_win = "htr_results_small_win",
    htr_slingo_1 = "htr_slingo_1",
    htr_slingo_2 = "htr_slingo_2",
    htr_slingo_3 = "htr_slingo_3",
    htr_slingo_4 = "htr_slingo_4",
    htr_spin_click = "htr_spin_click",
    htr_spin_loop = "htr_spin_loop",
    htr_vanish_number = "htr_vanish_number",
    htr_vanish_wild = "htr_vanish_wild",
    htr_win_big = "htr_win_big",
    htr_win_full_house = "htr_win_full_house",
    htr_you_could_win_text = "htr_you_could_win_text",
    ReelStop = "ReelStop",
    Rollup01 = "Rollup01",
    RollupTerm01 = "RollupTerm01",
    SevenEndAww = "SevenEndAww",
    SlingoTheme_Level1 = "SlingoTheme_Level1",
    SlingoTheme_Level2 = "SlingoTheme_Level2",
    SlingoTheme_Level3 = "SlingoTheme_Level3",
    trt_diceroll_1 = "trt_diceroll_1",
    trt_diceroll_2 = "trt_diceroll_2",
    trt_diceroll_3 = "trt_diceroll_3",
    trt_diceroll_4 = "trt_diceroll_4",
    trt_hotroll_1 = "trt_hotroll_1",
    trt_hotroll_2 = "trt_hotroll_2",
    trt_hotroll_3 = "trt_hotroll_3"
}
