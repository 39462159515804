export enum HotRollSoundEvents {

    bonus_running_total_tickup_start = "bonus_running_total_tickup_start",
    bonus_running_total_tickup_end = "bonus_running_total_tickup_end",

    fullhouse = "htr_win_full_house",

    free_spin_spark = "hr_free_spin_spark",
    free_spin_increment = "hr_free_spin_increment",
    bonus_start = "hr_bonus_start",
    bonus_end = "hr_bonus_end",
    // Bonus 1-6
    bonus_win_N = "hr_bonus_win_{0}",
    fire_transition = "fire_transition",
    bonus_intro_voice = "bonus_intro_voice",

    dice_hit_dice_a = "hrDiceHitEachOtherA",
    dice_hit_dice_b = "hrDiceHitEachOtherB",
    dice_hit_dice_c = "hrDiceHitEachOtherC",

    dice_hit_wall_a = "hrDiceHitWallA",
    dice_hit_wall_b = "hrDiceHitWallB",
    dice_hit_wall_c = "hrDiceHitWallC",

    dice_tumble_a = "hrDiceTumbleA",
    dice_tumble_b = "hrDiceTumbleB",
    dice_tumble_c = "hrDiceTumbleC",
    dice_tumble_d = "hrDiceTumbleD",
    dice_tumble_e = "hrDiceTumbleE",
    dice_tumble_f = "hrDiceTumbleF",
    dice_tumble_stop = "dice_tumble_stop",

    dice_slide = "hrDiceSlide",

    dice_award_2 = "hr02xAward",
    dice_award_3 = "hr03xAward",
    dice_award_4 = "hr04xAward",
    dice_award_5 = "hr05xAward",
    dice_award_6 = "hr06xAward",
    dice_award_7 = "hr07xAward",
    dice_award_8 = "hr08xAward",
    dice_award_9 = "hr09xAward",
    dice_award_10 = "hr10xAward",
    dice_award_11 = "hr11xAward",
    dice_award_12 = "hr12xAward",
    dice_award_13 = "hr13xAward",
    dice_award_14 = "hr14xAward",
    dice_award_15 = "hr15xAward",
    dice_award_16 = "hr16xAward",

    dice_instruction_1 = "hrAnnounceInstruction1a",
    dice_instruction_2a = "hrAnnounceInstruction2a",
    dice_instruction_2b = "hrAnnounceInstruction2b",
    dice_instruction_2c = "hrAnnounceInstruction2c",
    dice_instruction_3a = "hrAnnounceInstruction3a",
    dice_instruction_3b = "hrAnnounceInstruction3b",
    stop_instructions = "stop_instructions",

    dice_7_aww = "SevenEndAww",

    dice_snakeeyes = "hrSnakeEyes",
    dice_two = "hrTwo",
    dice_aces = "hrAces",
    dice_acedeuce = "hrAceDeuce",
    dice_four_easy = "hrEasyFour",
    dice_four_hard = "hrHardFour",
    dice_doubledeuce = "hrDoubleDeuce",
    dice_five = "hrFive",
    dice_bigsix = "hrBigSix",
    dice_six_easy = "hrEasySix",
    dice_six_hard = "hrHardSix",
    dice_seven = "hrSeven",
    dice_sevenout = "hrSevenOut",
    dice_eight_easy = "hrEasyEight",
    dice_eight = "hrEighterFromDecatur",
    dice_eight_hard = "hrHardEight",
    dice_nine = "hrNine",
    dice_ten_hard = "hrHardTen",
    dice_ten_easy = "hrEasyTen",
    dice_eleven = "hrEleven",
    dice_boxcars = "hrBoxCars",
    dice_route66 = "hrRoute66",

    dice_transform = "hrgdGoldDiceSFX",

    cheer1 = "hrCrowdCheer01",
    cheer2 = "hrCrowdCheer02",
    cheer3 = "hrCrowdCheer03",
    cheer4 = "hrCrowdCheer04",
    cheer5 = "hrCrowdCheer05",
    cheer6 = "hrCrowdCheer06",
    cheer7 = "hrCrowdCheer07",
    cheer8 = "hrCrowdCheer08",
    cheer9 = "hrCrowdCheer09",
    cheer10 = "hrCrowdCheer10",
    cheer11 = "hrCrowdCheer11",
    cheer12 = "hrCrowdCheer12",
    cheer13 = "hrCrowdCheer13",
    cheer14 = "hrCrowdCheer14",
    cheer15 = "hrCrowdCheer15",

    paytable_tickup = "paytable_tickup",
    paytable_tickup_complete = "paytable_tickup_complete",

    winSummaryTone = "hrSummaryTone04"
}
