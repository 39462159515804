import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Layers } from "appworks/graphics/layers/layers";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Text } from "appworks/graphics/pixi/text";
import { LoaderService } from "appworks/loader/loader-service";
import { builderManager } from "appworks/server/builders/builder-manager";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { FadeTransition } from "appworks/transitions/fade-transition";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIFlag } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { HotRollBackgroundComponent } from "components/hotroll-background-component";
import { HotRollBackgroundFireComponent } from "components/hotroll-background-fire-component";
import { HotRollBonusMultipleChoicePickComponent } from "components/hotroll-bonus-multiple-choice-pick-component";
import { HotRollDiceComponent } from "components/hotroll-dice-component";
import { HotRollDicePaytableComponent } from "components/hotroll-dice-paytable-component";
import { HotRollDiceRollCounterComponent } from "components/hotroll-dice-roll-counter-component";
import { HotRollFooterComponent } from "components/hotroll-footer-component";
import { HotRollFreespinTrailComponent } from "components/hotroll-freespin-fire-trail-component";
import { HotRollReelMatrixFactory } from "components/hotroll-reel-matrix-factory";
import { gameLayers, LayerNames } from "game-layers";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { GMRPreloaderComponent } from "gaming-realms/components/gmr-preloader-component";
import { GMRForceRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-force-request-builder";
import { GMRInitRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-init-request-builder";
import { HotRollPickRecordBuilder } from "integration/builders/records/hotroll-pick-record-builder";
import { HotRollSpinRecordBuilder } from "integration/builders/records/hotroll-spin-record-builder";
import { HotRollCollectRequestBuilder } from "integration/builders/requests/hotroll-collect-request-builder";
import { HotRollBonusDiceRollResultBuilder } from "integration/builders/results/hotroll-bonus-dice-roll-result-builder";
import { SlingoCelebrationComponent } from "slingo/components/slingo-celebration-component";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoReelHighlightComponent } from "slingo/components/slingo-reel-highlight-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoGameProgressResultDisplay } from "slingo/debug/display/results/slingo-game-progress-result-display";
import { SlingoCollectRecordBuilder } from "slingo/integration/builders/records/slingo-collect-record-builder";
import { SlingoInitRecordBuilder } from "slingo/integration/builders/records/slingo-init-record-builder";
import { SlingoPurchaseEntryRecordBuilder } from "slingo/integration/builders/records/slingo-purchase-entry-record-builder";
import { SlingoPickRequestBuilder } from "slingo/integration/builders/requests/slingo-pick-request-builder";
import { SlingoPurchaseEntryRequestBuilder } from "slingo/integration/builders/requests/slingo-purchase-entry-request-builder";
import { SlingoSpinRequestBuilder } from "slingo/integration/builders/requests/slingo-spin-request-builder";
import { SlingoGameProgressResultBuilder } from "slingo/integration/builders/results/slingo-game-progress-result-builder";
import { SlingoJokerResultBuilder } from "slingo/integration/builders/results/slingo-joker-result-builder";
import { SlingoLadderResultBuilder } from "slingo/integration/builders/results/slingo-ladder-result-builder";
import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { SlingoSetup } from "slingo/setup/slingo-setup";
import { BetBarComponent } from "slotworks/components/betbar/bet-bar-component";
import { CountMode } from "slotworks/components/bigwin/big-win-component";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { BigWinFactory } from "slotworks/components/factories/big-win-factory";
import { GenericComponentFactory } from "slotworks/components/factories/generic-component-factory";
import { IntegrationDebugger } from "slotworks/debug/integration-debugger/integration-debugger";
import { HotRollBigWinValueTransition } from "transitions/hotroll-big-win-value-transition";
import { HotRollDiceTransformTransition } from "transitions/hotroll-dice-transform-transition";
import { HotRollDiceWinValueTransition } from "transitions/hotroll-dice-win-value-transition";
import { HotRollFlameScreenTransition } from "transitions/hotroll-flame-screen-transition";
import { applyWhiteBlackTextStroke } from "util/hr-text-style";
import { HotRollGameDefinitionSetup } from "./hotroll-game-definition-setup";
import { HotRollSoundSetup } from "./hotroll-sound-setup";
import { HotRollStateSetup } from "./hotroll-state-setup";
import { SocialTicketMatrixComponent } from "appworks/state-machine/social/components/social-ticket-matrix-component";
import { SocialGMRAlertComponent } from "appworks/state-machine/social/components/social-gmr-alert-component";

export class HotRollSetup extends SlingoSetup {
    public setupCanvas(): void {
        super.setupCanvas();
        Services.get(CanvasService).cssCanvasEnabled = true;

        Text.minPadding = 5;
    }

    public setupI18N(): void {
        super.setupI18N();

        const translations = Services.get(TranslationsService);
        for (let i = 0; i <= 12; i++) {
            const text = `${i} ${translations.get((i === 1) ? "slingo" : "slingos")}`;
            translations.set(`${i}_slingo${i === 1 ? "" : "s"}`, text);
        }

        translations.set("roll_dice_caps", translations.get("roll_dice").toLocaleUpperCase());
        translations.set("total_bonus_caps", translations.get("total_bonus").toLocaleUpperCase());
        translations.set("start_caps", translations.get("start").toLocaleUpperCase());
    }

    public setupGameDefinition(): void {
        HotRollGameDefinitionSetup();
    }

    public setupComponents() {
        SpineContainer.AUTO_UPDATE_SPEED = true;

        Components.register(new GenericComponentFactory(PromptComponent), 0);
        Components.register(new GenericComponentFactory(BetBarComponent));
        Components.register(new GenericComponentFactory(CelebrateWinComponent));
        Components.register(new BigWinFactory(CountMode.LINEAR, false, [
            {
                threshold: 10,
                countTime: 7000,
                waitTime: 3000,
                scene: "bigWin"
            }
        ]));

        //Components.register(new GenericComponentFactory(GMRPreloaderComponent), null);
        Components.register(new GenericComponentFactory(SocialGMRAlertComponent), 0);

        Components.register(new HotRollReelMatrixFactory(), 3);

        Components.register(new GenericComponentFactory(HotRollFooterComponent));
        Components.register(new GenericComponentFactory(SlingoCelebrationComponent, LayerNames.CelebrationContent));
        Components.register(new GenericComponentFactory(SlingoSpinsCounterComponent, LayerNames.SpinCounter));
        Components.register(new GenericComponentFactory(SlingoLadderComponent));
        Components.register(new GenericComponentFactory(SlingoReelHighlightComponent));

        Components.register(new GenericComponentFactory(SocialTicketMatrixComponent), 3);
        Components.register(new GenericComponentFactory(HotRollBackgroundFireComponent));
        Components.register(new GenericComponentFactory(HotRollDiceComponent), 3);
        Components.register(new GenericComponentFactory(HotRollDiceRollCounterComponent));
        Components.register(new GenericComponentFactory(HotRollDicePaytableComponent));
        Components.register(new GenericComponentFactory(HotRollBonusMultipleChoicePickComponent));
        Components.register(new GenericComponentFactory(HotRollBackgroundComponent), 0);
        Components.register(new GenericComponentFactory(HotRollFreespinTrailComponent));
    }

    public setupServices(): void {
        super.setupServices();

        Services.get(LoaderService).loadSoundsInPreloader = true;
    }

    public setupStates() {
        HotRollStateSetup();
    }

    public setupDebug(): void {
        super.setupDebug();
        IntegrationDebugger.addResultDisplayFunction("HotRollBonusDiceRollResult", SlingoGameProgressResultDisplay);
    }

    public setupLayers(): void {
        Layers.setLayerOrderConfig({
            default: [
                LayerNames.MultiplierSequence,
                LayerNames.GoldBorder,
                LayerNames.Background,
                LayerNames.BackgroundMultipliers,
                LayerNames.Onboard,
                LayerNames.MatrixBackground,
                LayerNames.MatrixContent,
                LayerNames.TicketMatrixBacking,
                LayerNames.TicketMatrix,
                LayerNames.DicePaytable,
                LayerNames.Logo,
                LayerNames.BetBar,
                LayerNames.MenuButton,
                LayerNames.SymbolAnimations,
                LayerNames.SpinButton,
                LayerNames.SpinCounter,
                LayerNames.Footer,
                LayerNames.Collect,
                LayerNames.SlingoLadder,
                LayerNames.DiceRollCounter,
                LayerNames.Dice,
                LayerNames.DiceTransform,
                LayerNames.CelebrationBackground,
                LayerNames.BackgroundFire,
                LayerNames.CelebrationContent,
                LayerNames.CelebrationForeground,
                LayerNames.BonusChoice,
                LayerNames.Prompts,
                LayerNames.Menus,
                LayerNames.ScreenFade
            ],
            bonus: [
                LayerNames.BackgroundMultipliers,
                LayerNames.Onboard,
                LayerNames.MatrixBackground,
                LayerNames.MatrixContent,
                LayerNames.TicketMatrixBacking,
                LayerNames.TicketMatrix,
                LayerNames.MenuButton,
                LayerNames.SymbolAnimations,
                LayerNames.SpinButton,
                LayerNames.SpinCounter,
                LayerNames.Collect,
                LayerNames.SlingoLadder,
                LayerNames.Background,
                LayerNames.BackgroundFire,
                LayerNames.GoldBorder,
                LayerNames.DicePaytable,
                LayerNames.BetBar,
                LayerNames.Footer,
                LayerNames.DiceRollCounter,
                LayerNames.Logo,
                LayerNames.Dice,
                LayerNames.MultiplierSequence,
                LayerNames.DiceTransform,
                LayerNames.CelebrationBackground,
                LayerNames.CelebrationContent,
                LayerNames.CelebrationForeground,
                LayerNames.BonusChoice,
                LayerNames.Prompts,
                LayerNames.Menus,
                LayerNames.ScreenFade
            ]
        }, LayerNames);

        Layers.get("Prompts").onSceneEnter.add(() => {
            const cover = Layers.get("Prompts").getShape("cover");
            if (cover) {
                Layers.get("Prompts").add(cover, false, true);
            }
        });
    }

    public setupComms(): void {
        super.setupComms();

        builderManager.addRequestBuilder(new GMRForceRequestBuilder());
        builderManager.addRequestBuilder(new GMRInitRequestBuilder());
        builderManager.addRequestBuilder(new SlingoPurchaseEntryRequestBuilder());
        builderManager.addRequestBuilder(new SlingoSpinRequestBuilder());
        builderManager.addRequestBuilder(new SlingoPickRequestBuilder());
        builderManager.addRequestBuilder(new HotRollCollectRequestBuilder());

        builderManager.addRecordBuilder(new SlingoInitRecordBuilder());
        builderManager.addRecordBuilder(new SlingoPurchaseEntryRecordBuilder());
        builderManager.addRecordBuilder(new HotRollSpinRecordBuilder());
        builderManager.addRecordBuilder(new HotRollPickRecordBuilder());
        builderManager.addRecordBuilder(new SlingoCollectRecordBuilder());

        builderManager.addResultBuilder(new SlingoReelSpinResultBuilder());
        builderManager.addResultBuilder(new SlingoJokerResultBuilder());
        builderManager.addResultBuilder(new SlingoLadderResultBuilder());
        builderManager.addResultBuilder(new SlingoGameProgressResultBuilder());
        builderManager.addResultBuilder(new HotRollBonusDiceRollResultBuilder());
    }

    public setupTransitions(): void {
        gameLayers.ScreenFade.setTransition(new FadeTransition(gameLayers.ScreenFade, 500));
        gameLayers.ScreenFade.setTransitions("flame_transition", new HotRollFlameScreenTransition(gameLayers.ScreenFade));

        gameLayers.DiceTransform.setTransitions("transform", new HotRollDiceTransformTransition(gameLayers.DiceTransform));

        gameLayers.CelebrationContent.setTransitions("dicewin", new HotRollDiceWinValueTransition(gameLayers.CelebrationContent));
        gameLayers.CelebrationContent.setTransitions("bigwin", new HotRollBigWinValueTransition(gameLayers.CelebrationContent));
        gameLayers.CelebrationContent.setTransitions("fullhouse", new HotRollBigWinValueTransition(gameLayers.CelebrationContent));

        gameLayers.CelebrationForeground.setTransition(new FadeTransition(gameLayers.CelebrationForeground, 250));

        gameLayers.MultiplierSequence.setTransition(new FadeTransition(gameLayers.MultiplierSequence, 250));

        gameLayers.GoldBorder.setTransition(new FadeTransition(gameLayers.GoldBorder, 500));

        gameLayers.Dice.setTransition(new FadeTransition(gameLayers.Dice, 250));
    }

    public setupUI(): void {
        UI.layer(Layers.get("BetBar"), [
            {
                scene: "bonus",
                show: new UIFlagState(UIFlag.BONUS),
                enable: UIFlagState.ALWAYS
            }
        ]);

        super.setupUI();
    }

    public onReady() {
        // Special text effects on prompt texts
        const promptsTexts = [
            "title",
            "body",
            "potential_win_body",
            "total_win"
        ];

        const promptsButtons = [
            "continue",
            "continuenooptions",
            "history",
            "logout",
            "yes",
            "no",
            "ok",
            "tc"
        ];

        const promptsLayers = [
            gameLayers.Prompts,
            gameLayers.Collect
        ];

        promptsLayers.forEach((layer) => {
            layer.onSceneEnter.add(() => {
                for (const text of promptsTexts) {
                    const textField = layer.getText(text);
                    if (textField) {
                        applyWhiteBlackTextStroke(textField);
                    }
                }

                for (const btnName of promptsButtons) {
                    const btn = layer.getButton(btnName);
                    if (btn) {
                        btn.getLabels().forEach((lbl) => {
                            if (lbl instanceof Text) {
                                applyWhiteBlackTextStroke(lbl);
                            }
                        });
                    }
                }
            });
        });

        super.onReady();
    }

    public setupSound(): void {
        super.setupSound();
        HotRollSoundSetup();
    }
}
