import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HotRollFreespinTrailComponent } from "components/hotroll-freespin-fire-trail-component";
import { HotRollTicketMatrixComponent } from "components/hotroll-ticket-matrix-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoSpinState } from "slingo/states/slingo-spin-state";

export class HotRollSpinState extends SlingoSpinState {
    protected updateSpinCounter(): Contract<void> {
        const fsIndexes: number[] = [];

        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoReelSpinResult);
        result.symbols.forEach((symbol: string, index: number) => {
            if (symbol === "FS") { fsIndexes.push(index); }
        });

        const contracts: Array<() => Contract> = [];

        fsIndexes.forEach((reelIndex: number, i: number) => {
            contracts.push(() => Contract.getDelayedContract(i * 250, () => new Sequence([
                () => Components.get(HotRollFreespinTrailComponent).fireSpark(reelIndex),
                () => Components.get(SlingoSpinsCounterComponent).increment(),
                () => Contract.getTimeoutContract(500)
            ])));
        });

        return new Sequence([
            () => new Parallel(contracts),
            () => super.updateSpinCounter()
        ]);
    }

    protected onReelLand(reelIndex: number, match?: { matchedValue: number; reelIndex: number; }): void {
        super.onReelLand(reelIndex, match);

        if (match) {
            Components.get(HotRollTicketMatrixComponent).setNumberColour(match.matchedValue, "orange");
        }
    }
}
