import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { gameLayers } from "game-layers";
import { HotRollBackgroundFireComponent } from "./hotroll-background-fire-component";

export class HotRollDiceRollCounterComponent extends AbstractComponent {
    protected layer = gameLayers.DiceRollCounter;

    protected value: number;

    protected numbers: Sprite[] = [];
    protected suffix: Sprite;

    public show(): Contract {
        return new Parallel([
            () => this.layer.setScene("bonus"),
            () => Contract.wrap(() => this.setValue(1))
        ]);
    }

    public hide(): Contract {
        this.numbers = [];
        this.suffix = null;

        return this.layer.defaultScene();
    }

    public getValue() { return this.value; }

    public setValue(value: number) {
        this.value = value;

        this.showCorrectSuffix();

        this.numbers.forEach((sprite: Sprite) => { sprite.destroy(); });
        this.numbers = [];

        const chars = value.toString().split("");

        while (chars.length) {
            const num = chars.pop();
            const pos = this.layer.getPosition(`${this.numbers.length}`);

            let sprite: Sprite;
            if (value >= 10) {
                sprite = Services.get(GraphicsService).createAnimation(`Hot_Font_Fire_${num}`);
                (sprite as AnimatedSprite).play();
            } else {
                sprite = Services.get(GraphicsService).createSprite(`NoFireFont/${num}`);
            }

            sprite.setDualPosition(pos);

            this.layer.add(sprite);
            this.numbers.push(sprite);
        }

        Components.get(HotRollBackgroundFireComponent).setLevelFromRollCount(value);
    }

    protected showCorrectSuffix() {
        if (this.suffix) { this.suffix.destroy(); }

        let suffixString = "th";

        switch (this.value % 10) {
            case 1:
                suffixString = "st";
                break;
            case 2:
                suffixString = "nd";
                break;
            case 3:
                suffixString = "rd";
                break;
        }

        if ([11, 12, 13].indexOf(this.value % 100) !== -1) { // special cases
            suffixString = "th";
        }

        if (this.value >= 10) {
            this.suffix = Services.get(GraphicsService).createAnimation(`Hot_Font_Fire_${suffixString}`);
            (this.suffix as AnimatedSprite).play();
        } else {
            this.suffix = Services.get(GraphicsService).createSprite(`NoFireFont/${suffixString}`);
        }

        this.layer.add(this.suffix);
        this.suffix.setDualPosition(this.layer.getPosition("suffix"));
    }
}
