import { ComponentFactory } from "appworks/components/factories/component-factory";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { Graphics, TextStyle } from "pixi.js";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { ReelSpinner, SpinStage, SpinStageEvents, StageSet } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { SpinReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/spin/spin-reel-transition";
import { slotDefinition } from "slotworks/model/slot-definition";

export class SlingoReelMatrixFactory implements ComponentFactory {
    protected defaultStops: number[];
    protected defaultReelset: string;
    protected spinSpeed: number = 0.025;
    protected spinStages: SpinStage[] = [];
    protected quickSpinStages: SpinStage[] = [];
    protected skipStages: SpinStage[] = [];
    protected anticipationStages: SpinStage[] = [];
    protected shouldGenerateNumberTextures: boolean;
    protected numberStyle: Partial<TextStyle>;

    constructor(shouldGenerateNumberTextures = false, numberStyle: Partial<TextStyle> = {}) {
        this.shouldGenerateNumberTextures = shouldGenerateNumberTextures;
        this.numberStyle = numberStyle;

        const minStopDistance = this.calculateMinStopDistance();

        this.defaultReelset = "slingo";
        this.defaultStops = new Array(slotDefinition.matrixGrid.length).fill(0);

        // Min spin time will be handled outside of reel spinner for slingo,
        // because it causes delays on transition start which causes "hangs" at weird moments
        ReelSpinner.disableMinSpinTime = true;

        this.spinStages = [
            {
                name: "accelerate",
                s: -1,
                u: 0,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 50
                    }
                ]
            },
            {
                name: "minRepeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed
            },
            {
                name: "repeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 0
                    }
                ]
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 250
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "overshoot",
                s: -0.35,
                u: -0.01,
                v: 0
            },
            {
                name: "complete",
                s: 0.35,
                t: 220,
                easing: "Quadratic.Out",
                events: [
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 100
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 200
                    }
                ]
            }
        ];

        this.skipStages = [
            {
                name: "stop",
                s: -minStopDistance,
                t: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 100
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "complete",
                s: 0,
                t: 0
            }
        ];

        this.anticipationStages = [
            {
                name: "anticipate",
                s: -10,
                u: -this.spinSpeed,
                v: -this.spinSpeed * 0.5
            },
            {
                name: "tease",
                s: -10,
                u: -this.spinSpeed * 0.5,
                v: -this.spinSpeed * 0.5
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed * 0.5,
                v: 0.0
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "complete",
                s: 0,
                t: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 100
                    },
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 200
                    }
                ]
            }
        ];
    }

    public build() {
        if (this.shouldGenerateNumberTextures) {
            this.generateNumberTextures();
        }

        const matrix = this.createMatrix();
        this.addSymbolBehaviours(matrix);
        this.createTransition(matrix);
        return matrix;
    }

    protected createMatrix(): AbstractMatrixComponent {
        return new MatrixComponent(
            slotDefinition.matrixGrid,
            this.defaultStops,
            slotDefinition.reelsets.get(this.defaultReelset)
        );
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent) { }

    protected createTransition(matrix: AbstractMatrixComponent) {
        ReelSpinner.addStageSet({
            id: "default",
            spin: [this.spinStages],
            skip: [this.skipStages],
            anticipate: [this.anticipationStages],
            quickSpin: [this.quickSpinStages]
        });

        ReelSpinner.setStageSet("default");

        matrix.setTransition(new SpinReelTransition());
    }

    protected calculateMinStopDistance(): number {
        return slotDefinition.matrixGrid.reduce((max, current) => current > max ? current : max, 0) + 1;
    }

    protected generateNumberTextures() {
        for (let i = 1; i <= 75; i++) {
            const text = (window as any).t = new Text(i.toString(), {
                fill: "#fff",
                align: "center",
                wordWrap: true,
                ...this.numberStyle
            });
            text.landscape.fontSize = 100;
            text.portrait.fontSize = 100;
            text.landscape.width = 150;
            text.landscape.height = 150;
            text.updateText();
    
            const gfx = new Graphics();
            gfx.drawRect(0, 0, 150, 150);
            gfx.addChild(text);
    
            Services.get(CanvasService).stage.addChild(gfx);
            const texture = Services.get(CanvasService).renderer.generateTexture(gfx, PIXI.SCALE_MODES.LINEAR, 1);
            texture.castToBaseTexture();
            Services.get(GraphicsService).addTexture(`numbers/${i}`, texture);

            Services.get(CanvasService).stage.removeChild(gfx);
            gfx.destroy();
        }
    }
}
