import { gameLoop } from "appworks/core/game-loop";
import { ci, StateEvent } from "appworks/debug/ci";
import { SentryService } from "appworks/server/analytics/sentry-service";
import { Services } from "appworks/services/services";
import { Exit, GetExitFromString } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { State } from "appworks/state-machine/states/state";
import { SuperState } from "appworks/state-machine/states/superstate";
import { Timer } from "appworks/utils/timer";
import * as Logger from "js-logger";

export interface StateNode extends NodeConfig {
    state: State;
}

export interface DecisionNode extends NodeConfig {
    decision: Decision;
}

export interface SuperStateNode extends NodeConfig {
    superState: SuperState;
    entrySubState?: State | Decision;
}

export interface NodeConfig {
    links: { [link: string]: string | Exit | State | Decision | SuperState };
    entryPoint?: boolean;
    debugId?: string;
    turboSkip?: boolean;
    /**
     * When enabled, will skip through the states until a state with cascadeSkip: false is found
     * @default false
     */
    cascadeSkip?: boolean;
    /**
     * When enabled, will increase the game speed during skipping
     * @default false
     */
    speedSkip?: boolean;
    /**
     * The amount of time in milliseconds before speed skipping can trigger
     * @default 250
     */
    allowSkipDelay?: number;
    /**
     * How much speed skipping should speed the game up by
     * @default 2
     */
    skipSpeed?: number;
}

export const defaultNodeConfig: Partial<NodeConfig> = {
    turboSkip: false,
    cascadeSkip: false,
    speedSkip: false,
    allowSkipDelay: 250,
    skipSpeed: 2
};

export class StateMachine {

    private static INFINITE_DECISION_THRESHOLD: number = 10;

    public entryState: Node;
    public turbo: boolean = false;

    public superState: CurrentSuperState = null;
    public currentState: CurrentState = null;
    public pendingSuperStateExit: string | Exit.Collapse;

    private nodeConfigNodes: Map<NodeConfig, Node> = new Map();
    private nodes: Node[] = [];

    // Starts off the state machine in the default state
    public start() {
        this.generateLinks();

        if (!this.entryState) {
            throw new Error("No entry point in state machine");
        }

        this.setCurrentState(this.entryState);
    }

    public addStates(states: StateNode[]) {
        this.addNodes(states);
    }

    public addSuperStates(states: SuperStateNode[]) {
        this.addNodes(states);
    }

    public addDecisions(decisions: DecisionNode[]) {
        this.addNodes(decisions);
    }

    /**
     * Sets the current state. Usuaully either on init or by tests
     *
     * @param node {Node}
     */
    public setCurrentState(node: Node) {
        this.currentState = new CurrentState(node, node.concrete as State);

        this.enterState(this.currentState.node);
    }

    /**
     * Sets the current super state. Usuaully either on init or by tests
     *
     * @param node {Node}
     */
    public setSuperState(node: Node) {
        this.superState = new CurrentSuperState(node, node.concrete as SuperState);
    }

    /**
     * Handles requests to change state coming from the active state or super state
     * Super states will intercept requests coming from regular states while they are active
     *
     * @param exit {string | Exit}
     * @param target {State | SuperState}
     */
    public changeState(exit: string | Exit, target: State | SuperState) {
        if (this.currentState) {
            const invalidState = target !== this.currentState.concrete;
            const invalidSuperState = (!this.superState || target !== this.superState.concrete);
            if (invalidState && invalidSuperState) {
                throw new Error("StateMachine :: A non-active state is trying to change the current state! ");
            }
        }

        if (this.superState === null || target === this.superState.concrete) {
            this.exitState(exit);
        } else {
            this.interceptSubStateExit(exit);
        }
    }

    /**
     * Request that the current state skips
     */
    public skip(cascade?: boolean) {
        if (this.currentState) {
            Services.get(SentryService)?.addStateBreadcrumb(this.currentState.node.getDebugId(), "skip", false);
            this.log("[STATE] skip => " + this.currentState.node.getDebugId());

            if (this.currentState.node.allowSkip && this.currentState.node.config.speedSkip) {
                gameLoop.setSpeed(this.currentState.node.config.skipSpeed);
            }

            this.currentState.concrete.onSkip(cascade);
        }
    }

    public superStateExitConfirmed() {
        Services.get(SentryService)?.addStateBreadcrumb(this.superState.node.getDebugId(), "exit", true);
        this.log("[SUPER STATE] exit confirmed => " + this.superState.node.getDebugId());

        const superStateConcrete = this.superState.concrete;

        const nextState = this.superState.node.getLink(this.pendingSuperStateExit).node;
        this.superState = null;

        superStateConcrete.active = false;
        superStateConcrete.onExit();

        this.enterState(this.resolveDecision(nextState));
    }

    public getNodeByStateType(stateType: typeof State | typeof SuperState | typeof Decision) {
        for (const [nodeConfig, node] of this.nodeConfigNodes) {
            if (node.concrete instanceof stateType) {
                return node;
            }
        }
    }

    protected addNodes(nodes: NodeConfig[]) {
        nodes.forEach((nodeConfig) => {
            const concrete = (nodeConfig as StateNode).state || (nodeConfig as DecisionNode).decision || (nodeConfig as SuperStateNode).superState;

            const node = new Node(concrete, nodeConfig);

            if (nodeConfig.entryPoint) {
                this.entryState = node;
            }

            if (node.concrete instanceof State || node.concrete instanceof SuperState) {
                node.concrete.setup(this);
            }

            this.nodes.push(node);
            this.nodeConfigNodes.set(nodeConfig, node);
        });
    }

    /**
     * Exits the current state, resolves the next one, the enters it
     *
     * @param exit {string | Exit}
     */
    private exitState(exit: string | Exit) {
        let cascadeSkip: boolean = false;

        if (this.currentState) {
/////////////////////////////////////////////////////////////
////////////////////////////////
///////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////
////////////////////////////////////////////
/////////////
//////////////////////
            Services.get(SentryService)?.addStateBreadcrumb(this.currentState.node.getDebugId(), "exit", false);
            this.log("[STATE] exit => " + this.currentState.node.getDebugId());

            this.currentState.concrete.active = false;

            Timer.clearTimeout(this.currentState.node.delayTimer);
            gameLoop.setSpeed(1);

            this.currentState.concrete.onExit();
        }

        let nextStateNode: Node;

        // Repeats just re-enter the same state, so don't move on
        if (exit === Exit.Repeat) {
            nextStateNode = this.currentState.node;
        } else if (exit === Exit.Collapse) {
            this.exitSuperState(Exit.Collapse);
            return;
        } else {
            cascadeSkip = this.currentState.node.config.cascadeSkip && (exit === Exit.Skip);

            const nextLink = this.currentState.node.getLink(exit);

            if (nextLink.type === LINK_TYPE.Node) {
                nextStateNode = this.resolveDecision(nextLink.node, 0);
                // If there is no nextStateNode, we must be exiting a super state
                if (!nextStateNode) {
                    return;
                }
            } else {
                this.exitSuperState(nextLink.id);
                return;
            }
        }

        this.enterState(nextStateNode, cascadeSkip);

        if (this.currentState) {
            if ((this.turbo && this.currentState.node.config.turboSkip) || (this.currentState.node === nextStateNode && cascadeSkip)) {
                this.skip(true);
            }
        }
    }

    /**
     * Notify the super state when a sub state exits, the super state then may or may not bubble this event up to the state machine
     *
     * @param exit {string | Exit}
     */
    private interceptSubStateExit(exit: string | Exit) {
        Services.get(SentryService)?.addStateBreadcrumb(this.superState.node.getDebugId(), `intercepting:${exit}`, true);
        this.log("[SUPER STATE] intercepting => " + exit);

        const superStateConcrete = this.superState.concrete;

        switch (exit) {
            case Exit.Complete:
                superStateConcrete.onSubStateComplete();
                break;
            case Exit.Skip:
                superStateConcrete.onSubStateSkip();
                break;
            case Exit.Error:
                superStateConcrete.onSubStateError();
                break;
            default:
                break;
        }
    }

    /**
     * Exits the current super state, making super state null and links to the
     * next state based on the exit id
     *
     * @param exitId {string}
     */
    private exitSuperState(exitId: string | Exit.Collapse) {
        if (!this.superState) {
            throw new Error("Missing state node link > " + exitId);
        }

        this.log("[SUPER STATE] request exit => " + this.superState.node.getDebugId());

        const superStateConcrete = this.superState.concrete;

        this.pendingSuperStateExit = exitId;

        superStateConcrete.onSuperStateExit();
    }

    /**
     * Enters the concrete class of current state node.
     * If the current state is a super state, it's sub state entry is also entered
     */
    private enterState(nextStateNode: Node, cascadeSkip: boolean = false) {
        if (nextStateNode.concrete instanceof SuperState) {
            Services.get(SentryService)?.addStateBreadcrumb(nextStateNode.getDebugId(), "enter", true);
            this.log("[SUPER STATE] enter => " + nextStateNode.getDebugId());

            this.superState = new CurrentSuperState(nextStateNode, nextStateNode.concrete);
            this.superState.concrete.active = true;

            this.currentState = null;
            this.superState.concrete.onEnter(cascadeSkip);

            const subState = this.resolveDecision(this.superState.node.entrySubState);
            if (!subState) {
                // A super state has been exited immediately, no sub state, wait for exit approval
                return;
            }
            this.currentState = new CurrentState(subState, subState.concrete as State);
        } else {
            this.currentState = new CurrentState(nextStateNode, nextStateNode.concrete as State);
        }

        if (this.superState) {
            this.superState.concrete.subState = this.currentState.concrete;
        }

/////////////////////////////////////////////////////////
////////////////////////////
///////////////////////////////////////////////////////
//////////////////////////////////
//////////////////////////////////////////////////////////////////////////
////////////////////////////////////////
/////////
//////////////////
        Services.get(SentryService)?.addStateBreadcrumb(this.currentState.node.getDebugId(), "enter", false);
        this.log("[STATE] enter => " + this.currentState.node.getDebugId());
        this.currentState.concrete.active = true;

        this.currentState.node.allowSkip = false;
        this.currentState.node.delayTimer = Timer.setTimeout(() => {
            this.currentState.node.allowSkip = true;
        }, this.currentState.node.config.allowSkipDelay);

        this.currentState.concrete.onEnter(cascadeSkip);
    }

    /**
     * Starting from a node, recursively checks decisions and follows their links until a state (or super state) is found
     * Will throw an exception if an infinite loop is encountered
     *
     * @param node {Node}
     * @param depth [number=0]
     */
    private resolveDecision(node: Node, depth: number = 0): Node {
        if (depth > StateMachine.INFINITE_DECISION_THRESHOLD) {
            throw new Error("StateMachine :: Infinite decision loop detected");
        }

        if (node.concrete instanceof Decision) {
            const decisionResult = node.concrete.evaluate();

            Services.get(SentryService)?.addDecisionBreadcrumb(node.getDebugId(), (decisionResult === Exit.True));
            this.log("[DECISION] " + node.getDebugId() + " => " + (decisionResult === Exit.True));

            const link = node.getLink(decisionResult);

            if (link.type === LINK_TYPE.Alias) {
                this.exitSuperState(link.id);
                return;
            }

            depth++;
            node = this.resolveDecision(link.node, depth);
        }

        return node;
    }

    private generateLinks() {
        // Exit links
        this.nodeConfigNodes.forEach((node, nodeConfig) => {
            for (const link in nodeConfig.links) {
                if (nodeConfig.links.hasOwnProperty(link)) {
                    const configTarget = nodeConfig.links[link];

                    let target: Node | string;

                    if ((configTarget instanceof State) || (configTarget instanceof SuperState) || (configTarget instanceof Decision)) {
                        target = this.nodes.find((searchNode) => searchNode.concrete === configTarget);
                    } else {
                        target = configTarget;
                    }

                    node.addLink(GetExitFromString(link), target);
                }
            }
        });

        // Super state entry sub states
        this.nodeConfigNodes.forEach((node, nodeConfig) => {
            if (node.concrete instanceof SuperState) {
                const configTarget = (nodeConfig as SuperStateNode).entrySubState;

                let target: Node;

                if ((configTarget instanceof State) || (configTarget instanceof SuperState) || (configTarget instanceof Decision)) {
                    target = this.nodes.find((searchNode) => searchNode.concrete === configTarget);
                } else {
                    target = configTarget;
                }

                node.entrySubState = target;
            }
        });
    }

    /**
     * Log a message with colored label
     *
     * @param message {string}
     */
    private log(message: string) {
        Logger.info("%c State Machine ", "background: #00f; color: #fff", message);
    }
}

class CurrentSuperState {
    public concrete: SuperState;
    public node: Node;

    constructor(node: Node, concrete: SuperState) {
        this.node = node;
        this.concrete = concrete;
    }
}

class CurrentState {
    public concrete: State;
    public node: Node;

    constructor(node: Node, concrete: State) {
        this.node = node;
        this.concrete = concrete;
    }
}

class Node {
    public concrete: State | Decision | SuperState;
    public config: NodeConfig;
    public entrySubState: Node;
    public allowSkip: boolean;
    public delayTimer: number;

    protected exits: Map<string | Exit, Link>;

    constructor(concrete: State | Decision | SuperState, config: NodeConfig, entrySubState?: Node) {
        this.config = Object.assign({}, defaultNodeConfig, config);

        if (concrete instanceof SuperState && !entrySubState) {
            // throw new Error("Node :: Attempted to create super state node without an entry sub state");
        }

        this.concrete = concrete;

        this.exits = new Map<string | Exit, Link>();
    }

    public getLink(exit: string | Exit): Link {

        if (!this.exits.has(exit)) {
            if (exit === Exit.Skip) {
                Logger.warn("Link out of " + this.getDebugId() + " with exit Skip not found. Falling back to Complete");
                return this.getLink(Exit.Complete);
            } else {
                throw new Error("Cannot find link out of " + this.getDebugId() + " with exit " + exit);
            }
        }

        return this.exits.get(exit);
    }

    public addLink(exit: string | Exit, target: string | Node) {
        this.exits.set(exit, new Link(target));
    }

    public getDebugId() {
        return this.config.debugId ? this.config.debugId : this.concrete.constructor.name;
    }
}

/**
 * Marks a link as one which leads to another node (state, decision or super state),
 * or a named exit of it's parent node (EG: super state custom exits)
 */
const enum LINK_TYPE { Node, Alias }

class Link {
    // Identifier
    public id: string;
    // Node this link leads to (if applicable)
    public node: Node;
    // Link to a node or an alias
    public type: LINK_TYPE;

    constructor(value: string | Node) {
        if (typeof value === "string") {
            this.type = LINK_TYPE.Alias;
            this.id = value;
        } else {
            this.type = LINK_TYPE.Node;
            this.node = value;
        }
    }
}

export const stateMachine = new StateMachine();
