import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { HotRollPaytableMode } from "components/hotroll-dice-paytable-component";
import { HotRollBonusDiceRollResult } from "model/results/hotroll-bonus-dice-roll-result";

export class HotRollBonusDiceRollResultBuilder implements ResultBuilder {
    public filter(data: any, record?: Record, additionalData?: any): boolean {
        return data.feature?.chanceGames?.length > 0;
    }

    public build(record: Record, data: any, additionalData?: any): Result[] {
        const results: HotRollBonusDiceRollResult[] = [];

        // The nesting on these "chancegames" in the response is an absolute mess,
        // so just recursively push them all into a nice flat array
        const chanceGames: ChanceGame[] = [];
        let lives = 0;
        let is7OutBonus = false;
        const flattenNestedChanceGames = (games: ChanceGame[]) => {
            games.forEach((game: ChanceGame) => {
                if (parseInt(game.params?.stop) === 7) {
                    is7OutBonus = true;
                    lives += 1;
                    game.games[game.games.length - 1].is7Out = true;

                    if (game.games[99]) {
                        game.games[99].is100thRoll = true;
                    }
                }

                if (game.games) { flattenNestedChanceGames(game.games); }
                if (game.symbols) { chanceGames.push(game); }

            });
        };
        flattenNestedChanceGames(data.feature.chanceGames);

        // Get base multiplier for the bonus
        let baseMultiplier = null;
        chanceGames.forEach((game: ChanceGame) => {
            const multiplier = game.gameConfig.multiplier ?? 1;
            if (multiplier < baseMultiplier || baseMultiplier === null) {
                baseMultiplier = multiplier;
            }
        });

        // Build results
        const startLives = lives;
        chanceGames.forEach((game: ChanceGame) => {
            const result = new HotRollBonusDiceRollResult("bonus");

            result.cashWon = game.totalWin * 100;
            result.diceValues = game.symbols.map((v: string) => parseInt(v));
            result.diceSum = result.diceValues.reduce((previous, current) => previous += current);
            result.multiplier = game.gameConfig.multiplier ?? 1;
            result.isGoldenRoll = result.multiplier > baseMultiplier;
            result.is7Out = !!game.is7Out;
            result.is100thRoll = !!game.is100thRoll;

            if (is7OutBonus && !result.isGoldenRoll) {
                result.paytableMode = (results.length > 0) ? HotRollPaytableMode.SEVENOUT : HotRollPaytableMode.SEVENOUT_FIRSTROLL;
            } else {
                result.paytableMode = HotRollPaytableMode.NORMAL;
            }

            if (startLives > 1) { // if just one life, we wouldn't show the hearts
                if (lives > 0 && result.is7Out) { lives -= 1; }
                result.livesRemaining = lives;
                result.livesTotal = startLives;
            }

            results.push(result);
        });

        return results;
    }
}

interface ChanceGame {
    id: number;
    gameConfig: {
        id: string,
        multiplier: number
    };
    symbols: string[];
    payout: number[];
    stake: number;
    totalWin: number;
    games?: ChanceGame[];
    params: any;

    // These two don't come in the response, we added for convenience
    is7Out: boolean;
    is100thRoll: boolean;
}
