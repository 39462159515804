import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { HotRollBackgroundFireComponent } from "components/hotroll-background-fire-component";
import { gameLayers } from "game-layers";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";

export class HotRollFullHouseState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        if (!this.isFullHouse()) { return this.complete(); }

        new Parallel([
            () => Services.get(SoundService).customEventContract(HotRollSoundEvents.fullhouse),
            () => Components.get(SlingoTicketMatrixComponent).fullHouseAnim(),
            () => Contract.getDelayedContract(1750, () => new Parallel([
                () => gameLayers.CelebrationForeground.setScene("logo"),
                () => gameLayers.CelebrationBackground.setScene("fullhouse"),
                () => gameLayers.CelebrationContent.setScene("fullhouse"),
                () => Components.get(HotRollBackgroundFireComponent).setFlameLevel(2, 3000)
            ]))
        ]).then(() => {
            Components.get(HotRollBackgroundFireComponent).setFlameLevel(0, 250).execute();
            gameLayers.CelebrationBackground.defaultScene().execute();
            gameLayers.CelebrationContent.defaultScene().execute();
            gameLayers.CelebrationForeground.defaultScene().execute();
            this.complete();
        });
    }

    protected isFullHouse(): boolean {
        const level = gameState.getCurrentGame().getLatestResultOfType(SlingoLadderResult).total;
        return level === 12;
    }
}
