import { HotRollPaytableMode } from "components/hotroll-dice-paytable-component";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";

export class HotRollBonusDiceRollResult extends BonusResult {
    public resultType: string = "HotRollBonusDiceRollResult";

    public multiplier: number;
    public diceValues: number[];
    public diceSum: number;
    public livesRemaining: number;
    public livesTotal: number;
    public isGoldenRoll: boolean;
    public is7Out: boolean;
    public is100thRoll: boolean;
    public paytableMode: HotRollPaytableMode;
}
