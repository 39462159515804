import { gameLayers } from "game-layers";
import { SlingoReelMatrixFactory } from "slingo/components/factories/slingo-reel-matrix-factory";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { AlwaysLandAnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/always-land-animate-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class HotRollReelMatrixFactory extends SlingoReelMatrixFactory {
    protected createTransition(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        matrix.startSignal.addOnce(() => gameLayers.SymbolAnimations.enableBoundsMask(true));
        super.createTransition(matrix);
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        matrix.addDefaultSymbolBehaviour((symbol) => new AlwaysLandAnimateSymbolBehaviour(symbol));
    }
}
