import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SoundNames } from "sounds-enum";
import { HotRollSoundEvents } from "./hotroll-sound-events";

export function HotRollSoundSetup() {
    const sound = Services.get(SoundService);

    // Music
    sound.registerPlay(SlingoSoundEvent.play_game, { id: SoundNames.SlingoTheme_Level1, music: true, loop: true, volume: 0.8 });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["5"], { id: SoundNames.SlingoTheme_Level2, music: true, loop: true, volume: 0.8 });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["9"], { id: SoundNames.SlingoTheme_Level3, music: true, loop: true, volume: 0.8 });

    sound.registerStop(SlingoSoundEvent.end_game_no_win, { id: SoundNames.SlingoTheme_Level1 });
    sound.registerStop(SlingoSoundEvent.end_game_win, { id: SoundNames.SlingoTheme_Level1 });
    sound.registerStop(SlingoSoundEvent.end_game_no_win, { id: SoundNames.SlingoTheme_Level2 });
    sound.registerStop(SlingoSoundEvent.end_game_win, { id: SoundNames.SlingoTheme_Level2 });
    sound.registerStop(SlingoSoundEvent.end_game_no_win, { id: SoundNames.SlingoTheme_Level3 });
    sound.registerStop(SlingoSoundEvent.end_game_win, { id: SoundNames.SlingoTheme_Level3 });

    sound.registerPlay(HotRollSoundEvents.bonus_start, { id: SoundNames.SlingoTheme_Level3, music: true, loop: true, volume: 0.8 });
    sound.registerStop(HotRollSoundEvents.bonus_end, { id: SoundNames.SlingoTheme_Level3, fadeTime: 2000 });

    // Bonus
    sound.registerPlay(HotRollSoundEvents.fire_transition, { id: SoundNames.hrTransition });
    sound.registerPlay(HotRollSoundEvents.bonus_intro_voice, { id: SoundNames.hrBonus });

    // Intro
    sound.registerPlay(SoundEvent.start_game, { id: SoundNames.htr_launch_game });
    sound.registerStop(SlingoSoundEvent.play_game, { id: SoundNames.htr_launch_game });

    // UI
    sound.registerPlay(SoundEvent.default_button_press, { id: SoundNames.htr_spin_click });
    sound.registerPlay(SlingoSoundEvent.purchase_spin, { id: SoundNames.htr_extra_spin_click });
    sound.registerPlay(SoundEvent.stake_radio_select_higher, { id: SoundNames.htr_bet_value });
    sound.registerPlay(SoundEvent.stake_radio_select_lower, { id: SoundNames.htr_bet_value_decreases });

    // Reel spin
    sound.registerPlayArgs(SoundEvent.reel_N_spin_start, ["0"], { id: SoundNames.htr_spin_loop, loop: true });
    sound.registerStopArgs(SoundEvent.reel_N_spin_end, ["4"], { id: SoundNames.htr_spin_loop });

    // Reel land
    sound.registerPlay(SoundEvent.reel_any_land, { id: SoundNames.ReelStop });


    // Special symbols
    sound.registerPlay(HotRollSoundEvents.free_spin_spark, { id: SoundNames.htr_free_spin_whoosh, singleton: true });
    sound.registerPlay(HotRollSoundEvents.free_spin_increment, { id: SoundNames.HRS_Increment, singleton: true });

    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["FS"], { id: SoundNames.htr_free_spin_added, singleton: true });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["SJ"], { id: SoundNames.htr_reel_drop_superWild });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["J"], { id: SoundNames.htr_reel_drop_Wild });

    // Dabs
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["1"], { id: SoundNames.Dab1, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["2"], { id: SoundNames.Dab2, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["3"], { id: SoundNames.Dab3, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["4"], { id: SoundNames.Dab4, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["5"], { id: SoundNames.Dab5, singleton: true });

    // Slingos
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["1"], { id: SoundNames.htr_slingo_1, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["2"], { id: SoundNames.htr_slingo_2, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["3"], { id: SoundNames.htr_slingo_3, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.slingo_N, ["4"], { id: SoundNames.htr_slingo_4, singleton: true });

    // Ladder
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["1"], { id: SoundNames.htr_level_up_noPrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["2"], { id: SoundNames.htr_level_up_noPrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["3"], { id: SoundNames.htr_level_up_noPrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["4"], { id: SoundNames.htr_level_up_noPrize });

    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["5"], { id: SoundNames.htr_level_up_cashPrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["6"], { id: SoundNames.htr_level_up_cashPrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["7"], { id: SoundNames.htr_level_up_cashPrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["8"], { id: SoundNames.htr_level_up_cashPrize });

    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["9"], { id: SoundNames.htr_level_up_featurePrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["10"], { id: SoundNames.htr_level_up_featurePrize });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["11"], { id: SoundNames.htr_level_up_featurePrize });

    // Bonus entry
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["1"], { id: SoundNames.trt_diceroll_1 });
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["2"], { id: SoundNames.trt_diceroll_2 });
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["3"], { id: SoundNames.trt_diceroll_3 });
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["4"], { id: SoundNames.trt_diceroll_4 });
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["5"], { id: SoundNames.trt_hotroll_1 });
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["6"], { id: SoundNames.trt_hotroll_2 });
    sound.registerPlayArgs(HotRollSoundEvents.bonus_win_N, ["7"], { id: SoundNames.trt_hotroll_3 });

    // Big win / full house
    sound.registerPlayArgs(SoundEvent.enter_scene_LAYER_SCENE, ["CelebrationContent", "bigwin"], { id: SoundNames.htr_win_big, music: true });
    sound.registerPlay(HotRollSoundEvents.fullhouse, { id: SoundNames.htr_win_full_house, music: true });

    // Results
    sound.registerPlay(SlingoSoundEvent.result_no_win, { id: SoundNames.htr_results_no_win, singleton: true });
    sound.registerPlay(SlingoSoundEvent.result_normal_win, { id: SoundNames.htr_results_normal, singleton: true });
    sound.registerPlay(SlingoSoundEvent.result_substake_win, { id: SoundNames.htr_results_no_win, singleton: true });
    sound.registerPlay(SlingoSoundEvent.result_big_win, { id: SoundNames.htr_results_big, singleton: true });
    sound.registerPlay(SlingoSoundEvent.result_full_house_win, { id: SoundNames.htr_results_full_house, singleton: true });

    // You could win
    sound.registerPlay(SlingoSoundEvent.attract_popup, { id: SoundNames.htr_you_could_win_text, singleton: true });

    // Win countups
    sound.registerPlay(SoundEvent.winnings_countup, { id: SoundNames.htr_Prize_countup, loop: true });
    sound.registerStop(SoundEvent.winnings_countup_end, { id: SoundNames.htr_Prize_countup });
    sound.registerPlay(SoundEvent.big_win_countup_start, { id: SoundNames.htr_Prize_countup, loop: true });
    sound.registerStop(SoundEvent.big_win_countup_end, { id: SoundNames.htr_Prize_countup });

    sound.registerPlay(HotRollSoundEvents.winSummaryTone, { id: HotRollSoundEvents.winSummaryTone });

    // Dice
    sound.registerPlay(HotRollSoundEvents.dice_hit_dice_a, { id: HotRollSoundEvents.dice_hit_dice_a });
    sound.registerPlay(HotRollSoundEvents.dice_hit_dice_b, { id: HotRollSoundEvents.dice_hit_dice_b });
    sound.registerPlay(HotRollSoundEvents.dice_hit_dice_c, { id: HotRollSoundEvents.dice_hit_dice_c });

    sound.registerPlay(HotRollSoundEvents.dice_hit_wall_a, { id: HotRollSoundEvents.dice_hit_wall_a });
    sound.registerPlay(HotRollSoundEvents.dice_hit_wall_b, { id: HotRollSoundEvents.dice_hit_wall_b });
    sound.registerPlay(HotRollSoundEvents.dice_hit_wall_c, { id: HotRollSoundEvents.dice_hit_wall_c });

    sound.registerPlay(HotRollSoundEvents.dice_tumble_a, { id: HotRollSoundEvents.dice_tumble_a, loop: true });
    sound.registerPlay(HotRollSoundEvents.dice_tumble_b, { id: HotRollSoundEvents.dice_tumble_b, loop: true });
    sound.registerPlay(HotRollSoundEvents.dice_tumble_c, { id: HotRollSoundEvents.dice_tumble_c, loop: true });
    sound.registerPlay(HotRollSoundEvents.dice_tumble_d, { id: HotRollSoundEvents.dice_tumble_d, loop: true });
    sound.registerPlay(HotRollSoundEvents.dice_tumble_e, { id: HotRollSoundEvents.dice_tumble_e, loop: true });
    sound.registerPlay(HotRollSoundEvents.dice_tumble_f, { id: HotRollSoundEvents.dice_tumble_f, loop: true });
    sound.registerStop(HotRollSoundEvents.dice_tumble_stop, [
        { id: HotRollSoundEvents.dice_tumble_a },
        { id: HotRollSoundEvents.dice_tumble_b },
        { id: HotRollSoundEvents.dice_tumble_c },
        { id: HotRollSoundEvents.dice_tumble_d },
        { id: HotRollSoundEvents.dice_tumble_e },
        { id: HotRollSoundEvents.dice_tumble_f }
    ]);

    sound.registerPlay(HotRollSoundEvents.dice_slide, { id: HotRollSoundEvents.dice_slide });

    sound.registerPlay(HotRollSoundEvents.dice_award_2, { id: HotRollSoundEvents.dice_award_2 });
    sound.registerPlay(HotRollSoundEvents.dice_award_3, { id: HotRollSoundEvents.dice_award_3 });
    sound.registerPlay(HotRollSoundEvents.dice_award_4, { id: HotRollSoundEvents.dice_award_4 });
    sound.registerPlay(HotRollSoundEvents.dice_award_5, { id: HotRollSoundEvents.dice_award_5 });
    sound.registerPlay(HotRollSoundEvents.dice_award_6, { id: HotRollSoundEvents.dice_award_6 });
    sound.registerPlay(HotRollSoundEvents.dice_award_7, { id: HotRollSoundEvents.dice_award_7 });
    sound.registerPlay(HotRollSoundEvents.dice_award_8, { id: HotRollSoundEvents.dice_award_8 });
    sound.registerPlay(HotRollSoundEvents.dice_award_9, { id: HotRollSoundEvents.dice_award_9 });
    sound.registerPlay(HotRollSoundEvents.dice_award_10, { id: HotRollSoundEvents.dice_award_10 });
    sound.registerPlay(HotRollSoundEvents.dice_award_11, { id: HotRollSoundEvents.dice_award_11 });
    sound.registerPlay(HotRollSoundEvents.dice_award_12, { id: HotRollSoundEvents.dice_award_12 });
    sound.registerPlay(HotRollSoundEvents.dice_award_13, { id: HotRollSoundEvents.dice_award_13 });
    sound.registerPlay(HotRollSoundEvents.dice_award_14, { id: HotRollSoundEvents.dice_award_14 });
    sound.registerPlay(HotRollSoundEvents.dice_award_15, { id: HotRollSoundEvents.dice_award_15 });
    sound.registerPlay(HotRollSoundEvents.dice_award_16, { id: HotRollSoundEvents.dice_award_16 });

    sound.registerPlay(HotRollSoundEvents.dice_instruction_1, { id: HotRollSoundEvents.dice_instruction_1 });
    sound.registerPlay(HotRollSoundEvents.dice_instruction_2a, { id: HotRollSoundEvents.dice_instruction_2a });
    sound.registerPlay(HotRollSoundEvents.dice_instruction_2b, { id: HotRollSoundEvents.dice_instruction_2b });
    sound.registerPlay(HotRollSoundEvents.dice_instruction_2c, { id: HotRollSoundEvents.dice_instruction_2c });
    sound.registerPlay(HotRollSoundEvents.dice_instruction_3a, { id: HotRollSoundEvents.dice_instruction_3a });
    sound.registerPlay(HotRollSoundEvents.dice_instruction_3b, { id: HotRollSoundEvents.dice_instruction_3b });
    sound.registerStop(HotRollSoundEvents.stop_instructions, [
        { id: HotRollSoundEvents.dice_instruction_1 },
        { id: HotRollSoundEvents.dice_instruction_2a },
        { id: HotRollSoundEvents.dice_instruction_2b },
        { id: HotRollSoundEvents.dice_instruction_2c },
        { id: HotRollSoundEvents.dice_instruction_3a },
        { id: HotRollSoundEvents.dice_instruction_3b }
    ]);

    sound.registerPlay(HotRollSoundEvents.dice_7_aww, { id: HotRollSoundEvents.dice_7_aww });

    sound.registerPlay(HotRollSoundEvents.dice_snakeeyes, { id: HotRollSoundEvents.dice_snakeeyes });
    sound.registerPlay(HotRollSoundEvents.dice_two, { id: HotRollSoundEvents.dice_two });
    sound.registerPlay(HotRollSoundEvents.dice_aces, { id: HotRollSoundEvents.dice_aces });
    sound.registerPlay(HotRollSoundEvents.dice_acedeuce, { id: HotRollSoundEvents.dice_acedeuce });
    sound.registerPlay(HotRollSoundEvents.dice_four_easy, { id: HotRollSoundEvents.dice_four_easy });
    sound.registerPlay(HotRollSoundEvents.dice_four_hard, { id: HotRollSoundEvents.dice_four_hard });
    sound.registerPlay(HotRollSoundEvents.dice_doubledeuce, { id: HotRollSoundEvents.dice_doubledeuce });
    sound.registerPlay(HotRollSoundEvents.dice_five, { id: HotRollSoundEvents.dice_five });
    sound.registerPlay(HotRollSoundEvents.dice_bigsix, { id: HotRollSoundEvents.dice_bigsix });
    sound.registerPlay(HotRollSoundEvents.dice_six_easy, { id: HotRollSoundEvents.dice_six_easy });
    sound.registerPlay(HotRollSoundEvents.dice_six_hard, { id: HotRollSoundEvents.dice_six_hard });
    sound.registerPlay(HotRollSoundEvents.dice_seven, { id: HotRollSoundEvents.dice_seven });
    sound.registerPlay(HotRollSoundEvents.dice_sevenout, { id: HotRollSoundEvents.dice_sevenout });
    sound.registerPlay(HotRollSoundEvents.dice_eight_easy, { id: HotRollSoundEvents.dice_eight_easy });
    sound.registerPlay(HotRollSoundEvents.dice_eight, { id: HotRollSoundEvents.dice_eight });
    sound.registerPlay(HotRollSoundEvents.dice_eight_hard, { id: HotRollSoundEvents.dice_eight_hard });
    sound.registerPlay(HotRollSoundEvents.dice_nine, { id: HotRollSoundEvents.dice_nine });
    sound.registerPlay(HotRollSoundEvents.dice_ten_hard, { id: HotRollSoundEvents.dice_ten_hard });
    sound.registerPlay(HotRollSoundEvents.dice_ten_easy, { id: HotRollSoundEvents.dice_ten_easy });
    sound.registerPlay(HotRollSoundEvents.dice_eleven, { id: HotRollSoundEvents.dice_eleven });
    sound.registerPlay(HotRollSoundEvents.dice_boxcars, { id: HotRollSoundEvents.dice_boxcars });
    sound.registerPlay(HotRollSoundEvents.dice_route66, { id: HotRollSoundEvents.dice_route66 });

    sound.registerPlay(HotRollSoundEvents.dice_transform, { id: HotRollSoundEvents.dice_transform });

    sound.registerPlay(HotRollSoundEvents.cheer1, { id: HotRollSoundEvents.cheer1 });
    sound.registerPlay(HotRollSoundEvents.cheer2, { id: HotRollSoundEvents.cheer2 });
    sound.registerPlay(HotRollSoundEvents.cheer3, { id: HotRollSoundEvents.cheer3 });
    sound.registerPlay(HotRollSoundEvents.cheer4, { id: HotRollSoundEvents.cheer4 });
    sound.registerPlay(HotRollSoundEvents.cheer5, { id: HotRollSoundEvents.cheer5 });
    sound.registerPlay(HotRollSoundEvents.cheer6, { id: HotRollSoundEvents.cheer6 });
    sound.registerPlay(HotRollSoundEvents.cheer7, { id: HotRollSoundEvents.cheer7 });
    sound.registerPlay(HotRollSoundEvents.cheer8, { id: HotRollSoundEvents.cheer8 });
    sound.registerPlay(HotRollSoundEvents.cheer9, { id: HotRollSoundEvents.cheer9 });
    sound.registerPlay(HotRollSoundEvents.cheer10, { id: HotRollSoundEvents.cheer10 });
    sound.registerPlay(HotRollSoundEvents.cheer11, { id: HotRollSoundEvents.cheer11 });
    sound.registerPlay(HotRollSoundEvents.cheer12, { id: HotRollSoundEvents.cheer12 });
    sound.registerPlay(HotRollSoundEvents.cheer13, { id: HotRollSoundEvents.cheer13 });
    sound.registerPlay(HotRollSoundEvents.cheer14, { id: HotRollSoundEvents.cheer14 });
    sound.registerPlay(HotRollSoundEvents.cheer15, { id: HotRollSoundEvents.cheer15 });

    sound.registerPlay(HotRollSoundEvents.paytable_tickup, { id: SoundNames.hrBonusRollup_lewisshortened });
    sound.registerTerminator(SoundNames.hrBonusRollupTerm, SoundNames.hrBonusRollup_lewisshortened);
    sound.registerStop(HotRollSoundEvents.paytable_tickup_complete, { id: SoundNames.hrBonusRollup_lewisshortened });

    
    sound.registerPlay(HotRollSoundEvents.bonus_running_total_tickup_start, { id: SoundNames.Rollup01 });
    sound.registerTerminator(SoundNames.RollupTerm01, SoundNames.Rollup01);
    sound.registerStop(HotRollSoundEvents.bonus_running_total_tickup_end, { id: SoundNames.Rollup01 });
}
