import { AbstractComponent } from "appworks/components/abstract-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { Services } from "appworks/services/services";
import { gameLayers } from "game-layers";

// Having portrait version of the bg in the PSD doubles the size of the atlas, wanna make this game small af
export class HotRollBackgroundComponent extends AbstractComponent {
    protected canvas = Services.get(CanvasService);
    protected initialised: boolean = false;

    public init(): void {
        if (this.initialised) { return; }
        this.initialised = true;

        gameLayers.CelebrationBackground.onSceneEnter.add(() => this.checkBgPosition());
        gameLayers.Background.onSceneEnter.add(() => this.checkBgPosition());
        this.canvas.onOrientationChange.add(() => this.checkBgPosition());
        this.checkBgPosition();
    }

    protected checkBgPosition(layer = gameLayers.Background) {
        const sprites = [
            gameLayers.Background.getSprite("bg"),
            gameLayers.CelebrationBackground.getSprite("bg")
        ];
        const orientation = this.canvas.orientation;

        sprites.forEach((sprite) => {
            if (sprite) {
                CenterPivot(sprite).rotation = (orientation === Orientation.LANDSCAPE) ? 0 : Math.PI / 2;

                // Make bg a click blocker
                sprite.interactive = true;
            }
        });
    }
}
