import { BlastworksClientEvent } from "../../ClientController";
import { gameState } from "appworks/model/game-state";
import { SocialStateMachine } from "../social-state-machine";
import { SlingoPurchaseEntryState } from "slingo/states/slingo-purchase-entry-state";

export class SocialPurchaseEntryState extends SlingoPurchaseEntryState {
    
    public complete(): void {
        const gameplay = gameState.getCurrentGame();
        gameplay.setToLatestRecord();
        
        // If this is zero then there must be an error
        // i.e. account limits, insufficient funds
        if (gameplay.getTotalWagered() !== 0) {
            (this.stateMachine as SocialStateMachine).dispatchClientEvent(BlastworksClientEvent.START_GAME);
        }
        super.complete();
    }
}
