import { BlastworksClientEvent, ClientController } from "../ClientController";
import { StateMachine } from "../state-machine";

export class SocialStateMachine extends StateMachine {
    private blastworksClientController: ClientController;    
    
    public start() {
        this.blastworksClientController = ClientController.getInstance();
        super.start();
    }

    /**
     * Sends a BlastworksClientEvent to the blastworksClientController
     * 
     * @param event {BlastworksClientEvent}
     */
    public dispatchClientEvent(event: BlastworksClientEvent) {
        this.blastworksClientController.raiseEvent(event);
    }
} export const stateMachine = new SocialStateMachine();