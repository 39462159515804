import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Timer } from "appworks/utils/timer";
import { HotRollBackgroundFireComponent } from "components/hotroll-background-fire-component";
import { HotRollDicePaytableComponent } from "components/hotroll-dice-paytable-component";
import { HotRollDiceRollCounterComponent } from "components/hotroll-dice-roll-counter-component";
import { gameLayers } from "game-layers";
import { HotRollSoundEvents } from "setup/hotroll-sound-events";

export class HotRollExitBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {

        Services.get(SoundService).customEvent(HotRollSoundEvents.bonus_end);

        gameLayers.ScreenFade.setScene("flame_transition").execute();
        Timer.setTimeout(() => {
            new Parallel([
                () => gameLayers.Dice.defaultScene(),
                () => gameLayers.Logo.defaultScene(),
                () => Components.get(HotRollDiceRollCounterComponent).hide(),
                () => Components.get(HotRollDicePaytableComponent).hide(),
                () => Contract.wrap(() => {
                    uiFlags.set(UIFlag.BONUS, false);
                    Layers.resetLayerOrder();
                    Components.get(HotRollBackgroundFireComponent).init();
                })
            ]).then(() => this.complete());
        }, 700);
    }
}
