import { Components } from "appworks/components/components";
import { ClientController } from "appworks/state-machine/ClientController";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HotRollTicketMatrixComponent } from "components/hotroll-ticket-matrix-component";
import { SlingoCelebrationComponent } from "slingo/components/slingo-celebration-component";

export class SocialTicketMatrixComponent extends HotRollTicketMatrixComponent {
    public checkWinlines(showCelebration: boolean = true): Contract {
        const newMatches: string[] = [];
        const bwController: ClientController = ClientController.getInstance();
        
        this.winlines.forEach((matched, pattern) => {
            if (!matched && this.checkWinline(pattern)) {
                newMatches.push(pattern);
                this.winlines.set(pattern, true);
            }
        });

        if (showCelebration && newMatches.length) {
            bwController.raiseEvent(ClientController.getSlingoEvent(newMatches.length));
            return new Parallel([
                () => Components.get(SlingoCelebrationComponent).showCelebration(newMatches.length),
                () => new Sequence([
                    ...newMatches.map(pattern => () => this.animateLine(pattern))
                ])
            ]);
        } else {
            return Contract.empty();
        }
    }
}