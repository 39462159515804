import { SocialInitState } from "appworks/state-machine/social/states/social-init-state";
import { SocialPurchaseEntryState } from "appworks/state-machine/social/states/social-purchase-entry-state";
import { stateMachine } from "appworks/state-machine/social/social-state-machine";
import { SlingoFreeSpinsRemainingDecision } from "slingo/states/decisions/slingo-free-spins-remaining-decision";
import { SlingoGameInProgressDecision } from "slingo/states/decisions/slingo-game-in-progress-decision";
import { SlingoHasJokerDecision } from "slingo/states/decisions/slingo-has-joker-decision";
import { SlingoRecoveryIsBeforeFirstSpinDecision } from "slingo/states/decisions/slingo-recovery-is-before-first-spin-decision";
import { SlingoSpinPurchasedDecision } from "slingo/states/decisions/slingo-spin-purchased-decision";
import { SlingoStandardSpinsRemainingDecision } from "slingo/states/decisions/slingo-standard-spins-remaining-decision";
import { SlingoTicketCompletedDecision } from "slingo/states/decisions/slingo-ticket-completed-decision";
import { SlingoIdleState } from "slingo/states/slingo-idle-state";
import { SlingoPickJokerState } from "slingo/states/slingo-pick-joker-state";
import { IsBonusTriggeredDecision } from "slotworks/state-machine/standard/decisions/is-bonus-triggered-decision";
import { IsRecoveryDecision } from "slotworks/state-machine/standard/decisions/is-recovery-decision";
import { HotRollHasBonusDecision } from "state-machine/decisions/hotroll-has-bonus-decision";
import { HotRollBonusState } from "state-machine/states/bonus/hotroll-bonus-state";
import { HotRollBigWinState } from "state-machine/states/hotroll-big-win-state";
import { HotRollFullHouseState } from "state-machine/states/hotroll-full-house-state";
import { SocialPurchaseSpinOrCollectState } from "appworks/state-machine/social/states/social-pruchase-spin-or-collect-state";
import { SocialRequestSpinState } from "appworks/state-machine/social/states/social-request-spin-state";
import { SocialGameOverState } from "appworks/state-machine/social/states/social-game-over-state";
import { SocialRecoveryState } from "appworks/state-machine/social/states/social-recovery-state";
import { SocialSpinState } from "appworks/state-machine/social/states/social-spin-state";
import { SocialEnterBonusState } from "appworks/state-machine/social/states/social-enter-bonus-state";
import { SocialExitBonusState } from "appworks/state-machine/social/states/social-exit-bonus-state";

// State diagram: https://drive.google.com/file/d/1185ct79sLyZsY3vOGMkEH55TG6Vf5958/view?usp=sharing

export function HotRollStateSetup() {
    // States
    const initState = new SocialInitState();
    const idleState = new SlingoIdleState();
    const recoveryState = new SocialRecoveryState();
    const purchaseEntryState = new SocialPurchaseEntryState();
    const requestSpinState = new SocialRequestSpinState();
    const spinState = new SocialSpinState();
    const pickJokerState = new SlingoPickJokerState();
    const purchaseOrCollectState = new SocialPurchaseSpinOrCollectState();
    const gameOverState = new SocialGameOverState();
    const bigWinState = new HotRollBigWinState();
    const fullHouseState = new HotRollFullHouseState();
    const enterBonusState = new SocialEnterBonusState();
    const bonusState = new HotRollBonusState();
    const exitBonusState = new SocialExitBonusState();

    // Decisions
    const isRecoveryDecision = new IsRecoveryDecision();
    const gameInProgressDecision = new SlingoGameInProgressDecision();
    const hasJokerDecision = new SlingoHasJokerDecision();
    const standardSpinsRemainingDecision = new SlingoStandardSpinsRemainingDecision();
    const freeSpinsRemainingDecision = new SlingoFreeSpinsRemainingDecision();
    const ticketCompletedDecision = new SlingoTicketCompletedDecision();
    const spinPurchasedDecision = new SlingoSpinPurchasedDecision();
    const recoveryBeforeFirstSpinDecision = new SlingoRecoveryIsBeforeFirstSpinDecision();
    const hasBonusDecision = new HotRollHasBonusDecision();
    const bonusMoreRollsDecision = new IsBonusTriggeredDecision("bonus");

    stateMachine.addStates([
        { state: initState, entryPoint: true, links: { complete: isRecoveryDecision } },
        { state: recoveryState, links: { complete: recoveryBeforeFirstSpinDecision } },

        { state: idleState, links: { complete: gameInProgressDecision } },
        { state: purchaseEntryState, links: { complete: requestSpinState, error: idleState } },
        { state: requestSpinState, links: { complete: spinState, error: purchaseOrCollectState } },
        { state: spinState, links: { complete: ticketCompletedDecision } },
        { state: pickJokerState, links: { complete: ticketCompletedDecision } },
        { state: purchaseOrCollectState, links: { complete: spinPurchasedDecision } },
        { state: bigWinState, links: { complete: gameOverState } },
        { state: gameOverState, links: { complete: idleState } },

        { state: fullHouseState, links: { complete: purchaseOrCollectState } },
        { state: enterBonusState, links: { complete: bonusState } },
        { state: bonusState, links: { complete: bonusMoreRollsDecision } },
        { state: exitBonusState, links: { complete: bigWinState } }
    ]);

    stateMachine.addDecisions([
        { decision: isRecoveryDecision, links: { true: recoveryState, false: idleState } },
        { decision: recoveryBeforeFirstSpinDecision, links: { true: requestSpinState, false: spinState } },
        { decision: gameInProgressDecision, links: { true: requestSpinState, false: purchaseEntryState } },
        { decision: hasJokerDecision, links: { true: pickJokerState, false: standardSpinsRemainingDecision } },
        { decision: standardSpinsRemainingDecision, links: { true: requestSpinState, false: freeSpinsRemainingDecision } },
        { decision: freeSpinsRemainingDecision, links: { true: requestSpinState, false: purchaseOrCollectState } },
        { decision: spinPurchasedDecision, links: { true: requestSpinState, false: hasBonusDecision } },
        { decision: hasBonusDecision, links: { true: enterBonusState, false: bigWinState } },
        { decision: ticketCompletedDecision, links: { true: fullHouseState, false: hasJokerDecision } },
        { decision: bonusMoreRollsDecision, links: { true: bonusState, false: exitBonusState } }
    ]);
}
